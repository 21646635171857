import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICategoryTitle, IEmail } from 'app/models/Email/IEmail';
import {
  ConsentType,
  IEmployee,
  HubEventListenerProps,
  IBulkSmscandidateConsent,
  ICandidateBrand,
  IModalInteraction,
  INotification,
  LogFilters,
  ILogsFilterChips,
  IInboxQuery,
  ICommunicationLimitsDialog,
  ICommunicationLimitsPayload,
  ITemplatesByCategory,
  ISaveTemplate,
  ITemplateType,
  IUpdateTemplate,
  IUnreadSmsCount,
  IInboxReadStatusCommand,
  IVoiceAction,
  IDisposition,
  ISaveCallLog,
  IInitiateCall,
} from 'app/models/Notification/Notification';
import { IChat } from 'app/models/Sms/IChat';
import { ISmsAttachment } from 'app/models/Sms/ISms';
import { ICandidateDetails } from '../../../app/models/Candidate/CandidateProfile';
import { logsInitialState, initialState, defaultLogsChips } from './initial-state';

const handleHubEvent = createAction<HubEventListenerProps>('HANDLE_SIGNALR_EVENT');
const getCandidatesConsentsAction = createAction<{
  candidateIds: Array<string>;
  brandId: any;
  consentType: ConsentType;
}>('GET_CANDIDATES_CONSENTS');
const getTemplatesByCategory = createAction<{ category: string }>('GET_TEMPLATES_BY_CATEGORY');
const getConsentsWithdrawn = createAction<ICandidateBrand[]>('GET_CONSENTS_WITHDRAWN');
const bulkSmsCandidateConsent = createAction<IBulkSmscandidateConsent>('BULKSMS_CANDIDATE_CONSENTS');
const deletePersonalTemplate = createAction<{ templateId: string; category: string }>('DELETE_PERSONAL_TEMPLATE');
const getInboxMessages = createAction<IInboxQuery>('GET_INBOX_MESSAGES');
const getUnreadCount = createAction('GET_UNREAD_COUNT');
const getCommunicationLimitsData = createAction<ICommunicationLimitsPayload>('GET_COMMUNICATION_LIMITS_DATA');
const getFacilityBaseTemplate = createAction<{ userType: number }>('GET_BASE_TEMPLATE');
const getFacilityTemplatesByCategory = createAction<ITemplatesByCategory>('GET_FACILITY_TEMPLATES_BY_CATEGORY');
const createFacilityTemplate = createAction<ISaveTemplate>('CREATE_FACILITY_TEMPLATE');
const updateFacilityEmailTemplates = createAction<IUpdateTemplate>('UPDATE_FACILITY_EMAIL_TEMPLATE');
const updateMessageReadStatus = createAction<IInboxReadStatusCommand>('UPDATE_MESSAGE_READ_STATUS');
const initiateCall = createAction<IInitiateCall>('INITIATE_CALL');
const saveCallLog = createAction<ISaveCallLog>('SAVE_CALL_LOG');
const notificationSlice = createSlice({
  name: 'notificationData',
  initialState,
  reducers: {
    setExpanded(state, action: PayloadAction<{ [key: string]: any }>) {
      return {
        ...state,
        expanded: {
          ...state.expanded,
          ...action.payload,
        },
      };
    },
    resetFilters(state, action: PayloadAction<any>) {
      if (action.payload === 'isCommunicationHistoryGrid') {
        state.gridLogFilters = logsInitialState;
      } else {
        state.logFilters = logsInitialState;
      }
    },
    setDropdown(state, action: PayloadAction<{ [key: string]: boolean }>) {
      return {
        ...state,
        dropdown: {
          ...state.dropdown,
          ...action.payload,
        },
      };
    },
    resetChips(state) {
      state.chips = defaultLogsChips;
    },
    setFilterChips(state, action: PayloadAction<ILogsFilterChips>) {
      state.chips = action.payload;
    },
    setGridFilters(state, action: PayloadAction<LogFilters>) {
      state.gridLogFilters = action.payload;
    },
    setFilters(state, action: PayloadAction<LogFilters>) {
      state.logFilters = action.payload;
    },
    setShowFilters(state, action: PayloadAction<Boolean>) {
      state.showLogsFilter = action.payload;
    },
    setAttachments(state, action: PayloadAction<ISmsAttachment | undefined>) {
      state.sms.attachments = action.payload;
    },
    setNotes(state, action: PayloadAction<string>) {
      state.sms.newNotes = action.payload;
    },
    setLogdata(state, action: PayloadAction<any>) {
      state.log = action.payload.log;
      state.snackbarData = action.payload.snackbarData;
    },
    setVoiceData(state, action: PayloadAction<any>) {
      state.voice = action.payload;
    },
    setEmailData(state, action: PayloadAction<IEmail>) {
      state.email = action.payload;
    },
    setEmailType(state, action: PayloadAction<string>) {
      state.email.data!.emailType = action.payload;
    },
    setEmailSender(state, action: PayloadAction<IEmployee>) {
      state.email.data.sender = action.payload;
    },
    setEmailBCC(state, action: PayloadAction<IEmployee[]>) {
      state.email.data.bccs = action.payload;
    },
    setCategoryTitles(state, action: PayloadAction<string[]>) {
      state.email.categoryTitles = action.payload;
    },
    setSmsData(state, action: PayloadAction<any>) {
      state.sms = action.payload;
    },
    setFalseSmsUponAttachmentSendViaEmail(state, action: PayloadAction<void>) {
      state.sms.chatHistory!.chats!.notifySmsUponAttachmentSendViaEmail = false;
    },
    setPacketCategoryTitles(state, action: PayloadAction<ICategoryTitle[]>) {
      state.email.packetCategoryTitles = action.payload;
    },

    /**
     * Used to update the sms meta data needed for publish action.
     */
    updateSmsData(state, action: PayloadAction<any>) {
      state.sms.data = action.payload;
    },
    updateChatHistory(state, action: PayloadAction<any>) {
      state.sms.chatHistory = action.payload;
    },
    updateChat(state, action: PayloadAction<IChat>) {
      if (state.sms.chatHistory) state.sms.chatHistory.chats = action.payload;
    },
    updateNotes(state, action: PayloadAction<any>) {
      state.sms.notes = action.payload;
    },
    updateUnreadCount(state, action: PayloadAction<number>) {
      state.sms.activeUnreadCount = action.payload;
    },
    setEmailInteraction(state, action: PayloadAction<IModalInteraction>) {
      state.email.open = action.payload.open;
      state.email.minimized = action.payload.minimized;
    },
    setSmsInteraction(state, action: PayloadAction<IModalInteraction>) {
      state.sms.open = action.payload.open;
      state.sms.minimized = action.payload.minimized;
    },
    setVoiceInteraction(state, action: PayloadAction<IModalInteraction>) {
      state.voice.open = action.payload.open;
      state.voice.minimized = action.payload.minimized;
      state.voice.showDisposition = action.payload.showDisposition ?? false;
    },
    setLogInteraction(state, action: PayloadAction<IModalInteraction>) {
      state.log.open = action.payload.open;
      state.log.minimized = action.payload.minimized;
    },

    setSnackBarData(state, action: PayloadAction<any>) {
      state.snackbarData = action.payload;
    },

    /**
     * External action. Call this to set a global notification data.
     */
    setNotificationData(state, action: PayloadAction<INotification>) {
      return {
        ...action.payload,
      };
    },

    /**
     * External action. Call this to set a email template change warning message.
     */
    setChangeWarning(state, action: PayloadAction<boolean>) {
      if (state.snackbarData?.changeWarning) {
        state.snackbarData.changeWarning = action.payload;
      }
    },

    /**
     * External action. Call this to set a increment active toaster count.
     */
    incrementActiveToaster(state, action: PayloadAction) {
      state.activeToasterCount = state.activeToasterCount + 1;
    },

    /**
     * External action. Call this to set a decrement active toaster count.
     */
    decrementActiveToaster(state, action: PayloadAction) {
      state.activeToasterCount = state.activeToasterCount - 1;
    },

    /**
     * External action. Call this to update inbox messages.
     */
    updateInbox(state, action: PayloadAction<any>) {
      state.inbox = action.payload;
    },

    /**
     * External action. Call this to update global inbox messages.
     */
    updateInboxMessages(state, action: PayloadAction<any>) {
      state.inboxMessages = action.payload;
    },

    updatePageCounter(state, action: PayloadAction<any>) {
      state.pageNumber = action.payload;
    },

    updateNextPageExistsFlag(state, action: PayloadAction<any>) {
      state.nextPageExists = action.payload;
    },

    /**
     * Update Associated candidate to SMS .
     */
    associateCandidate(state, action: PayloadAction<any>) {
      state.sms.chatHistory = { ...state.sms.chatHistory, associatedCandidate: action.payload };
    },

    /**
     * Update candidate details required for sms modal.
     */
    updateSmsCandidateInfo(state, action: PayloadAction<ICandidateDetails>) {
      state.sms.chatHistory!.candidateDetails = action.payload;
    },
    setUnreadFilter(state, action: PayloadAction<any>) {
      state.inboxUnreadFilter = action.payload;
    },
    setConsent(state, action: PayloadAction<any>) {
      state.candidateConsents = action.payload;
    },
    setSidePanelConsent(state, action: PayloadAction<any>) {
      state.sidePanelConsents = action.payload;
    },
    setSmsConsents(state, action: PayloadAction<any>) {
      state.smsCandidateConsents = action.payload;
    },
    setEmailConsentWithdrawn(state, action: PayloadAction<any[]>) {
      state.emailConsentWithdrawn = action.payload;
    },
    setCandidateData(state, action: PayloadAction<any[]>) {
      state.sms.data.candidateData = action.payload;
    },
    setUnreadCount(state, action: PayloadAction<IUnreadSmsCount[]>) {
      state.unreadCount = action.payload;
    },
    setCommunicationLimitsEmail(state, action: PayloadAction<ICandidateBrand[]>) {
      state.communicationLimitsEmail = action.payload;
    },
    setCommunicationLimitsSms(state, action: PayloadAction<ICandidateBrand[]>) {
      state.communicationLimitsSms = action.payload;
    },
    setCommunicationLimitsDialogOpen(state, action: PayloadAction<ICommunicationLimitsDialog>) {
      state.communicationLimitsDialog = action.payload;
    },
    setPinnedMessages(state, action: PayloadAction<any[]>) {
      state.sms.pinnedMessages = action.payload;
    },
    setManualEntry(state, action: PayloadAction<boolean>) {
      state.sms.manualEntry = action.payload;
    },
    setManuallyEnteredNumber(state, action: PayloadAction<string>) {
      state.sms.manuallyEnteredNumber = action.payload;
    },
    setFacilityBaseTemplate(state, action: PayloadAction<string>) {
      state.facilityBaseTemplate = action.payload;
    },
    setFacilityTemplates(state, action: PayloadAction<any[]>) {
      state.packetFacilityTemplates = action.payload;
    },
    setFacilityTemplatesById(state, action: PayloadAction<ITemplateType>) {
      state.packetFacilityTemplatesById = action.payload;
    },
    setIsEdit(state, action: PayloadAction<boolean>) {
      state.isEdit = action.payload;
    },
    setVoiceAction(state, action: PayloadAction<IVoiceAction>) {
      state.voiceAction = action.payload;
    },
    setVoiceDisposition(state, action: PayloadAction<IDisposition>) {
      state.voiceDisposition = action.payload;
    },
    setVoiceLoader(state, action: PayloadAction<boolean>) {
      state.voiceLoader = action.payload;
    },
  },
});

const { actions } = notificationSlice;

export const { name: notificationDataKey, reducer: notificationDataReducer } = notificationSlice;

export const notificationDataActions = {
  ...actions,
  handleHubEvent,
  getCandidatesConsentsAction,
  getTemplatesByCategory,
  getConsentsWithdrawn,
  bulkSmsCandidateConsent,
  deletePersonalTemplate,
  getInboxMessages,
  getUnreadCount,
  getCommunicationLimitsData,
  getFacilityBaseTemplate,
  getFacilityTemplatesByCategory,
  createFacilityTemplate,
  updateFacilityEmailTemplates,
  updateMessageReadStatus,
  initiateCall,
  saveCallLog,
};
