import { tooltipType } from 'app/components/Common/Tooltips';
import { toolTipCheck } from 'app/components/Order/OrderDetails/helper';
import { missingField } from 'app/constants';
import { IClientContactUnit, IFacilityClientContactsList } from 'app/models/Facility/FacilityDetails';
import { gridControlType } from 'app/models/Grid';
import { useEnableEmailCommunication, useEnableVoiceCommunication } from 'oidc/CommunicationEnabler';
import React from 'react';
import { Concatenate } from 'utils/string/string';
import { FacilityDrawer } from '../../../SidePanel/FacilityDrawer';
import { setUpdatedByClientContract } from '@AMIEWEB/Unit/ClientContact/helper';

export const useFacilityCantactViewInfo = () => {
  const enableEmailCommunication = useEnableEmailCommunication();
  const enableVoiceCommunication = useEnableVoiceCommunication();

  return [
    {
      title: 'firstName',
      value: 'First Name',
      clickable: true,
      sortable: true,
      sortByField: 'firstNameSortable',
    },
    {
      title: 'lastName',
      value: 'Last Name',
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'lastNameAttributes',
      sortable: true,
    },
    { title: 'prefix', value: 'Prefix', sortable: true },
    { title: 'goesBy', value: 'Goes by', sortable: true },
    { title: 'email', value: 'Email', sortable: true, clickable: enableEmailCommunication, isModalOpen: true },
    {
      title: 'phoneNumber',
      value: 'Phone',
      sortable: true,
      isNumber: true,
      clickable: enableVoiceCommunication,
      isModalOpen: true,
    },
    {
      title: 'title',
      value: 'Title',
      sortable: true,
    },
    { title: 'contactPreference', value: 'Contact Preferences', sortable: true },
    {
      title: 'doNotCallIn',
      value: 'Do Not Call',
      sortable: true,
      control: {
        type: gridControlType.checkbox,
        color: 'green',
      },
    },
    {
      title: 'city',
      value: 'City',
      sortable: true,
    },
    { title: 'state', value: 'State', sortable: true },
    {
      title: 'actions',
      value: 'Actions',
      sortable: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'actionAttributes',
      sortByField: 'actionsSortField',
    },
    {
      title: 'units',
      value: 'Units',
      sortable: true,
      tooltip: tooltipType.CustomTooltip,
      tooltipAttributes: 'unitAttributes',
      sortByField: 'unitsSortField',
    },
  ];
};
const tooltipFix = { display: 'inline-block' };
export const formatFacilityContacts = (response, employeeName?: string) =>
  response.map((item: IFacilityClientContactsList, index) => {
    const unitNames =
      item.units && item.units.length
        ? Concatenate(
            item.units.map(item => item.name),
            ', ',
          )
        : missingField;
    const actionDescriptions =
      item.actions && item.actions.length
        ? Concatenate(
            item.actions.map(item => item.description),

            ', ',
          )
        : missingField;
    return {
      contactId: item.contactId,
      firstName: <FacilityDrawer displayValue={item?.firstName} selected={item} />,
      firstNameSortable: item.firstName,
      lastName: `${toolTipCheck(item?.lastName, 9) || missingField}`,
      name: item?.name,
      lastNameAttributes: {
        tooltipContent: item?.lastName,
        contentStyle: tooltipFix,
        disableTooltip: !item?.lastName || (item?.lastName.length || 0) < 9,
      },
      title: item?.title || missingField,
      email: item?.email || missingField,
      goesBy: item?.goesBy || missingField,
      prefix: item?.prefix || missingField,
      city: item?.city || missingField,
      state: item?.state || missingField,
      zip: item?.zip || missingField,
      doNotCallIn: item?.doNotCallIn === 'N' ? false : true,
      status: item?.status || missingField,
      amhTitleId: item.amhTitleId,
      address1: item?.address1 || missingField,
      address2: item?.address2 || missingField,
      stateProvinceId: item?.stateProvinceId || missingField,
      country: item?.country || missingField,
      faxInstruction: item?.faxInstruction || missingField,
      notes: item?.notes || missingField,
      insertDate: item?.insertDate || missingField,
      statusLastUpdated: setUpdatedByClientContract(item?.insertDate),
      statusId: item.statusId,
      evaluationDeliveryTypeId: item.evaluationDeliveryTypeId,
      confirmationDeliveryTypeId: item.confirmationDeliveryTypeId,
      fileDeliveryTypeId: item.fileDeliveryTypeId,
      contactPreferenceTypeId: item.contactPreferenceTypeId,
      activeSsc: item.activeSsc,
      descStatus: item?.descStatus || missingField,
      doNotCallFacility: item?.doNotCallFacility === 'N' ? false : true,
      contactPreference: item?.contactPreference || missingField,
      statusIdForFiltering: item?.statusIdForFiltering || missingField,
      regionalDirectorId: item?.regionalDirectorId || missingField,
      phoneNumber: item?.phoneNumber || missingField,
      units: item.units && item.units.length ? 'Units' : '--',
      unitAttributes: {
        tooltipContent: unitNames,
        contentStyle: tooltipFix,
        disableTooltip: item.units && item.units.length ? false : true,
      },
      unitsSortField: unitNames,
      actions: item.actions && item.actions.length > 0 && item.actions?.length ? 'Actions' : '--',
      actionAttributes: {
        tooltipContent: actionDescriptions,
        contentStyle: tooltipFix,
        disableTooltip: item.actions && item.actions.length > 0 && item.actions?.length ? false : true,
      },
      actionsSortField: actionDescriptions,
      actionsSort: item.actionsSort,
      isPrimary: item.isPrimary,
      selected: false,
      isCheckBoxReq: false,
      unitIds: item?.units?.map((unit: IClientContactUnit) => unit.unitId?.toString()),
    };
  });
