/* eslint-disable tss-unused-classes/unused-classes */
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Badge, IconButton, InputAdornment, Tab, Tabs, TextField, Button, Grid, Skeleton } from 'amn-ui-core';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import CircularMessage from 'app/assets/images/Header/CircularMessage.svg';
import HeaderMessageIcon from 'app/assets/images/Header/Message.svg';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';
import {
  HubEventListenerProps,
  IInboxQuery,
  IInboxReadStatusCommand,
  IUnreadSmsCount,
} from 'app/models/Notification/Notification';
import { getCandidateById } from 'app/services/CandidateServices/CandidateServices';
import { getInboxMessages, GetUserLogs } from 'app/services/NotificationServices/NotificationService';
import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Concatenate } from 'utils/string/string';
import { ChannelType, HubEventType, PAGE_SIZE, SendType, UserRoles, UseType } from '../Constants';
import { notificationDataActions, notificationDataKey } from 'store/redux-store/notification/notification.redux';
import {
  notificationSelection,
  selectInboxFilter,
  selectInboxMessages,
  selectPinnedMessages,
  selectUnreadCount,
} from 'store/redux-store/notification/notification.selector';
import { MessagePopup } from '../Tasks/Common/MessagePopup';
import { INotificationPopupProps } from './NotificationPopup';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { notificationSaga } from 'store/redux-store/notification/notification.saga';
import { useInjectSaga } from 'utils/redux-injectors';
import { Inbox } from './Inbox';
import { InboxSearchResults } from './InboxSearchResults';
import CloseIcon from '@mui/icons-material/Close';
import { SmsType } from 'app/enums/Sms';
import { InboxMessageFilter } from './InboxMessageFilter';
import { NoFilterResult } from './NoFilterResult';
import { optionState } from '../MultiChannel/BulkSms/MessageHelpers';
import { SignalREventType } from 'app/enums/Common';
import { coverageActions } from 'store/redux-store/user-coverage/async-actions';
import { useDecision } from '@optimizely/react-sdk';
import { ff_notifications_coverage_sms } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import SelectInbox from './SelectInbox';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { debounce, isEmpty } from 'lodash';
import _ from 'lodash';
import ChatOutlined from '@mui/icons-material/ChatOutlined';
import { PinnedLimitDialog } from './PinnedLimitDialog';
import {
  defaultAssociatedRecord,
  getDefaultSender,
} from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';
import { PreferenceCosmosKeys } from 'oidc/UserDevicePreference/utils';
import { StyledToastContainerSMS } from '../Tasks/utils';
import { ff_notifications_enable_shareddesk_communication } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { InboxToolbar, MessageDivider, useMessageInboxStyles } from './MessageInbox.styles';
import { getInboxLogIds, handlePinMessage, handleUnpinMessage } from './MesageInboxHelper';
import MessageCardSkeleton from './MessageCardSkeleton';

export const MessageInbox = () => {
  const { t } = useTranslation();
  const { classes } = useMessageInboxStyles();
  const user = useSelector(selectUser);
  const unreadCountArr = useSelector(selectUnreadCount);
  const userPreferences = useSelector(selectUserPreference);
  const smsInbox = useSelector(selectInboxMessages);
  const inboxFilters = userPreferences.inboxFilter?.value;
  const dispatch = useDispatch();
  const [showNotificationBackdrop, setShowNotificationBackdrop] = useState<boolean>(false);
  const globalData = useSelector(notificationSelection);
  const appliedFilterValues = useSelector(selectInboxFilter);
  const globalNotification = useRef<any>();
  globalNotification.current = globalData || null;
  const [inboxMessages, setInboxMessages] = useState<any>();
  const [popUpMessage, setPopUpMessage] = useState<any>([]);
  const incomingMessages = useRef<any>();
  incomingMessages.current = popUpMessage;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [recentMessages, setRecentMessages] = useState<any>([]);
  const userPreference = useSelector(selectUserPreference);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [recentInbox, setRecentInbox] = useState<boolean>(true);
  const [pinnedInbox, setPinnedInbox] = useState<boolean>(true);
  const [displaySearchResults, setDisplaySearchResults] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [contactSearchResults, setContactSearchResults] = useState<any>([]);
  const [messageSearchResults, setMessageSearchResults] = useState<any>([]);
  const [searchTypeTab, setSearchTypeTab] = useState<any>('c');
  const [paginationFlag, setPaginationFlag] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [filterSetValues, setFilterSetValues] = useState<any>([]);
  const [filterSet, setFilterSet] = useState<any>(
    optionState.filter(option => (inboxFilters || [])?.includes(option.value)),
  );
  const [checkCoverage] = useDecision(ff_notifications_coverage_sms);
  useInjectSaga({ key: notificationDataKey, saga: notificationSaga });
  const notificationPreference = userPreference?.notificationPreference?.value;
  const popUp = React.useRef<any>(true);
  const global = React.useRef<any>(globalData);
  const { coveredInboxes, activeInbox } = useSelector(selectCoveredInbox);
  const { promiseInProgress } = usePromiseTracker({ area: 'inbox-coverage-requests', delay: 0 });
  const { promiseInProgress: isCallingInbox } = usePromiseTracker({ area: 'sms-inbox-saga', delay: 0 });
  popUp.current = notificationPreference.filter(val => val.name === 'SMS Notification')[0]?.enabled;
  global.current = globalData;
  var recents;
  const toolbarRef = React.useRef<any>();
  const [prevUser, setPrevUser] = React.useState<any>(activeInbox);
  const pinnedPrefMessages = userPreference?.pinnedMessages?.value;
  const pinnedMessages = useSelector(selectPinnedMessages);
  const [sharedDeskFlag] = useDecision(ff_notifications_enable_shareddesk_communication);

  const getUnreadConversationCount = (arr: IUnreadSmsCount[]) => {
    return arr?.reduce((sum, element) => sum + element.count, 0) ?? 0;
  };

  useEffect(() => {
    if (globalData.sms.open) {
      setShowNotificationBackdrop(false);
      setOpen(false);
    }
    if (globalData.inbox) {
      setInboxMessages({ results: globalData.inbox });
      // eslint-disable-next-line react-hooks/exhaustive-deps
      recents = globalData?.inbox;
    }
    globalNotification.current = globalData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalData, activeInbox, coveredInboxes]);

  React.useEffect(() => {
    if ((user?.userInfo?.employeeId).toString() === activeInbox?.coveredUserId) {
      if (!isNullOrUndefined(pinnedPrefMessages) && !isEmpty(pinnedPrefMessages)) {
        dispatch(notificationDataActions.setPinnedMessages(pinnedPrefMessages));
        if (inboxMessages?.results?.length > 0) {
          const updatedRecent = inboxMessages?.results?.filter(data =>
            getInboxLogIds(pinnedPrefMessages)?.every((id: string) => data.log?.id !== id),
          );
          setRecentMessages(updatedRecent);
        }
      }
    } else {
      dispatch(notificationDataActions.setPinnedMessages([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinnedPrefMessages, activeInbox?.coveredUserId, inboxMessages?.results?.length]);

  React.useEffect(() => {
    if (coveredInboxes.length > 0 || activeInbox.coveredUserId) {
      dispatch(notificationDataActions.getUnreadCount());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coveredInboxes?.length, activeInbox?.coveredUserId]);

  React.useEffect(() => {
    if (coveredInboxes.length > 0 && prevUser?.coveredUserId !== activeInbox.coveredUserId) {
      fetchInboxMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promiseInProgress, activeInbox.coveredUserId]);

  const handleToggle = inboxMessage => {
    if (!global.current.sms.open) {
      setOpen(prevOpen => !prevOpen);
      if (!open && (checkCoverage.enabled || sharedDeskFlag.enabled)) {
        dispatch(
          coverageActions.fetchCoveredInbox({
            coveringEmployeeIds: [user.userInfo.employeeId],
            startDate: new Date(),
            endDate: new Date(),
            pageNumber: 1,
            pageSize: 50,
            statusIds: [4],
            sharedDeskFlagEnabled: sharedDeskFlag.enabled,
            coverageFlagEnabled: checkCoverage.enabled,
          }),
        );
      }
      fetchInboxMessage(true);
    }
  };
  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
    setDisplaySearchResults(false);
    setSearchValue('');
    handleSavePinned(false);
  };
  //function to save the preference to storage
  const handleSavePinned = (isOpen: boolean) => {
    if (activeInbox?.coveredUserId === user.userInfo.employeeId.toString()) {
      if (
        !isOpen &&
        open &&
        (appliedFilterValues === undefined || appliedFilterValues?.length === 0) &&
        pinnedMessages !== pinnedPrefMessages
      ) {
        dispatch(
          userDevicePreferenceActions.savePinnedMessagePreferenceAction({
            id: PreferenceCosmosKeys.pinnedMessages,
            value: pinnedMessages,
          }),
        );
      }
      setPrevUser(activeInbox);
    }
  };
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }
    prevOpen.current = open;
    return () => {};
  }, [open]);

  React.useEffect(() => {
    if (!global.current.sms.open) {
      const selectedOptions = optionState.filter(option => (inboxFilters || [])?.includes(option.value));
      if (!_.isEqual(filterSet, filterSetValues)) {
        setFilterSetValues(selectedOptions);
        setFilterSet(selectedOptions);
        fetchInboxMessage(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxFilters]);

  const fetchInboxMessage = async (refresh = false) => {
    const isSharedDeskInbox = activeInbox?.coveredUserId == user.userInfo?.sharedProfile?.employeeId;
    const inboxFetchQuery: IInboxQuery = {
      userId: isSharedDeskInbox ? `${user.userInfo?.employeeId}` : `${activeInbox?.coveredUserId}`,
      phoneNumber: activeInbox?.phoneNumber,
      email: activeInbox?.coveredUserEmail,
      filters: inboxFilters,
      pageSize: globalData?.pageSize || PAGE_SIZE,
      pageNumber: refresh ? 1 : globalData.pageNumber + 1,
    };
    if (isSharedDeskInbox) {
      inboxFetchQuery.sharedUserId = `${activeInbox?.coveredUserId}`;
    }
    dispatch(notificationDataActions.getInboxMessages(inboxFetchQuery));
  };

  useEffect(() => {
    if (smsInbox) {
      const updatedResult =
        smsInbox?.contacts?.pageNumber > 1
          ? [...globalData.inbox, ...smsInbox?.contacts?.results]
          : smsInbox?.contacts?.results;
      setInboxMessages({ results: updatedResult });
      dispatch(notificationDataActions.updateInbox(updatedResult));
      dispatch(notificationDataActions.updateNextPageExistsFlag(smsInbox?.contacts?.nextPageExists));
      dispatch(notificationDataActions.updatePageCounter(smsInbox?.contacts?.pageNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsInbox]);

  const storedScrollPosition = useRef(0);

  const handleScroll = async e => {
    const element = e.target;
    storedScrollPosition.current = element.scrollTop;

    if (element.scrollHeight - element.scrollTop < element.clientHeight + 5 && globalData?.nextPageExists) {
      fetchInboxMessage().then(setScrollPosition);
    }

    if (Math.floor(element.scrollHeight - element.scrollTop) <= element.clientHeight + 5 && paginationFlag) {
      searchInbox(searchValue, false, searchTypeTab).then(setScrollPosition);
    }
  };

  const setScrollPosition = () => {
    const element = document.querySelector('.scroll-class');
    if (element) {
      element.scrollTop = storedScrollPosition.current;
    }
  };

  useEffect(() => {
    if (inboxMessages?.results?.length > PAGE_SIZE) {
      setScrollPosition();
    }
  }, [inboxMessages?.results]);

  const handleToasterClose = id => {
    setPopUpMessage(popUpMessage.filter(x => x.id !== id));
  };

  const updateReadStatus = inboxMessage => {
    const readStatusPayload: IInboxReadStatusCommand = {
      sender: {
        phoneNumber: inboxMessage?.log?.message?.sender?.phoneNumber,
      },
      contactId: inboxMessage?.log?.message?.tos?.find(Boolean)?.contactId?.toString() || '',
      isRead: true,
      channel: ChannelType.sms,
      readOn: new Date(),
      phoneNumber: inboxMessage?.log?.message?.tos?.find(Boolean)?.phoneNumber || '',
    };
    dispatch(notificationDataActions.updateMessageReadStatus(readStatusPayload));
    const updatedInboxMessage = global.current.inbox.map(x =>
      (x.travelerId !== '' && x.travelerId === readStatusPayload.contactId) ||
      (x.travelerId === '' && x.name === readStatusPayload.phoneNumber)
        ? { ...x, unreadLogs: 0 }
        : x,
    );
    setInboxMessages({ results: updatedInboxMessage });
    dispatch(notificationDataActions.updateInbox(updatedInboxMessage));
  };

  // Opens the SMS modal
  // Makes readStaus API call for contactId
  // fetches user logs
  //  Updates Inbox messages
  const OpenSmsModal = async inboxMessage => {
    handleSavePinned(true);
    if (!global.current.sms.open) {
      setShowNotificationBackdrop(true);
    }
    if (
      (checkCoverage && coveredInboxes?.length > 1) ||
      (sharedDeskFlag.enabled &&
        !isNullOrUndefined(user.userInfo?.sharedProfile) &&
        parseInt(activeInbox?.coveredUserId) != user.userInfo?.employeeId)
    ) {
      dispatch(
        coverageActions.setActiveInbox(
          coveredInboxes.find(x => x.phoneNumber === inboxMessage?.log?.message?.sender?.phoneNumber),
        ),
      );
    }
    const smsLogsPayload = {
      contactId: inboxMessage?.log?.message?.tos?.find(Boolean)?.contactId
        ? `${inboxMessage?.log?.message?.tos?.find(Boolean)?.contactId}`
        : '',
      pageSize: PAGE_SIZE,
      pageNumber: 1,
      channel: ['SMS'],
      initiatedBy:
        //here we set the actual sender, if the modal is initiated by covering user
        (checkCoverage && coveredInboxes?.length > 1) ||
        (sharedDeskFlag.enabled && !isNullOrUndefined(user.userInfo?.sharedProfile))
          ? inboxMessage?.log?.message?.sender?.phoneNumber
          : user?.userInfo?.phoneNumber,
      contactPhone: `${inboxMessage?.log?.message?.tos?.find(Boolean)?.phoneNumber}`,
    };
    let candidateDetails: any = {};
    if (inboxMessage?.log?.message?.tos?.find(Boolean)?.contactId) {
      const brandDetails = inboxMessage?.log?.associatedRecords?.find(x => x.name.toLowerCase() === 'brandid');
      const inboxLog = inboxMessage?.log?.message?.tos?.find(Boolean);
      const contactId = inboxLog?.contactId;
      const tosBrandId = inboxLog?.brandId;
      const isBrandInvalid = !brandDetails?.value || brandDetails?.value === 'undefined' || brandDetails?.value === '0';

      const brandValue = isBrandInvalid
        ? tosBrandId === 'undefined' || !tosBrandId || tosBrandId === '0'
          ? '1'
          : tosBrandId
        : brandDetails?.value;

      const response = await getCandidateById(contactId, brandValue);

      if (!response)
        dispatch(
          globalActions.setSnackBar({
            message: t('candidate.credentials.networkError'),
            severity: 'error',
          }),
        );
      candidateDetails = response;
    }
    const travelerId = candidateDetails?.travelerId;

    const associatedRecord = [
      { name: 'travelerId', value: `${candidateDetails?.travelerId}` },
      { name: 'recruiterId', value: `${candidateDetails?.recruiter?.recruiterId}` },
      {
        name: 'recruiter',
        value: Concatenate([candidateDetails?.recruiter?.first, candidateDetails?.recruiter?.last], ' '),
      },
      { name: 'brandId', value: `${candidateDetails?.brandId}` },
    ];
    const smsData = {
      associatedRecords: !!travelerId ? associatedRecord : [],
      channel: ChannelType.sms,
      body: '',
      sender: inboxMessage.log.message.sender,
      sendType: SendType.one_to_one,
      tos: inboxMessage?.log?.message?.tos,
      useType: UseType.Candidate,
      brand: user?.userInfo?.companyBrandName,
      brandId: user?.userInfo?.companyId?.toString(),
      userRole: UserRoles.recruitment,
      unknownContact: !!travelerId ? false : true,
    };
    const chatHistoryPayload = Object.create({});
    chatHistoryPayload.candidateDetails = candidateDetails;
    const response = await GetUserLogs(smsLogsPayload);
    chatHistoryPayload.chats = response;
    const sms = global.current.sms;
    if (
      (sms.open || sms.minimized) &&
      (sms.data?.body?.length > 0 || (sms?.newNotes || '')?.length > 0 || sms.attachments !== undefined)
    ) {
      dispatch(notificationDataActions.setSmsInteraction({ open: true, minimized: false, readonly: false }));
      global.current.sms.data?.tos[0]?.contactId !== inboxMessage.travelerId
        ? dispatch(notificationDataActions.setSnackBarData({ channel: 'sms', manual: false, changeWarning: true }))
        : updateReadStatus(inboxMessage);
    } else {
      updateReadStatus(inboxMessage);
      dispatch(
        notificationDataActions.setNotificationData({
          ...global.current,

          sms: {
            ...global.current.sms,
            chatHistory: chatHistoryPayload,
            data: smsData,
            open: true,
            minimized: false,
            readonly: false,
            isBrandSelectionSkipped: undefined,
            sendType: SmsType.one_to_one,
            manualEntry: false,
            manuallyEnteredNumber: null,
          },
          snackbarData: null,
        }),
      );
    }
  };

  const closeNotification = (e, closeToast) => {
    closeToast();
    e.stopPropagation();
  };

  const CloseButton = ({ closeToast }) => {
    return <ClearIcon className={classes.closeIcon} onClick={e => closeNotification(e, closeToast)} />;
  };

  useEffect(
    () => {
      const hubConnection = user?.userInfo?.hubConnection;
      if (hubConnection) {
        hubConnection.on('Broadcast', (user, eventName, log) => {
          trackEvent({
            type: 'event',
            name: `SignalR Hub`,
            properties: {
              NotificationType: SignalREventType.Inbox,
              UserId: user,
              CorrelationId: log?.correlationId,
              Log: log,
              Description: `Received inbox message notification for user: ${user}`,
            },
          });

          var listenerProp: HubEventListenerProps = {
            user,
            eventName,
            log,
            coverageEnabled: checkCoverage.enabled,
          };

          dispatch(notificationDataActions.handleHubEvent(listenerProp));

          const updatedPopUpMessage = incomingMessages.current;

          /* Showing toaster pop up alert */
          var unreadMessagesCount;
          const getUnreadMessagesCount = (id: any) => {
            inboxMessages?.results.map(item => {
              if (id === item.travelerId) {
                unreadMessagesCount = item.unreadLogs;
              }
            });
          };

          getUnreadMessagesCount(log?.message?.tos?.find(Boolean)?.contactId);

          const contentProps: INotificationPopupProps = {
            id: log.id,
            name: log?.message?.tos?.find(Boolean).name,
            travelerId: log?.message?.tos?.find(Boolean).contactId,
            body: log?.message?.body,
            phoneNumber: log?.message?.tos?.find(Boolean).phoneNumber,
            attachments: log.message.attachmentUrls,
          };

          popUp.current &&
            !prevOpen.current &&
            eventName === HubEventType.InboundSms &&
            toast(<MessagePopup {...contentProps} />, {
              toastId: contentProps.id,
              containerId: 'inbox',
              position: 'top-right',
              closeButton: true,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 5000,
              onClick: () => OpenSmsModal({ log }),
              onClose: () => handleToasterClose(log.message.id),
            });
          setPopUpMessage([log, ...updatedPopUpMessage]);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handlePinAndUnpin = (item, pin) => {
    switch (pin) {
      case true:
        //unpinning
        let unpinResult = handleUnpinMessage(inboxMessages?.results, item, pinnedMessages);
        dispatch(notificationDataActions.setPinnedMessages(unpinResult.pinnedMessages));
        setRecentMessages(unpinResult.messages);
        break;
      case false:
        //pinning
        let pinResult = handlePinMessage(inboxMessages?.results, item, pinnedMessages, recentMessages);
        if (pinResult.pinnedMessage) {
          dispatch(notificationDataActions.setPinnedMessages([...pinnedMessages, ...pinResult.pinnedMessage]));
          setRecentMessages(pinResult.messages);
        }
        setOpenModal(pinResult.pinnedLimitReached);
        break;
    }
  };
  useEffect(() => {
    //a function which triggers itself when there is any change in the inbox data to update the recent & pinned chats
    if (
      inboxMessages?.results &&
      (appliedFilterValues === undefined || appliedFilterValues?.length === 0) &&
      filterSetValues?.length === 0
    ) {
      const updatedPinnedChats: any[] = [];
      // eslint-disable-next-line react-hooks/exhaustive-deps
      recents = inboxMessages?.results;
      pinnedMessages?.map(item => {
        if (item !== null && item !== undefined) {
          const pin = inboxMessages?.results?.find(
            data =>
              (data?.travelerId === item?.travelerId || data?.name === item?.name) &&
              data?.phoneNumber === item?.phoneNumber,
            data =>
              (data?.travelerId === item?.travelerId || data?.name === item?.name) &&
              data?.phoneNumber === item?.phoneNumber,
          );
          updatedPinnedChats.push(pin);
        }
      });
      if (!_.isEqual(updatedPinnedChats, pinnedMessages))
        dispatch(notificationDataActions.setPinnedMessages(updatedPinnedChats));
      getInboxLogIds(updatedPinnedChats)?.map((id: string) => {
        recents = recents?.filter(data => data.log?.id !== id);
      });
      setRecentMessages(recents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboxMessages?.results]);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleShow = item => {
    if (item === 'recents') {
      setRecentInbox(!recentInbox);
    } else {
      setPinnedInbox(!pinnedInbox);
    }
  };

  const handleFetch = async (keyword: string, refresh = false, searchType = searchTypeTab) => {
    if (keyword?.length > 2) {
      const isSharedDeskInbox = activeInbox?.coveredUserId === user.userInfo?.sharedProfile?.employeeId;
      const searchInboxQuery: IInboxQuery = {
        userId: isSharedDeskInbox
          ? `${user.userInfo?.employeeId}`
          : `${activeInbox?.coveredUserId || user?.userInfo?.employeeId}`,
        phoneNumber: activeInbox?.phoneNumber || user?.userInfo?.phoneNumber,
        email: activeInbox?.coveredUserEmail || user?.userInfo?.email,
        pageSize: globalData?.pageSize || PAGE_SIZE,
        pageNumber: refresh ? 1 : pageNumber + 1,
        search: keyword,
        searchType: searchType,
      };
      if (isSharedDeskInbox) {
        searchInboxQuery.sharedUserId = `${activeInbox?.coveredUserId}`;
      }
      await getInboxMessages(searchInboxQuery).then(res => {
        if (searchType === 'c') {
          setPaginationFlag(res?.contacts?.nextPageExists);
          setPageNumber(res?.contacts?.pageNumber);
          refresh
            ? setContactSearchResults([...res?.contacts?.results])
            : setContactSearchResults([...contactSearchResults, ...res?.contacts?.results]);
        } else {
          setPaginationFlag(res?.messages?.nextPageExists);
          setPageNumber(res?.messages?.pageNumber);
          refresh
            ? setMessageSearchResults([...res?.messages?.results])
            : setMessageSearchResults([...messageSearchResults, ...res?.messages?.results]);
        }
        setDisplaySearchResults(true);
      });
    } else {
      setDisplaySearchResults(false);
    }
  };

  const searchInbox = async (keyword: string, refresh = false, searchType = searchTypeTab) => {
    setSearchValue(keyword);
    setSearchTypeTab(searchType);
    refresh && setPageNumber(1);
    debounceService(keyword, refresh, searchType);
  };

  const debounceService = useMemo(
    () => debounce((keyword, refresh, searchType) => handleFetch(keyword, refresh, searchType), 300),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeInbox],
  );

  const handleStopSearch = () => {
    setSearchValue('');
    setDisplaySearchResults(false);
    setContactSearchResults(null);
    setMessageSearchResults(null);
  };

  const handleInboxChange = item => {
    dispatch(notificationDataActions.updateInbox([]));
    dispatch(notificationDataActions.updateNextPageExistsFlag(false));
    dispatch(notificationDataActions.updatePageCounter(1));
  };

  const openManualSmsModal = () => {
    handleSavePinned(true);
    if (!global.current.sms.open) {
      setShowNotificationBackdrop(true);
    }

    const smsData = {
      associatedRecords: defaultAssociatedRecord,
      channel: ChannelType.sms,
      body: '',
      sender: getDefaultSender(user),
      sendType: SendType.one_to_one,
      tos: [
        {
          phoneNumber: '',
          contactType: 'candidate',
        },
      ],
      useType: UseType.Candidate,
      userRole: UserRoles.recruitment,
    };
    const chatHistoryPayload = Object.create({});
    chatHistoryPayload.candidateDetails = null;
    chatHistoryPayload.chats = [];
    dispatch(
      notificationDataActions.setNotificationData({
        ...global.current,

        sms: {
          ...global.current.sms,
          chatHistory: chatHistoryPayload,
          data: smsData,
          open: true,
          minimized: false,
          readonly: false,
          isBrandSelectionSkipped: undefined,
          sendType: SmsType.one_to_one,
          manualEntry: true,
          manuallyEnteredNumber: null,
        },
        snackbarData: null,
      }),
    );
  };

  const setInboxMessagesResult = msgs => {
    setInboxMessages({ results: msgs });
  };

  const setPinnedMessages = msgs => {
    dispatch(notificationDataActions.setPinnedMessages(msgs));
  };

  const markAllAsRead = () => {
    const updatedInboxMessage = global?.current?.inbox?.map(x => ({ ...x, unreadLogs: 0 }));
    setInboxMessages({ results: updatedInboxMessage });
    dispatch(notificationDataActions.updateInbox(updatedInboxMessage));

    setMessageSearchResults(
      messageSearchResults?.map(message => {
        return { ...message, unreadLogs: 0 };
      }),
    );
    setContactSearchResults(
      contactSearchResults?.map(message => {
        return { ...message, unreadLogs: 0 };
      }),
    );
  };

  return (
    <>
      <CustomBackdrop open={showNotificationBackdrop} />
      <Tabs
        TabIndicatorProps={{ style: { background: '#52429A' } }}
        ref={anchorRef}
        value={open}
        onChange={handleToggle}
        aria-label="wrapped label tabs example"
      >
        <Tab
          classes={{ root: classes.tab }}
          value={true}
          icon={
            <Badge
              color={'error'}
              className={getUnreadConversationCount(unreadCountArr) > 99 ? classes.countBadge : classes.badge}
              badgeContent={getUnreadConversationCount(unreadCountArr)}
              invisible={getUnreadConversationCount(unreadCountArr) === 0}
            >
              <img src={HeaderMessageIcon} alt="HeaderMessageIcon" id="MessageIconId" />
            </Badge>
          }
        />
      </Tabs>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        modifiers={{
          offset: {
            enabled: true,
            offset: '-110, 5',
            left: -20,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper className={classes.paper} elevation={5}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList className={classes.notificationViewer} autoFocusItem={open} id="menu-list-grow">
                  <InboxToolbar ref={toolbarRef}>
                    <img src={CircularMessage} className={classes.titleIcon} alt="CircularMessage" />
                    <SelectInbox
                      onChangeHandler={item => handleInboxChange(item)}
                      toolbarRef={toolbarRef}
                      setPrevUser={setPrevUser}
                      modalOpen={open}
                      popOverOpen={popOverOpen}
                      setPopOverOpen={setPopOverOpen}
                      savePinnedConversations={handleSavePinned}
                      markAllAsRead={markAllAsRead}
                    />
                    <div className={classes.filterIcon}></div>
                  </InboxToolbar>
                  <div className={classes.flexContainer}>
                    <TextField
                      fullWidth
                      size="small"
                      id="searchBox"
                      className={classes.searchBox}
                      variant="outlined"
                      placeholder={t('search.filter.Search')}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon style={{ color: '#CCCCCC' }} />
                          </InputAdornment>
                        ),
                        endAdornment: displaySearchResults ? (
                          <InputAdornment position="start">
                            <IconButton color="primary" onClick={handleStopSearch} size="small">
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ) : undefined,
                        autoFocus: true,
                      }}
                      value={searchValue}
                      onKeyDown={event => {
                        event.stopPropagation();
                        if (event.key === 'Enter') {
                          searchInbox(searchValue?.trim(), true, searchTypeTab);
                        }
                      }}
                      onChange={event => setSearchValue(event.target.value)}
                    />
                    <InboxMessageFilter
                      justifyContent={'Left'}
                      isMultiSelect={true}
                      onChange={values => setFilterSetValues(values)}
                      value={filterSetValues}
                      hiddenInput={true}
                      options={optionState}
                      apply={() => {}}
                      getOptionDisabled={option => {
                        if (option?.value === 2) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                    />
                    <div className={classes.smsContainer}>
                      <Button
                        disableRipple
                        variant="outlined"
                        classes={{ root: classes.smsIcon }}
                        data-testid="Manual-SMS-Button"
                        onClick={openManualSmsModal}
                      >
                        <ChatOutlined />
                      </Button>
                    </div>
                  </div>
                  <MessageDivider />
                  {isCallingInbox ? (
                    <Grid item xs={12}>
                      <MessageCardSkeleton />
                      <MessageCardSkeleton />
                      <MessageCardSkeleton />
                      <MessageCardSkeleton />
                      <MessageCardSkeleton />
                    </Grid>
                  ) : (
                    <div>
                      {getUnreadConversationCount(unreadCountArr) === 0 && appliedFilterValues?.length > 0 ? (
                        <NoFilterResult />
                      ) : (
                        <div className={`${classes.scroll} scroll-class`} onScroll={handleScroll}>
                          {!displaySearchResults ? (
                            <Inbox
                              pinnedInbox={pinnedInbox}
                              handleScroll={handleScroll}
                              inboxMessages={inboxMessages}
                              setInboxMessagesResult={setInboxMessagesResult}
                              handleShow={handleShow}
                              recentMessages={recentMessages}
                              setRecentMessages={setRecentMessages}
                              recentInbox={recentInbox}
                              handlePinAndUnpin={handlePinAndUnpin}
                              OpenSmsModal={OpenSmsModal}
                              setOpen={setOpen}
                              isFilterApplied={inboxFilters?.length > 0}
                              popOverOpen={popOverOpen}
                              setPinnedMessages={setPinnedMessages}
                            />
                          ) : (
                            <InboxSearchResults
                              searchText={searchValue}
                              contactsResults={contactSearchResults}
                              setContactSearchResults={setContactSearchResults}
                              messagesResults={messageSearchResults}
                              setMessageSearchResults={setMessageSearchResults}
                              OpenSmsModal={OpenSmsModal}
                              setShowNotificationBackdrop={setShowNotificationBackdrop}
                              setInboxMessages={setInboxMessages}
                              setDisplaySearchResults={setDisplaySearchResults}
                              setSearchValue={setSearchValue}
                              searchInbox={searchInbox}
                              searchTypeTab={searchTypeTab}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <div className={classes.fixedPos}>
        <StyledToastContainerSMS enableMultiContainer containerId={'inbox'} limit={3} closeButton={CloseButton} />
      </div>
      <PinnedLimitDialog dialogeOpen={openModal} handleClose={handleModalClose} />
    </>
  );
};
