import { IUserDetails } from '@AMIEWEB/Settings/UserManagement/store/userManagement.redux';
import { signatureSubstitutionCovered } from './SignatureSubstituteCovered';
import { signatureSubstitution } from './SignatureSubstitute';
import { SendType, UseSubType, UseType } from '@AMIEWEB/Notification/Constants';
import { placementStatusAction } from 'store/redux-store/placement-status/slice';
import { GridTag } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.redux';
import { getUniqueEmails } from 'app/ComponentLibrary/Molecules/EmailValidator';
import { missingField } from '../Sms/CredentialingAnalyst/PlacementDisplay';
import { trackEvent } from 'app-insights/appInsightsTracking';
import moment from 'moment';
import { IEmailSender, IEmployee, IPublishEmail, ISenderMenu, IToken } from 'app/models/Notification/Notification';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { publishEmail } from 'app/services/NotificationServices/NotificationService';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { candidateDetailActions } from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { UpdateAlertEmailData } from 'app/services/TaskServices/TaskServices';
import _ from 'lodash';
import i18next from 'i18next';
import { IUserInfo } from 'oidc/user.redux';
export const getModalTitle = (activeStep: number, selectedTemplateTitle: string, t: Function) => {
  switch (activeStep) {
    case 1:
      return t('notification.emails.sendEmailTitle') + (selectedTemplateTitle ? `: ${selectedTemplateTitle}` : '');
    case 2:
      return t('notification.emails.previewEmailTitle');
    case 3:
      return t('notification.emails.savePersonalTemplateTitle');
  }
};

export const canSaveDraft = () => {
  return false;
};

export const getSignatureSubstitution = (senderMenuValue, user, userDetails, userPreference) => {
  if (senderMenuValue && Number(senderMenuValue?.id) !== user.userInfo.employeeId) {
    if (userDetails) {
      const selectedUser: IUserDetails = userDetails?.filter(c => c?.employeeId === senderMenuValue?.id)[0];
      return signatureSubstitutionCovered(selectedUser);
    }
  } else {
    return signatureSubstitution(user, userPreference);
  }
};

export const sendEmail = async (payload: IPublishEmail): Promise<boolean> => {
  const {
    dispatch,
    notificationData,
    attachments,
    gridTag,
    emailType,
    emailConsents,
    communicationLimitsEmail,
    emailSendType,
    senderMenuValue,
    user,
    userDetails,
    userPreference,
    sharedDeskFlag,
    globalData,
    originalAttachmentUrls,
    urlAttachments,
    candidateDetails,
    handleSubmitResponse,
    successMessage,
    errorMessage,
    loader,
    setLoader,
  } = payload;

  let data = globalData?.email?.data!;
  let publishStatus = false;
  /** Code: Updated packet status, if invoked from Placement - Submit packet modal  */
  if (notificationData.useType === UseType.Placement && notificationData.useSubType === UseSubType.PacketSubmission) {
    dispatch(placementStatusAction.updateEfoDocToOkToSend({ ...notificationData?.attachmentData?.documentPacket }));
  }
  setLoader({ ...loader, backdropLoader: true });
  const emailForm = new FormData();
  attachments && attachments.map(attachment => emailForm.append('attachments', attachment ?? null));
  const body = notificationData.body?.replaceAll('&gt;', '>');
  const emailData = { ...notificationData, body: body };
  const publishData = JSON.parse(JSON.stringify(emailData));
  publishData.substitutions = {};
  if (
    gridTag === GridTag.FacilityClientContactsGrid ||
    data.packetSubmission ||
    data?.confirmationLetter ||
    data.useType === UseType.Bulk ||
    data.useType === UseType.ClientContacts
  ) {
    publishData.sendType = emailType;
  }

  validateAndAddRecipients(emailConsents, communicationLimitsEmail, emailSendType, publishData);

  substituteTokens(emailData, userDetails, senderMenuValue, user, userPreference, publishData);

  if (!!emailData.attachmentCannotBeRemoved) {
    publishData.attachmentUrls = emailData.attachmentUrls!.map(x => x.url);
  }

  if (!!senderMenuValue && !!senderMenuValue?.coveredUser) {
    publishData.associatedRecords = [
      ...publishData.associatedRecords,
      {
        name: 'coveringEmployeeId',
        value: user.userInfo.employeeId,
      },
    ];
  }

  if (!!data.facilityOneToOne) {
    publishData.associatedRecords = [
      ...publishData.associatedRecords,
      {
        name: 'initiatedFrom',
        value: 'FacilityGridEmailLink',
      },
    ];
  }

  addSharedDeskInfoInAssociatedRecords(sharedDeskFlag, user, globalData, publishData);

  setPlacementDocs(notificationData, originalAttachmentUrls, urlAttachments, publishData);

  emailForm.append('publishEmail', JSON.stringify(publishData));

  await publishEmail(emailForm)
    .then(response => {
      publishStatus = response?.data?.published;

      if (!publishStatus) {
        setLoader({ ...loader, backdropLoader: false });
        handleSubmitResponse(true, 'error', errorMessage);
        return publishStatus;
      }

      setLoader({ ...loader, backdropLoader: false });
      handleSubmitResponse(true, 'success', successMessage);
      dispatch(
        notificationDataActions.setNotificationData({
          ...globalData,
          email: {
            data: globalData.email?.data,
            open: false,
            minimized: false,
            expanded: false,
            forwardAttachmentUrls: [],
          },
        }),
      );

      dispatch(globalActions.setActiveStep(1));
      dispatch(globalActions.setDeletedAttachments([]));
      dispatch(notificationDataActions.setCommunicationLimitsEmail([]));

      dispatch(candidateDetailActions.updateLastContactedOn(moment(new Date()).toISOString()));
      dispatch(
        candidateDetailActions.setKpis({
          ...candidateDetails.kpis,
          lastContacted: moment(new Date()).toISOString(),
        }),
      );
    })
    .then(() => {
      if (!notificationData.attachmentUrls || notificationData.alertId) return;
      const date = new Date();
      const chatsResult = _.cloneDeep(globalData.sms.chatHistory!.chats!);
      chatsResult.results?.forEach(result => {
        result.message.attachments?.forEach(attachment => {
          const index = notificationData.attachmentUrls!.findIndex(x => x.url === attachment.contentUrl);
          if (index !== -1) attachment.dateDeliveredToKofax = date;
        });
      });
      chatsResult.notifySmsUponAttachmentSendViaEmail = true;
      dispatch(notificationDataActions.updateChat(chatsResult));
    })
    .catch(() => {
      setLoader({ ...loader, backdropLoader: false });
      handleSubmitResponse(true, 'error', errorMessage);
      return publishStatus;
    });

  updateFailedEmailForwardInfo(notificationData);

  return publishStatus;
};

const updateFailedEmailForwardInfo = notificationData => {
  if (notificationData?.alertId) {
    const newToAssociatedRecords = notificationData?.tos?.map(to => ({
      name: 'ForwardedTo',
      value: to.email,
    }));
    const newCCAssociatedRecords = notificationData.ccs.map(cc => ({
      name: 'ForwardedTo',
      value: cc.email,
    }));
    const newBCCAssociatedRecords = notificationData.bccs.map(bcc => ({
      name: 'ForwardedTo',
      value: bcc.email,
    }));
    const newAssociatedRecords = [...newToAssociatedRecords, ...newCCAssociatedRecords, ...newBCCAssociatedRecords];

    let patchPayload = {
      id: notificationData.alertId,
      partitionKey: notificationData.alertPartitionKey,
      associatedRecords: newAssociatedRecords,
    };

    UpdateAlertEmailData(notificationData.alertId, patchPayload);
  }
};

const addSharedDeskInfoInAssociatedRecords = (sharedDeskFlag, user, globalData, publishData) => {
  if (
    sharedDeskFlag &&
    !isNullOrUndefined(user.userInfo?.sharedProfile) &&
    globalData?.email?.data?.sender?.email === user.userInfo?.sharedProfile?.email
  ) {
    publishData.associatedRecords = [
      ...publishData.associatedRecords,
      {
        name: 'sharedDeskPublisherName',
        value: `${user.userInfo?.firstName} ${user.userInfo?.lastName}`,
      },
      {
        name: 'sharedUserId',
        value: user.userInfo?.sharedProfile?.employeeId,
      },
      {
        name: 'sharedUsername',
        value: `${user.userInfo?.sharedProfile?.firstName} ${user.userInfo?.sharedProfile?.lastName}`,
      },
    ];
  }
};

const setPlacementDocs = (notificationData, originalAttachmentUrls, urlAttachments, publishData) => {
  if (
    notificationData?.alertId ||
    notificationData?.useSubType === UseSubType.PacketSubmission ||
    notificationData?.useSubType === UseSubType.ConfirmationLetter
  ) {
    const newAttachmentUrls = [];
    originalAttachmentUrls?.forEach(attachmentUrl => {
      urlAttachments?.forEach(urlAttachment => {
        const splitted = attachmentUrl?.split('/');
        const attachmentName = splitted[splitted?.length - 1];
        if (attachmentName === urlAttachment.name) {
          newAttachmentUrls.push(attachmentUrl);
        }
      });
    });
    publishData.attachmentUrls = newAttachmentUrls;
  }
};

const validateAndAddRecipients = (emailConsents, communicationLimitsEmail, emailSendType, publishData) => {
  const validEmail: any = [];
  const uniqueEmails: any[] = getUniqueEmails(publishData?.tos, emailConsents ?? [], communicationLimitsEmail ?? []);
  const suppressedEmails = uniqueEmails
    ?.filter(item => item?.limitReached)
    ?.map(rec => ({
      candidateId: rec?.contactId?.toString(),
      brandId: rec?.brandId?.toString(),
      email: rec?.email,
    }));

  uniqueEmails?.forEach(item => {
    if (
      item.email &&
      item.email !== missingField &&
      item?.email !== undefined &&
      item?.email !== '' &&
      !(emailSendType === SendType.bulk && item?.hasConsentWithdrawn) &&
      !item?.limitReached
    )
      validEmail.push(item);
  });
  validEmail?.forEach(item => item?.hasConsentWithdrawn && delete item?.hasConsentWithdrawn);
  publishData.tos = validEmail;

  // appInsights call
  if (suppressedEmails?.length > 0) {
    trackEvent({
      type: 'event',
      name: 'EmailSuppression',
      properties: {
        date: moment(new Date()).format('YYYY/MM/DD'),
        records: suppressedEmails,
      },
    });
  }
};

const substituteTokens = (emailData, userDetails, senderMenuValue, user, userPreference, publishData) => {
  //Adding substituTion needed for email
  if (emailData?.body && emailData?.substitutions) {
    const substitution = {};
    emailData?.substitutions?.map((item: { input: string; value: any }) => {
      substitution[item?.input.replace('{{', '').replace('}}', '')] = item?.value;
    });
    publishData.substitutions = substitution;
  }

  //Adding signature substitution needed for email
  if (emailData?.body && userDetails) {
    var signatureSubstitutions = getSignatureSubstitution(senderMenuValue, user, userDetails, userPreference);
    if (signatureSubstitutions) {
      const signatures = {};
      publishData.signatureSubstitutions = signatureSubstitutions?.map((item: IToken) => {
        if (item && item.input) {
          signatures[item?.input?.replace('{{', '').replace('}}', '')] = item?.value;
        }
      });
      publishData.signatureSubstitutions = signatures;
    }
  }
};

const getSenderName = (selectedMenu: ISenderMenu, currentUser: ISenderMenu, packetSubmission: boolean, clientConfirmation: boolean) => {
  if (selectedMenu?.id != currentUser?.id && !packetSubmission && !clientConfirmation) {
    return currentUser?.name + i18next.t('coverageConfirmation.prompt.2') + selectedMenu?.name;
  } else {
    return selectedMenu?.name;
  }
};

export const getSelectedSender = (selectedMenu: ISenderMenu, currentUser: ISenderMenu, packetSubmission: boolean, clientConfirmation: boolean) => {
  const selectedUser : IEmailSender = {
    name: getSenderName(selectedMenu, currentUser, packetSubmission, clientConfirmation),
    email: selectedMenu?.email,
    userId: selectedMenu?.id,
    senderId: selectedMenu?.id,
    NtUserName: '',
  };
  return selectedUser;
}

export const getActualBCCs = (
  selectedUser: IEmailSender,
  selectedMenu: ISenderMenu,
  bccs: IEmployee[],
  senderInfo: IEmployee,
  userInfo: IUserInfo,
) => {
  const selectedCoveredUser = { ...selectedUser, name: selectedMenu?.name };
  const otherBCCs = bccs?.filter?.(
    bcc => (!isNullOrUndefined(bcc?.name) && !isNullOrUndefined(bcc?.userId)) || !isNullOrUndefined(bcc?.email),
  );
  const actualBCCs = [...otherBCCs, selectedCoveredUser];
  let filteredBCCs = [];
  if (parseInt(senderInfo?.userId) !== userInfo?.employeeId) {
    filteredBCCs = actualBCCs?.filter(bcc => bcc?.userId !== senderInfo?.userId);
  } else {
    filteredBCCs = actualBCCs;
  }

  return filteredBCCs;
};