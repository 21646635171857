import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';

import { initialState } from './OrderCreation.redux';

const selectDomain = (state: RootState) => state.orderCreationNew || initialState;

export const selectActiveStep = createSelector([selectDomain], orderState => orderState.activeStep);
export const selectPreviewTabSelected = createSelector([selectDomain], orderState => orderState.previewTabSelected);
export const selectUnitId = createSelector([selectDomain], orderState => orderState.selectedUnitId);
export const selectedUnit = createSelector([selectDomain], orderState => orderState.selectedUnitItem);
export const selectedUnitSkillSets = createSelector([selectDomain], orderState => orderState.selectedUnitSkillsSets);
export const selectedOrderDetails = createSelector([selectDomain], orderState => orderState.orderResponseDetails);
export const selectedOrderTypeDetails = createSelector([selectDomain], orderState => orderState?.page1);
export const selectedExperienceSet = createSelector([selectDomain], orderState => orderState?.page1?.skillSetGroup);
export const selectPage2 = createSelector([selectDomain], orderState => orderState.page2);
export const selectClinicianToPatientRatio = createSelector(
  [selectDomain],
  orderState => orderState?.page2?.orderClinicianToPatientRatio,
);
export const selectPage2DefaultValues = createSelector(
  [selectDomain],
  orderState => orderState.page2.areDefaultValueSet,
);
export const selectPage3DefaultValues = createSelector(
  [selectDomain],
  orderState => orderState.page3.areDefaultValueSet,
);
export const selectOrderWorkHistory = createSelector([selectDomain], orderState => orderState?.page2?.orderWorkHistory);
export const selectOrderPriorities = createSelector([selectDomain], orderState => orderState?.orderPriorities);
export const selectSubmissionRequirement = createSelector(
  [selectDomain],
  orderState => orderState?.page2?.virtualRatingSubmissionRequirement,
);
export const selectReference = createSelector([selectDomain], orderState => orderState?.page2?.reference);
export const selectPage3 = createSelector([selectDomain], orderState => orderState.page3);
export const selectTypeAheadDropDownOptions = createSelector(
  [selectDomain],
  orderState => orderState.typeAheadDropdownOptions,
);
export const selectedUnitBestBets = createSelector([selectDomain], orderState =>
  orderState?.orderId ? orderState?.page2?.bestBets : orderState?.selectedUnitItem?.bestBets,
);
export const selectedUnitBestBetsId = createSelector(
  [selectDomain],
  orderState => orderState.selectedUnitItem.bestBetsId,
);

export const selectPage2OrderPriorities = createSelector(
  [selectDomain],
  orderState => orderState?.page2?.orderPriorities,
);

export const selectEditOrderId = createSelector([selectDomain], orderState => orderState?.orderId);
export const selectOrderCreationState = createSelector([selectDomain], orderState => orderState);
export const selectIsEditSuccess = createSelector([selectDomain], orderState => orderState.isEditSuccess);
export const selectPage3Shifts = createSelector([selectDomain], orderState => orderState?.page3?.shifts);
export const isEditLoader = createSelector([selectDomain], orderState => orderState?.editLoader);

export const selectNewOrderCreation = createSelector([selectDomain], orderState => orderState);
export const selectSkillsetDataUIFormat = createSelector(
  [selectDomain],
  orderState => orderState?.page1?.skillSetGroup,
);
export const selectClientContacts = createSelector([selectDomain], orderState => orderState?.clientContacts);
export const selectPreferences = createSelector([selectDomain], orderState => orderState?.preferences);
export const selectDisableNextBtnSidePanel = createSelector(
  [selectDomain],
  orderState => orderState.disableNextBtnSidePanel,
);
export const selectAdditionalRequiredText = createSelector(
  [selectDomain],
  orderState => orderState?.additionalRequiredQualifications,
);
export const selectPreferredQualificationText = createSelector(
  [selectDomain],
  orderState => orderState?.preferredQualifications,
);
export const selectInternalNotes = createSelector([selectDomain], orderState => orderState?.internalNotes);
export const selectJobDescription = createSelector([selectDomain], orderState => orderState?.jobDescription);

export const selectedPositionsAvailable = createSelector(
  [selectDomain],
  orderState => orderState?.page1.positionsAvailable,
);

export const selectNewOrderCreatedFromUnit = createSelector(
  [selectDomain],
  orderState => orderState?.isNewOrderCreatedFromUnit,
);

export const selectOrderLicenseOptions = createSelector([selectDomain], orderState => orderState?.orderLicenseOptions);

export const selectOrderLicenseComapctStatusOptions = createSelector(
  [selectDomain],
  orderState => orderState?.compactStatusOptions,
);
