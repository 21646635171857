/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useMemo, useState } from 'react';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { PaperCard } from './styles';
import { Box, ClickAwayListener, Divider, Grid, Typography, Skeleton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { candidateDetailsSelection, selectEmergencyContact } from '../../../Profile/CandidateDetails.selector';
import { useSelector } from 'react-redux';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { useTranslation } from 'react-i18next';
import { missingField } from 'app/constants';
import AddressIcon from 'app/assets/images/Credentialing/PersonalInfo/address.svg';
import ContactInfoIcon from 'app/assets/images/Credentialing/PersonalInfo/contact_information.svg';
import EmergencyContactIcon from 'app/assets/images/Credentialing/PersonalInfo/emergency_contact.svg';
import PersonalInfoIcon from 'app/assets/images/Credentialing/PersonalInfo/personal_information.svg';
import PersonalInfoIconV2 from 'app/assets/images/CardBuilderCandidate/PersonalInformationIcon.svg';
import AddressIconV2 from 'app/assets/images/CardBuilderCandidate/AddressIcon.svg';
import ContactInfoIconV2 from 'app/assets/images/CardBuilderCandidate/ContactInformationIcon.svg';
import EmergencyContactIconV2 from 'app/assets/images/CardBuilderCandidate/EmergencyContactIcon.svg';
import ApplicationsIconV2 from 'app/assets/images/CardBuilderCandidate/ApplicationQuestionsIcon.svg';
import CaseManagementV2 from 'app/assets/images/CardBuilderCandidate/CaseManagementIcon.svg';
import OpenIcon from 'app/assets/images/CandidateProfile/OpenIcon.svg';
import ApplicationsIcon from 'app/assets/images/Credentialing/PersonalInfo/applications.svg';
import moment from 'moment';
import { AddressGroup } from './AddressGroup';
import {
  ff_candidate_container_ui_v2,
  ff_candidate_ui_manage_NUID,
  ff_candidate_ui_view_ADPID,
  ff_contactInformationVeryCommunicationLink,
} from 'app/constants/FeatureFlags/Candidate/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import ReasonInfo from 'app/assets/images/CandidateHeader/info.svg';
import { ApplicationQuestionsCard } from './ApplicationQuestionsCard';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { selectUser } from 'oidc/user.selectors';
import CaseManagement from 'app/assets/images/Credentialing/PersonalInfo/caseManagement.svg';
import PermanentTaxHomeInfo from 'app/assets/images/Credentialing/PersonalInfo/taxHomeaddress.svg';
import AddIcon from '@mui/icons-material/Add';
import { PermanentTaxHomeCard } from './PermanentTaxHomeInfoCard';
import _ from 'lodash';
import PermanentTaxFormDialogue from '../Form/PermanentTaxFormDailogue';
import { CandidateContainer } from '../../SummaryTab/Common/CandidateContainer';
import { usePromiseTracker } from 'react-promise-tracker';

const useStyles = makeStyles()(theme => ({
  divider: {
    width: '100%',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  fontTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  adjustPaper: {
    marginBottom: '12px',
  },
  email: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#006FB9',
  },
  child: {
    overflow: 'hidden',
    fontSize: '14px',
  },
  subTitle: {
    fontWeight: 'bold',
  },
  consentLink: {
    color: '#106FB9',
    fontSize: '14px',
    paddingLeft: '10px',
    fontWeight: 500,
  },
  consentContainer: {
    textDecoration: 'none',
  },
  consentContainerDisabled: {
    paddingLeft: '5px',
    textDecoration: 'none',
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  tooltipPlacementBottom: {
    margin: 0,
    marginTop: 10,
    position: 'relative',
    left: '-8px',
  },
  reasonInfoIcon: {
    marginTop: 4,
    marginLeft: 4,
  },
  reasonStyle: {
    display: 'flex',
  },

  addIcon: {
    width: '25px',
    height: '25px',
    color: '#888888',
    background: 'primary',
    borderRadius: '50%',
    '&:hover': {
      background: theme.palette.framework.system.lightGray,
      color: `${theme.palette.framework.system.skyBlue} !important`,
    },
  },
}));

export enum displayType {
  personalInfo = 'personalInfo',
  emergency = 'emergency',
  address = 'address',
  contactInfo = 'contactInfo',
  applicationQuestion = 'applicationQuestion',
  caseManagement = 'caseManagement',
  permanentTaxHomeInfo = 'permanentTaxHomeInfo',
}

const iconType = customSwitch({
  [displayType.personalInfo]: <img src={PersonalInfoIcon} alt="Personal Info" />,
  [displayType.emergency]: <img src={EmergencyContactIcon} alt="Emergency Info" />,
  [displayType.address]: <img src={AddressIcon} alt="Address" />,
  [displayType.contactInfo]: <img src={ContactInfoIcon} alt="Contact Info" />,
  [displayType.applicationQuestion]: <img src={ApplicationsIcon} alt="Application" />,
  [displayType.caseManagement]: <img src={CaseManagement} alt="caseManagement" />,
  [displayType.permanentTaxHomeInfo]: <img src={PermanentTaxHomeInfo} alt="Permanent Tax Home Information" />,
})(null);

const iconTypeV2 = customSwitch({
  [displayType.personalInfo]: <img src={PersonalInfoIconV2} alt="Personal Info" />,
  [displayType.emergency]: <img src={EmergencyContactIconV2} alt="Emergency Info" />,
  [displayType.address]: <img src={AddressIconV2} alt="Address" />,
  [displayType.contactInfo]: <img src={ContactInfoIconV2} alt="Contact Info" />,
  [displayType.applicationQuestion]: <img src={ApplicationsIconV2} alt="Application" />,
  [displayType.caseManagement]: <img src={CaseManagementV2} alt="caseManagement" />,
  [displayType.permanentTaxHomeInfo]: <img src={PermanentTaxHomeInfo} alt="Permanent Tax Home Information" />,
})(null);

const EmergencyContact = props => {
  const { classes } = useStyles();
  const emergencyContact = useSelector(selectEmergencyContact);
  const { t } = useTranslation();

  /**
   * Method to set phone number field with country code and extension notes
   */
  const setPhoneNumberHtml = useMemo(() => {
    const countryDialingCode = emergencyContact?.countryDialingCode
      ? `+${emergencyContact?.countryDialingCode}`
      : undefined;

    const contactPhone = emergencyContact?.phone ? emergencyContact?.phone : undefined;

    let contactInfo = '';
    countryDialingCode ? (contactInfo += countryDialingCode) : undefined;
    contactPhone ? (contactInfo += contactPhone) : undefined;

    const extensionNotes = emergencyContact?.extensionNotes ? emergencyContact?.extensionNotes : '';
    return contactInfo || extensionNotes ? `${contactInfo} ${extensionNotes}` : '';
  }, [emergencyContact?.phone, emergencyContact?.countryDialingCode, emergencyContact?.extensionNotes]);

  return (
    <>
      <LayoutGrid direction="column" justifyContent="space-between">
        <LayoutGridItem item>
          <Typography className={classes.adjustPaper}>{'Contact'}</Typography>
          {(emergencyContact?.name !== '' && emergencyContact?.name !== null) ||
          (emergencyContact?.relationship !== '' && emergencyContact?.relationship !== null) ||
          (emergencyContact?.phone !== '' && emergencyContact?.phone !== null) ? (
            <>
              <Typography className={classes.fontTitle}>{emergencyContact?.name ?? ''}</Typography>

              {emergencyContact?.relationship !== '' && emergencyContact?.relationship && (
                <>
                  <Typography>
                    {t('candidate.personalInfoTabLabels.relationship')} &nbsp;
                    {'"'}
                    <span className={classes.fontTitle}>{emergencyContact?.relationship}</span>
                    {'"'}
                  </Typography>
                </>
              )}

              <Typography className={classes.fontTitle}>{setPhoneNumberHtml}</Typography>
            </>
          ) : (
            <Typography> {'--'}</Typography>
          )}
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};

const PersonalInformation = props => {
  const { classes } = useStyles();
  const { candidateDetails } = props;
  const { t } = useTranslation();
  //const ethnicityId = useSelector(selectEthnicityId);
  //const raceEthnicityOptions = useSelector(ethnicityOptionsSelector);
  //const raceEthnicity = findRaceEthnicityItem(raceEthnicityOptions, ethnicityId);
  const [isHandleSSNClick, setIsHandleSSNClick] = useState<boolean>(false);
  const [isSSNViewableByUser, setIsSSNViewableByUser] = useState<boolean>(false);
  const [isSSNLastFourViewableByUser, setIsSSNLastFourViewableByUser] = useState<boolean>(false);
  const [isCalendarViewableByUser, setIsCalendarViewableByUser] = useState<boolean>(false);
  const [isGenderViewableByUser, setIsGenderViewableByUser] = useState<boolean>(false);
  const userInfo = useSelector(selectUser);
  const [viewAdpId] = useDecision(ff_candidate_ui_view_ADPID);
  const [manageNUID] = useDecision(ff_candidate_ui_manage_NUID);
  useEffect(() => {
    //Check for View Access
    setIsSSNViewableByUser(
      Authorized(
        [
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.recruitment_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_Director,
          userRoles.clinical_Manager,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.clinical_ReviewAnalyst,
          userRoles.payrollSpecialist,
          userRoles.housingAccountExecutive,
          userRoles.timeProcessingSpecialist,
          userRoles.workersCompCoordinator,
          userRoles.riskManagement,
          userRoles.complianceAuditSpecialist,
          userRoles.benefitsCoordinator,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
        ],
        userInfo.userInfo,
      ),
    );
    setIsCalendarViewableByUser(
      Authorized(
        [
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.recruitment_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_Director,
          userRoles.clinical_Manager,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.clinical_ReviewAnalyst,
          userRoles.payrollSpecialist,
          userRoles.housingAccountExecutive,
          userRoles.timeProcessingSpecialist,
          userRoles.workersCompCoordinator,
          userRoles.riskManagement,
          userRoles.complianceAuditSpecialist,
          userRoles.benefitsCoordinator,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
        ],
        userInfo.userInfo,
      ),
    );
    setIsSSNLastFourViewableByUser(
      Authorized(
        [
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.recruitment_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_Director,
          userRoles.clinical_Manager,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.clinical_ReviewAnalyst,
          userRoles.payrollSpecialist,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
        ],
        userInfo.userInfo,
      ),
    );
    setIsGenderViewableByUser(!Authorized([userRoles.clinical_ClientContractSpecialist], userInfo.userInfo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.userInfo]);

  return (
    <Grid container direction="row" justifyContent="space-between" xs={12}>
      <Grid item xs={5} className={classes.child}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <Box>{t('candidate.personalInfoCard.name')}</Box>
              </Grid>
              <Grid item whiteSpace="nowrap">
                <Box className={classes.subTitle}>
                  {candidateDetails.name
                    ? `${candidateDetails.name.first}${
                        candidateDetails.name.middle ? ` ${candidateDetails.name.middle}` : ''
                      } ${candidateDetails.name.last}`
                    : '-'}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <Box>{t('candidate.personalInfoCard.raceEthnicity')}</Box>
              </Grid>
              <Grid item>
                <Box className={classes.subTitle}>
                  {raceEthnicity ? (isViewableByUser ? raceEthnicity : '*****') : '--'}
                </Box>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item>
            <Grid container spacing={1} item direction="column">
              <Grid item>
                <Box>{t('candidate.personalInfoCard.dateOfBirth')}</Box>
              </Grid>
              <Grid item>
                <Box className={classes.subTitle}>
                  {candidateDetails.dateOfBirth ? (
                    isCalendarViewableByUser ? (
                      moment(new Date(candidateDetails.dateOfBirth)).format('MM/DD/YYYY')
                    ) : (
                      <CustomTooltip tooltipContent={t('candidate.personalInfoCard.protectedDataTooltip')}>
                        **/**/****
                      </CustomTooltip>
                    )
                  ) : (
                    '-'
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {viewAdpId.enabled && (
            <Grid item>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box>{t('candidate.personalInfoCard.adpId')}</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.subTitle}>{candidateDetails.adpId ? candidateDetails.adpId : '--'}</Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4} className={classes.child}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <Box>{t('candidate.personalInfoCard.goesBy')}</Box>
              </Grid>
              <Grid item>
                <Box className={classes.subTitle}>{candidateDetails.goesBy ? `"${candidateDetails.goesBy}"` : '-'}</Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="column">
              <Grid item>
                <Box>{t('candidate.personalInfoCard.socialSecurityNum')}</Box>
              </Grid>
              <Grid item>
                <ClickAwayListener onClickAway={e => setIsHandleSSNClick(false)}>
                  <Box className={classes.subTitle}>
                    {candidateDetails.socialSecurityNumber ? (
                      isSSNViewableByUser ? (
                        <Box onClick={e => setIsHandleSSNClick(true)}>
                          {isHandleSSNClick ? (
                            <span>{candidateDetails.socialSecurityNumber.replace(/-/g, ' ')}</span>
                          ) : (
                            <span>{`*** ** ${candidateDetails.socialSecurityNumber.toString().slice(-4)}`}</span>
                          )}
                        </Box>
                      ) : isSSNLastFourViewableByUser ? (
                        <span>{`*** ** ${candidateDetails.socialSecurityNumber.toString().slice(-4)}`}</span>
                      ) : (
                        <CustomTooltip tooltipContent={t('candidate.personalInfoCard.protectedDataTooltip')}>
                          <span>{'*** ** ****'}</span>
                        </CustomTooltip>
                      )
                    ) : (
                      '-'
                    )}
                  </Box>
                </ClickAwayListener>
              </Grid>
            </Grid>
          </Grid>
          {manageNUID.enabled && (
            <Grid item>
              <Grid container spacing={1} direction="column">
                <Grid item>
                  <Box>{t('candidate.personalInfoCard.nuid')}</Box>
                </Grid>
                <Grid item>
                  <Box className={classes.subTitle} mb="-24px">
                    {candidateDetails.nuid ? candidateDetails.nuid : '--'}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={3} className={classes.child}>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Box>{t('candidate.personalInfoCard.gender')}</Box>
          </Grid>
          <Grid item>
            <Box className={classes.subTitle}>
              {candidateDetails.gender ? (isGenderViewableByUser ? candidateDetails.gender : '*****') : '--'}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Address = props => {
  const { classes } = useStyles();
  const { candidateDetails } = props;
  const addressValue = candidateDetails?.addressData || [];
  const addresses = addressValue?.filter(x => x.travelerAddressId !== 0).map(address => {
    const updatedAddress = { ...address };
    for (const key in updatedAddress) {
      if (updatedAddress[key] === null && key === 'address1') {
        updatedAddress[key] = missingField;
      }
    }
    return updatedAddress;
  });
  const taxHome = addresses.filter(item => item.addressType === 'Tax Home');
  const permanent = addresses.filter(item => item.addressType === 'Permanent');
  const company = addresses.filter(item => item.addressType === 'Company Assignment');
  const companyHotel = addresses.filter(item => item.addressType === 'Company Assignment - Hotel');
  const other = addresses.filter(item => item.addressType === 'Other');
  const { t } = useTranslation();
  return (
    <>
      <LayoutGrid item container direction="column" justifyContent="space-between">
        <LayoutGridItem item>
          <Typography className={classes.adjustPaper}>{t('candidate.addressCard.taxHome')}</Typography>
          <AddressGroup addresses={taxHome} title={t('candidate.addressCard.taxHome')} />
        </LayoutGridItem>
        <Divider className={classes.divider}></Divider>
        <LayoutGridItem item>
          <Typography className={classes.adjustPaper}>{t('candidate.addressCard.permanent')}</Typography>
          <AddressGroup addresses={permanent} title={t('candidate.addressCard.permanent')} />
        </LayoutGridItem>
        <Divider className={classes.divider}></Divider>
        <LayoutGridItem item>
          <Typography className={classes.adjustPaper}>{t('candidate.addressCard.companyAssignment')}</Typography>
          <AddressGroup addresses={company} title={t('candidate.addressCard.companyAssignment')} />
        </LayoutGridItem>
        <Divider className={classes.divider}></Divider>
        <LayoutGridItem item>
          <Typography className={classes.adjustPaper}>{t('candidate.addressCard.companyAssignmentHotel')}</Typography>
          <AddressGroup addresses={companyHotel} title={t('candidate.addressCard.companyAssignmentHotel')} />
        </LayoutGridItem>
        <Divider className={classes.divider}></Divider>
        <LayoutGridItem item>
          <Typography className={classes.adjustPaper}>{t('candidate.addressCard.other')}</Typography>
          <AddressGroup addresses={other} title={t('candidate.addressCard.other')} />
        </LayoutGridItem>
      </LayoutGrid>
    </>
  );
};

const ContactInformation = props => {
  const { classes } = useStyles();
  const { candidateDetails } = props;
  const { t } = useTranslation();
  const isShowTheParmenent2 = candidateDetails.phoneNumbers.filter(
    x => (x.phoneTypeId === 6 || x.phoneTypeId === 1) && x.phoneNumber !== '',
  );
  return (
    <Grid container spacing={2} direction="column" style={{ flexWrap: 'initial' }}>
      <Grid item>
        <Box className={classes.fontTitle}>{t('candidate.contactInfoCard.phone')}</Box>
      </Grid>
      {candidateDetails.phoneNumbers?.length > 0 ? (
        candidateDetails.phoneNumbers.map(item => {
          if (item.phoneNumber) {
            return (
              <Grid item>
                <Grid container spacing={1} direction="row" alignItems="center" justifyContent="space-between" xs={12}>
                  <Grid item xs={10}>
                    <Grid container spacing={3} direction="row" alignItems="center" xs={12}>
                      {isShowTheParmenent2?.length !== 0 ? (
                        item.phoneTypeId !== 3 && (
                          <>
                            {' '}
                            <Grid item>
                              <Box className={classes.fontTitle}>{item.phoneNumber}</Box>
                            </Grid>
                            <Grid item>
                              <Box>{item.phoneTypeDescription}</Box>
                            </Grid>
                          </>
                        )
                      ) : (
                        <>
                          {' '}
                          <Grid item>
                            <Box className={classes.fontTitle}>{item.phoneNumber}</Box>
                          </Grid>
                          <Grid item className={classes.reasonStyle}>
                            <Box>{item.phoneTypeDescription}</Box>
                            {item.phoneTypeId === 3 && (
                              <CustomTooltip
                                tooltipContent={`Only being displayed from AMIE Classic.`}
                                tooltipPlacementBottom={classes.tooltipPlacementBottom}
                                isInlineContent={true}
                              >
                                <img src={ReasonInfo} className={classes.reasonInfoIcon} />
                              </CustomTooltip>
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={2}> for future reference i'm putting this
                    <Button
                      variant="text"
                      color="default"
                      className={classes.button}
                      startIcon={<PhoneIcon className={classes.phoneIcon} />}
                      href={'phoneIcon'}
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            );
          }
        })
      ) : (
        <Grid item>
          <Box>{'-'}</Box>
        </Grid>
      )}
      <Grid item>
        <Box className={classes.fontTitle}>{t('candidate.contactInfoCard.email')}</Box>
      </Grid>
      {candidateDetails.primaryEmail && (
        <Grid item>
          <Box className={classes.email}>{candidateDetails.primaryEmail}</Box>
        </Grid>
      )}
      {candidateDetails.secondaryEmail && (
        <Grid item>
          <Box className={classes.email}>{candidateDetails.secondaryEmail}</Box>
        </Grid>
      )}
      {!candidateDetails.primaryEmail && !candidateDetails.secondaryEmail && (
        <Grid item>
          <Box>{'-'}</Box>
        </Grid>
      )}
    </Grid>
  );
};

const CaseManagementCard = ({ classes, candidateId }: { classes; candidateId: number }) => {
  const caseManagementURL = globalThis?.app?.env.REACT_APP_CASE_MANAGEMENT;
  const { t } = useTranslation();
  return (
    <>
      {/* <Divider sx={{ width: '100%', marginBottom: '15px' }}></Divider> */}
      <a
        style={{ textDecoration: 'none' }}
        target="_blank"
        rel="noreferrer"
        href={`${caseManagementURL}/${candidateId}`}
      >
        <img src={OpenIcon} alt="Open" />
        <span className={classes.consentLink}>{t('candidate.personalInfoTabLabels.amieWebCaseViewer')}</span>
      </a>
    </>
  );
};

const defaultValues = {
  pWithhold: null,
  pEffectiveDate: null,
};

export const CardBuilder = props => {
  const candidateDetails = useSelector(candidateDetailsSelection);
  const user = useSelector(selectUser);
  const [communicationLink] = useDecision(ff_contactInformationVeryCommunicationLink);
  const { title, disType } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [candidateContainerUIV2] = useDecision(ff_candidate_container_ui_v2);
  const [addPermanentTaxData, setAddPermanentTaxData] = useState<boolean>(false);
  const [isPayrollSpecialist, setIsPayrollSpecialist] = useState(false);

  const { promiseInProgress: candidateDetailsPromisInProgress } = usePromiseTracker({
    area: 'get-candidate-details',
    delay: 0,
  });
  const { promiseInProgress: personalInfoPromiseInProgress } = usePromiseTracker({
    area: 'get-candidate-personal-info-tab-details',
    delay: 0,
  });

  const handleAdd = () => {
    setAddPermanentTaxData(true);
  };

  useEffect(() => {
    setIsPayrollSpecialist(Authorized([userRoles.payrollSpecialist], user.userInfo));
  }, [user.userInfo]);

  const cardBuilderUIV2 = (
    <LayoutGridItem style={{ width: '100%' }} pb="12px">
      <CandidateContainer
        title={title}
        icon={iconTypeV2(disType)}
        rightIcon={displayType.permanentTaxHomeInfo === disType && isPayrollSpecialist && <AddIcon />}
        onClick={handleAdd}
        iconToolTip={t('candidate.taxInfoCard.addPermanentTaxHome')}
      >
        {candidateDetailsPromisInProgress || personalInfoPromiseInProgress ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <LayoutGrid container spacing={2} direction="column">
            <LayoutGridItem item style={{ paddingTop: '15px' }}>
              {displayType.caseManagement === disType ? (
                <CaseManagementCard classes={classes} candidateId={candidateDetails.travelerId} />
              ) : communicationLink.enabled ? (
                displayType.contactInfo === disType ? (
                  <>
                    <LayoutGridItem style={{ paddingBottom: '12px', paddingRight: '12px' }}>
                      <a
                        className={
                          candidateDetails.consentLink ? classes.consentContainer : classes.consentContainerDisabled
                        }
                        href={candidateDetails.consentLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={OpenIcon} alt="Open" />
                        <span className={classes.consentLink}>{`Verify Communication Consent`}</span>
                        <Divider sx={{ mt: '12px' }}></Divider>
                      </a>
                    </LayoutGridItem>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}

              <LayoutGridItem item sx={{ width: '100%' }}>
                {displayType.emergency === disType && <EmergencyContact />}
                {displayType.address === disType && <Address candidateDetails={candidateDetails} />}
                {displayType.contactInfo === disType && <ContactInformation candidateDetails={candidateDetails} />}
                {displayType.personalInfo === disType && <PersonalInformation candidateDetails={candidateDetails} />}
                {displayType.applicationQuestion === disType && <ApplicationQuestionsCard />}
                {displayType.permanentTaxHomeInfo === disType && <PermanentTaxHomeCard />}
              </LayoutGridItem>
            </LayoutGridItem>
          </LayoutGrid>
        )}
      </CandidateContainer>
    </LayoutGridItem>
  );

  const cardBuilderUI = (
    <PaperCard className={classes.adjustPaper}>
      {candidateDetailsPromisInProgress || personalInfoPromiseInProgress ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <LayoutGrid container spacing={2} direction="column">
          <LayoutGridItem item style={{ paddingTop: '15px' }}>
            <LayoutGrid container spacing={2} direction="row" alignItems="center" justifyContent="space-between">
              <LayoutGridItem>
                <LayoutGrid container spacing={2} direction="row" alignItems="center">
                  <LayoutGridItem item>{iconType(disType)}</LayoutGridItem>
                  <LayoutGridItem item>
                    <Box className={classes.title}>{title}</Box>
                  </LayoutGridItem>
                </LayoutGrid>
              </LayoutGridItem>
              <LayoutGridItem item>
                {displayType.permanentTaxHomeInfo === disType && isPayrollSpecialist && (
                  <CustomTooltip
                    style={{ color: '#888888' }}
                    tooltipContent={t('candidate.taxInfoCard.addPermanentTaxHome')}
                    placement="bottom-start"
                    isInlineContent={true}
                  >
                    <div className={classes.addIcon}>
                      <AddIcon onClick={handleAdd} id="add-icon-permanentTaxHome" color="" />
                    </div>
                  </CustomTooltip>
                )}
              </LayoutGridItem>
            </LayoutGrid>
          </LayoutGridItem>
          <LayoutGridItem item style={{ padding: '15px 0px 3px 10px' }}>
            {displayType.caseManagement === disType ? (
              <CaseManagementCard classes={classes} candidateId={candidateDetails.travelerId} />
            ) : communicationLink.enabled ? (
              displayType.contactInfo === disType ? (
                <a
                  className={candidateDetails.consentLink ? classes.consentContainer : classes.consentContainerDisabled}
                  href={candidateDetails.consentLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={OpenIcon} alt="Open" />
                  <span className={classes.consentLink}>{`Verify Communication Consent`}</span>
                </a>
              ) : (
                <Divider className={classes.divider}></Divider>
              )
            ) : (
              <Divider className={classes.divider}></Divider>
            )}
          </LayoutGridItem>
          <LayoutGridItem item sx={{ width: '100%' }}>
            {displayType.emergency === disType && <EmergencyContact />}
            {displayType.address === disType && <Address candidateDetails={candidateDetails} />}
            {displayType.contactInfo === disType && <ContactInformation candidateDetails={candidateDetails} />}
            {displayType.personalInfo === disType && <PersonalInformation candidateDetails={candidateDetails} />}
            {displayType.applicationQuestion === disType && <ApplicationQuestionsCard />}
            {displayType.permanentTaxHomeInfo === disType && <PermanentTaxHomeCard />}
          </LayoutGridItem>
        </LayoutGrid>
      )}
    </PaperCard>
  );

  return (
    <>
      {candidateContainerUIV2?.enabled ? cardBuilderUIV2 : cardBuilderUI}
      {addPermanentTaxData && (
        <PermanentTaxFormDialogue setOpenForm={setAddPermanentTaxData} isModalOpen={addPermanentTaxData} />
      )}
    </>
  );
};
