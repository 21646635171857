import { ContactProps, IEmployee } from 'app/models/Notification/Notification';
import { customSwitch } from './../../../utils/customSwitchCase/customSwitch';
import { IAssociatedRecord } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/OrderMatchTab/AlertStore/OrderMatchAlert.redux';
export enum ChannelType {
  email = 'email',
  alert = 'alert',
  sms = 'sms',
  voice = 'voice',
  web = 'web',
  inApp = 'inApp',
  inbox = 'inbox',
}

export enum UserRoles {
  recruitment = 'Recruitment',
  accountManager = 'Account Management',
  credentialing = 'Credentialing',
}

export enum Dated {
  today = 'd',
  week = 'w',
  month = 'm',
  year = 'y',
}

export enum ContactType {
  primary,
  secondary,
}

export enum SendType {
  bulk = 'bulk',
  one_to_one = 'one_to_one',
  automated = 'automated',
}

export enum FormName {
  Traveler = 1,
  Placement = 2,
  Contact = 3,
  Reference = 4,
}

export enum CallPurpose {
  Prospecting = 'prospecting',
  TouchPoint = 'touchpoint',
  CustomerService = 'customerService',
}

export enum IDirection {
  Outbound = 'Outbound',
  Inbound = 'Inbound',
}

export enum UseType {
  Credentialing = 'credentialing',
  General = 'general',
  HousingAndTravel = 'housingAndTravel',
  HRPayroll = 'hrpayroll',
  JobMatchAlert = 'JobMatchAlert',
  Placement = 'placement',
  Candidate = 'candidate',
  Recruitment = 'recruitment',
  ClientContacts = 'clientImpact',
  Bulk = 'bulk',
}
export enum ICallOutcome {
  PhoneDisconnected = 'phoneDisconnected',
  CouldntConnect = 'couldnotConnect',
  LeftMessage = 'leftMessage',
  SpokeToCandidate = 'spokeToCandidate',
  SpokeToClient = 'spokeToClient',
  NoAction = 'noAction',
}

export enum UseSubType {
  Page = 'page',
  Header = 'header',
  SidePanel = 'sidepanel',
  WorkDesk = 'wordesk',
  ClientContactGrid = 'clientContactsGrid',
  Automation = 'automation',
  ConfirmationLetter = 'confirmation-letter',
  PacketSubmission = 'packet-submission',
  CandidateSearch = 'candidate-search',
  PlacementRfoNotification = 'placement-rfo-notification',
  KeyContactBulkEmail = 'key-contact-bulk-email',
}
interface ITos {
  contactId: number;
  designation: any;
  email: string;
  phoneNumber: any;
  name: string;
}
export interface IVoiceData {
  manualLog: boolean;
  channel: ChannelType;
  associatedRecords: IAssociatedRecord[];
  sender: IEmployee;
  sendType: SendType;
  tos: ITos[];
  useType: UseType;
  userRole: string;
  isInternal: boolean;
  formName?: any;
  UseSubType?: UseSubType;
}

export interface IEmailData {
  associatedRecords: { name: string; value: any }[];
  sender: IEmployee;
  sendType?: SendType;
  emailType: SendType;
  body?: string;
  subject?: string;
  tos: ContactProps[];
  useType: UseType;
  userRole: string;
  isInternal?: boolean;
  substitutions?: any;
  useSubType?: UseSubType;
  disableGroupEmailCheckbox?: boolean;
}

export enum HubEventType {
  InboundSms = 'InboundSms',
  OutboundSmsDelivery = 'OutboundSmsDelivery',
  OutboundSmsProcessError = 'OutboundSmsProcessError',
  TaskNotification = 'Task',
  EmailDelivered = 'delivered',
  EmailBounced = 'bounce',
  EmailDropped = 'dropped',
  EmailBlocked = 'blocked',
  EmailUnsubscribed = 'unsubscribe',
  EmailSpam = 'spamreport',
  EmailProcessed = 'processed',
}

export enum UserPreferenceTag {
  CandidateSearchGridFilter = 'CandidateSearchGridFilter',
  PlacementSearchGridFilter = 'PlacementSearchGridFilter',
}
export enum NotificationHeaderOptions {
  Preferences = 'Preferences',
  Task = 'Task',
  Alert = 'Alert',
}
export const PAGE_SIZE = 20;
export const MISSING_FIELD = '—';
export const COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT = 500;

export const getBrandUrl = customSwitch({
  amn: 'https://www.amnhealthcare.com/',
  amh: 'https://www.americanmobile.com/',
  clubstaffing: 'https://www.clubstaffing.com/',
  hsg: 'https://www.healthsourceglobal.com/',
  medtravelers: ' https://www.medtravelers.com/',
  nursechoice: 'https://www.nursechoice.com/',
  onward: 'https://www.onwardhealthcare.com/',
})(undefined);
