import { PlacementInfo } from 'app/models/Candidate/CandidateProfile';

// Editing Name & DOB is restricted for the following placement status
const blacklist = new Set([
  'Booked',
  'Booking Approved',
  'Waiting Good To Go',
  'Postponement Warning',
  'Canceled by Traveler',
  'Canceled by Facility',
  'Canceled by AMH',
  'Canceled by Settlement',
  'Good To Go',
  'On Assignment',
  'Assignment Complete',
  'Canceled by Traveler',
  'Canceled by Facility',
  'Canceled by AMH',
  'Canceled by Settle',
]);

// Check if current or any past placement status is in the blacklist
export const IsNameDobEditable = (currentPlacementStatus: string, historicalPlacements: PlacementInfo[]) => {
  if (blacklist.has(currentPlacementStatus)) return false;
  if (historicalPlacements.length === 0) return true;
  const isAnyStatusInRestrictedList = historicalPlacements?.some(placement => {
    return blacklist.has(placement?.placementStatus) === true;
  });
  return !isAnyStatusInRestrictedList;
};
