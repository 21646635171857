import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { Cancel } from '@AMIEWEB/Common/CancelModal/Cancel';
import { Undo } from '@AMIEWEB/Common/UndoModal/Undo';
import { useSelectedUnitDetails } from '@AMIEWEB/Unit/UnitDetails/Containers/unitHelper';
import { Box, Tab, Tabs, TextField, Typography } from 'amn-ui-core';
import { isEqual } from 'lodash';
import { selectUser } from 'oidc/user.selectors';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FacilityUnitActions } from '../UnitTab/Store/FacilityUnit.redux';
import { useStyles } from './styles';
import { IPostUnitDetailViewPageFields } from './types';
import { formatFreeText } from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/UnitDetailDataTransformer';

const UnitDetailsEditQualificationModal = ({ isModalOpen, setIsModalOpen, isEditable }) => {
  // #region State
  const [selectedTab, setSelectedTab] = useState<string>('requiredQualifications');
  const [expanded, setExpanded] = useState<boolean>(false);
  const selectedUnitDetail = useSelectedUnitDetails();
  const savedQualificationDetails = {
    requiredQualifications: selectedUnitDetail?.requiredQualifications || '',
    additionalRequiredQualifications: selectedUnitDetail?.additionalRequiredQualifications || '',
    preferredQualifications: selectedUnitDetail?.preferredQualifications || '',
    internalNotes: selectedUnitDetail?.internalNotes || '',
    externalJobDescription: selectedUnitDetail?.externalJobDescription || '',
  };
  const [qualificationTexts, setQualificationTexts] = useState(savedQualificationDetails);
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);
  const [isUndoDialogOpen, setIsUndoDialogOpen] = useState(false);
  // #endregion

  // #region Hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const { classes } = useStyles({ expanded });
  const isFormUpdated = !isEqual(savedQualificationDetails, qualificationTexts);
  // #endregion

  // #region Functions
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    document.getElementById(newValue) && document.getElementById(newValue).scrollIntoView({ behavior: 'smooth' });
    setSelectedTab(newValue);
  };

  const handleInputTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    let newValue = e.target.value;
    newValue = newValue?.length > 8000 ? newValue?.slice(0, 8000) : newValue;
    setQualificationTexts({ ...qualificationTexts, [field]: newValue });
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>, field: string) => {
    e.preventDefault();
    let copiedText = e.clipboardData.getData('text');
    const currentValue = qualificationTexts[field] || '';
    let newValue = currentValue + copiedText;
    newValue = newValue.length > 8000 ? newValue?.slice(0, 8000) : newValue;
    setQualificationTexts({ ...qualificationTexts, [field]: newValue });
  };

  const handleSave = () => {
    const unitId = selectedUnitDetail?.unitId;
    const facilityId = selectedUnitDetail?.facilityId;
    const currentEmployeeId = user?.userInfo?.employeeId;
    const currentEmployeeName = (user?.userInfo?.firstName + ' ' + user?.userInfo?.lastName).trim();
    const payload: IPostUnitDetailViewPageFields = {
      ...qualificationTexts,
      requiredQualifications:
        qualificationTexts?.requiredQualifications?.length === 0 ? null : qualificationTexts?.requiredQualifications,
      preferredQualifications: formatFreeText(qualificationTexts.preferredQualifications),
      internalNotes: formatFreeText(qualificationTexts.internalNotes),
      externalJobDescription: formatFreeText(qualificationTexts.externalJobDescription),
      unitId,
      facilityId,
      currentEmployeeId,
      currentEmployeeName,
    };
    dispatch(FacilityUnitActions.requestUpdateUnitViewPageFields(payload));
    setIsModalOpen(false);
  };

  const handleClose = () => {
    isFormUpdated ? setIsCancelDialogOpen(true) : setIsModalOpen(false);
  };
  // #endregion

  // #region Helpers
  const tabs = [
    { id: 'requiredQualifications', label: t('facility.units.jobDetails.requiredQualification'), readOnly: true },
    {
      id: 'additionalRequiredQualifications',
      label: t('facility.units.jobDetails.additionalRequiredQualifications'),
      readOnly: false,
    },
    { id: 'preferredQualifications', label: t('facility.units.jobDetails.preferredQualification'), readOnly: false },
    { id: 'internalNotes', label: t('facility.units.internalJobNotes.viewPageTitle'), readOnly: false },
    { id: 'externalJobDescription', label: t('facility.units.jobDetails.externalJobDescription'), readOnly: false },
  ];

  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const HelperTextCustom = ({ value }: { value: string }) => (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span
        style={{
          visibility: value?.length >= 8000 ? 'visible' : 'hidden',
        }}
      >
        {t('order.maxCharLimit')}
      </span>
      <span>{`${value?.length || 0}/8000`}</span>
    </div>
  );

  const cancelDialog = (
    <Cancel
      openDialog={isCancelDialogOpen}
      handleCancelAction={() => setIsCancelDialogOpen(false)}
      handleConfirmAction={() => {
        setIsModalOpen(false);
        setIsCancelDialogOpen(false);
      }}
    />
  );

  const undoDialog = (
    <Undo
      openDialog={isUndoDialogOpen}
      handleConfirmAction={() => {
        setQualificationTexts(savedQualificationDetails);
        setIsUndoDialogOpen(false);
      }}
      handleCancelAction={() => {
        setIsUndoDialogOpen(false);
      }}
    />
  );
  // #endregion

  return (
    <>
      <GenericDialog
        open={isModalOpen}
        onClose={handleClose}
        draggable
        variant="blue"
        className={classes.dialog}
        dialogTitleProps={{
          text: t('facility.units.jobDetails.title'),
          closeButton: true,
          expandable: true,
          onExpandChange: () => setExpanded(!expanded),
          undoButton: true,
          onUndoClick: () => setIsUndoDialogOpen(true),
          disableUndo: !isFormUpdated,
        }}
        dialogActions={
          isEditable
            ? [
                {
                  text: t('order.facility.creditHoldDialog.cancelBtn'),
                  variant: 'contained',
                  color: 'tertiary',
                  onClick: handleClose,
                },
                {
                  text: t('candidate.skillsets.save'),
                  variant: 'contained',
                  color: 'primary',
                  onClick: handleSave,
                },
              ]
            : []
        }
        dialogContentProps={{
          classes: {
            root: classes.dialogueContent,
          },
        }}
      >
        <div className={classes.container}>
          {/* Tabs Component */}
          <Box className={expanded ? classes.filterTabsExpanded : classes.filterTabs}>
            <Box style={{ marginTop: '20px' }}>
              <Tabs
                value={selectedTab}
                classes={{ indicator: classes.indicator }}
                textColor="primary"
                orientation="vertical"
                onChange={handleTabChange}
                aria-label="edit qualifications selector"
              >
                {tabs.map((tab, index) => (
                  <Tab key={tab.id} label={tab.label} className={classes.tab} value={tab.id} {...a11yProps(index)} />
                ))}
              </Tabs>
            </Box>
          </Box>
          {/* Form Component */}
          <Box className={expanded ? classes.content : classes.fullScreenContainer}>
            <Box style={{ width: !expanded ? '100%' : '600px' }} className={classes.form}>
              <Box>
                <div style={{ display: 'flex', flexDirection: 'column', gap: isEditable ? '8px' : '12px' }}>
                  {tabs.map(tab => (
                    <Box key={tab.id} id={tab.id}>
                      <Typography sx={{ marginBottom: isEditable ? '12px' : '6px' }} variant="h6">
                        {tab.label}
                      </Typography>
                      {!isEditable || tab.readOnly ? (
                        <Typography
                          sx={{
                            marginBottom: tab.id === 'requiredQualifications' && isEditable ? '12px' : '0px',
                            whiteSpace: tab.id === 'requiredQualifications' ? 'pre-line' : 'normal',
                          }}
                        >
                          {qualificationTexts[tab.id] || '--'}
                        </Typography>
                      ) : (
                        <TextField
                          className={classes.notes}
                          variant="filled"
                          multiline
                          value={qualificationTexts[tab.id]}
                          onChange={e => handleInputTextChange(e, tab.id)}
                          onPaste={e => handlePaste(e, tab.id)}
                          error={qualificationTexts[tab.id].length >= 8000}
                          helperText={<HelperTextCustom value={qualificationTexts[tab.id]} />}
                        />
                      )}
                    </Box>
                  ))}
                </div>
              </Box>
            </Box>
          </Box>
        </div>
      </GenericDialog>
      {isCancelDialogOpen && cancelDialog}
      {isUndoDialogOpen && undoDialog}
    </>
  );
};

export default UnitDetailsEditQualificationModal;
