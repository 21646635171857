import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import {
  AppBar,
  Avatar,
  Backdrop,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Modal,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';
import { JabberExtensionCode, unformatPhoneNumber } from 'app/helpers/phoneHelpers';
import { UserDeviceProps } from 'app/models/Notification/Notification';
import { selectUser } from 'oidc/user.selectors';
import { userDevicePreferenceActions } from 'oidc/UserDevicePreference/userPreference.redux';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import React, { useContext, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Zoom } from 'react-toastify';
import styledComponent from 'styled-components';
import { ChangeBanner } from '../../Common/ChangeBanner';
import { CloseConfirmation } from '../../Common/CloseDialog';
import { nameInitial } from '../../Common/StringHelper';
import { ToasterContent } from '../../Common/ToasterContent';
import { ToasterHeader } from '../../Common/ToasterHeader';
import { COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT, ChannelType, IDirection, UseType } from '../../Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import {
  notificationSelection,
  selectVoiceAction,
  selectVoiceDisposition,
  selectVoiceLoader,
} from 'store/redux-store/notification/notification.selector';
import { NotificationContext } from '../../NotificationWrapper';
import { OutcomeIcon } from './OutcomeGridIcons';
import UserDeviceSelection from './UserDeviceSelection';
import PersonIcon from '@mui/icons-material/Person';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { formatVoiceData } from './Helper';

const useStyles = makeStyles()(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltipBackground: {
    backgroundColor: '#fff',
    color: '#555555',
    border: '1px solid #555555',
    fontSize: '12px',
  },
  modalHeaderSubtitle: {
    textOverflow: 'ellipsis',
    color: '#333',
    fontSize: '1.1rem',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.5rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1rem',
    },
    letterSpacing: '0px',
    font: 'normal normal medium 14px/16px Roboto',
    opacity: '1',
  },
  deviceSelectionHeader: {
    color: '#393939',
    fontSize: '1.5rem',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.75rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
  },
  purposeButton: {
    position: 'relative',
    float: 'right',
    marginRight: '5%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    overflowY: 'hidden',
    overflowX: 'hidden',
    width: '33vw',
    outline: 'none',
    paddingBottom: '1%',
    boxShadow: '0px 0px 2px 6px #8888',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    borderRadius: '6px',
  },
  actionButton: {
    margin: '2% 0% 0% 0%',
    float: 'right',
    color: '#fff',
  },
  cancelButton: { backgroundColor: '#8e9195' },
  box: {
    border: '1px solid #B9BBBC',
    borderRadius: '10px',
    width: '6.5vw',
    height: '12vh',
    backgroundColor: '#FFFFFF',
    margin: '1',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  root: {
    margin: '2% 0% 0% 0%',
    float: 'right',
    backgroundColor: 'grey',
    fontSize: '14px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'grey',
      margin: '2% 0% 0% 0%',
      fontSize: '14px',
    },
  },
  gridIcon: {
    display: 'flex',
    margin: '0.5%',
  },
  gridText: {
    display: 'flex',
    margin: '8% 6% 0 6%',
    textAlign: 'center',
    fontSize: '0.75rem',
    fontWeight: 500,
    font: 'normal normal medium Roboto',
    letterSpacing: '0px',
    opacity: '1',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    boxSizing: 'border-box',
  },
  notes: {
    minHeight: '5vh',
  },
  avatar: {
    margin: '2% 3% 2% 0',

    color: '#FFFFFF',
    fontSize: '30px',
    [theme.breakpoints.down('lg')]: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(10),
      width: theme.spacing(10),
    },
  },
  modalIcon: {
    color: '#BDBEBF',
    cursor: 'pointer',
    fontSize: '2rem',
    margin: '5px',
  },
  modalContent: {
    margin: '2% 5%',
    color: '#393939',
  },
  invertItemColor: {
    filter: `invert(31%) sepia(70%) saturate(1672%) hue-rotate(181deg) brightness(80%) contrast(80%)`,
  },
  errorText: {
    fontSize: '12px',
    justifyContent: 'center',
    color: theme.palette.system.errorRed,
  },
  characterCount: {
    fontSize: '12px',
    justifyContent: 'center',
    color: '#888888',
  },
  errorInfoIcon: {
    width: '16px',
    height: '16px',
  },
}));

const ModalAppBar = styledComponent(AppBar)`
  width: 100%;
  height: 10vh;
  padding-top: 1%;
  padding-left: 34px;
  background: #f4f5f7;
  cursor: all-scroll;
  box-shadow: none;
`;
const Notes = styledComponent(TextField)`
  margin-top: 1%;
  width: 100%;
`;

const FieldHeader = styledComponent(Typography)`
  margin-bottom: 1%;
  margin-top: 2%;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
  font: normal normal medium 16px/26px Roboto;
  letter-spacing: 0px;
  color: #393939;
  opacity: 1;
`;

const Header = styledComponent(Typography)`
  text-align: left;
  font: normal normal medium Roboto;
  letter-spacing: 0px;
  color: #34b233;
  opacity: 1;

  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 1.5%;
`;
const SubHeader = styledComponent(Typography)`
  color: #333333;
  font-weight: 600;
  font-size: 1rem;
  opacity: 1;
  text-align: left;
  font: normal normal medium 14px/16px Roboto;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  @media (max-width: 1650px) {
    font-size: 0.7rem;
  }
`;

const DeviceHeader = styledComponent(Typography)`
  color: #393939;
  opacity: 1;
  text-align: left;
  font: normal normal medium 16px/28px Roboto;
  letter-spacing: 0px;
  opacity: 1;
`;

const modalDeviceSelectionTitle = 'Select Device and Line';

export const Voice = () => {
  const dispatch = useDispatch();
  const globalData = useSelector(notificationSelection);
  const theme = useTheme();

  const { onClose, handleSubmitResponse } = useContext(NotificationContext);
  const data = globalData?.voice?.data;
  const { t } = useTranslation();
  const userDevice = useSelector(selectUserPreference);
  const user = useSelector(selectUser);
  const { classes } = useStyles();
  const voiceAction = useSelector(selectVoiceAction);
  const voiceDisposition = useSelector(selectVoiceDisposition);
  const loader = useSelector(selectVoiceLoader);
  const userDevicePreference = useSelector(selectUserPreference);

  const [notes, setNotes] = useState<string>('');
  const [userDeviceLine, setUserDeviceLine] = useState<UserDeviceProps>({
    selectedDevice: userDevicePreference?.existing?.device || '',
    deviceList: [],
    selectedLine: userDevicePreference?.existing?.line || '',
    deviceLine: [],
    isDefaultDeviceAndLine: false,
  });

  useEffect(() => {
    const voiceData = JSON.parse(JSON.stringify(data));
    dispatch(notificationDataActions.setVoiceLoader(true));
    dispatch(
      notificationDataActions.setVoiceDisposition({
        ...data,
        CTIActivityHistoryId: '',
        id: '',
        date: new Date(),
        time: new Date(),
        direction: IDirection.Outbound,
        user: '',
        phoneNumber: '',
        sender: data?.sender,
        notes: data?.notes ?? '',
        disposition: data?.disposition ?? null,
        initiatedOn: new Date(),
      }),
    );
    if (globalData.voice?.showDisposition) {
      dispatch(notificationDataActions.setVoiceAction({ ...voiceAction, showDisposition: true }));
      dispatch(notificationDataActions.setVoiceLoader(false));
    } else if (
      userDevicePreference?.existing?.device !== null &&
      userDevicePreference?.existing?.device !== '' &&
      userDevicePreference?.existing?.line !== null &&
      userDevicePreference?.existing?.line !== ''
    ) {
      dispatch(notificationDataActions.setVoiceLoader(false));
      HandleInitiateCall();
      dispatch(
        notificationDataActions.setVoiceAction({
          ...voiceAction,
          openDeviceSelection: false,
          showDisposition: !voiceData?.isInternal,
        }),
      );
      dispatch(
        notificationDataActions.setVoiceInteraction({
          open: true,
          minimized: false,
          showDisposition: !voiceData?.isInternal,
        }),
      );
    } else {
      dispatch(notificationDataActions.setVoiceLoader(false));
      setUserDeviceLine(prevState => ({
        ...prevState,
        deviceList: userDevicePreference.deviceList,
        deviceLine: userDevicePreference.deviceLine,
        isDefaultDeviceAndLine: false,
      }));
      dispatch(notificationDataActions.setVoiceAction({ ...voiceAction, openDeviceSelection: true }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const HandleInitiateCall = () => {
    if (userDeviceLine.isDefaultDeviceAndLine) {
      const userPreference = {
        ...userDevice,
        current: {
          device: userDevicePreference.current?.device,
          line: userDevicePreference.current?.line,
        },
        existing: {
          device: userDevicePreference.current?.device,
          line: userDevicePreference.current?.line,
        },
        default: true,
      };
      dispatch(userDevicePreferenceActions.setUserPreference(userPreference));
      const preferredUser = {
        userId: user?.userInfo?.employeeId?.toString(),
        deviceName: userDevice?.current?.device,
        lineNumber: userDevice?.current?.line,
      };
      const preferenceData = {
        id: 'DevicePreference',
        value: preferredUser,
      };
      dispatch(userDevicePreferenceActions.saveUserDevicePreferenceAction(preferenceData));
      // saveUserDevicePreference(preferredUser)
      handleSubmitResponse(true, 'success', t('notification.voice.updatedPreferenceSuccess'));
    }
    const voiceData = JSON.parse(JSON.stringify(data));
    if (data && data?.tos.find(Boolean)?.phoneNumber) {
      voiceData.tos.find(Boolean).phoneNumber =
        JabberExtensionCode + unformatPhoneNumber(voiceData?.tos.find(Boolean)?.phoneNumber);
    }
    voiceData.sender.deviceName = userDevicePreference?.current?.device;
    voiceData.sender.lineNumber = userDevicePreference?.current?.line;

    dispatch(notificationDataActions.initiateCall({ data: voiceData}));
  };

  const saveManualCallLog = () => {
    const voiceData = JSON.parse(JSON.stringify(voiceDisposition));
    if (data && data?.tos.find(Boolean)?.phoneNumber) {
      voiceData.tos.find(Boolean).phoneNumber =
        JabberExtensionCode + unformatPhoneNumber(voiceData?.tos.find(Boolean)?.phoneNumber);
    }
    voiceData.initiatedOn = voiceData.date.substring(0, 11) + voiceData.time.substring(11, voiceData.time?.length);
    const voiceDataRefactored = { ...voiceData };

    if (!data?.tos.find(Boolean)?.contactId) {
      voiceDataRefactored.associatedRecords[0].value = null;
      voiceDataRefactored.associatedRecords[1].value = null;
    }

    dispatch(notificationDataActions.saveCallLog({ callLog: formatVoiceData(voiceDataRefactored), isManualLog: true }));
  };

  const handleDisposition = (value, key) => {
    const dispositionData = JSON.parse(JSON.stringify(voiceDisposition));
    dispositionData[key] = value;
    dispatch(notificationDataActions.setVoiceDisposition({ ...voiceDisposition, [key]: value }));
    dispatch(notificationDataActions.setVoiceData({ data: dispositionData, open: true, showDisposition: true }));
  };

  const handleModalClose = () => {
    const closeModalData = {
      voice: {
        data: null,
        open: false,
        minimized: false,
        showDisposition: false,
      },
    };
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        ...closeModalData,
      }),
    );
    dispatch(notificationDataActions.setSnackBarData(null));
  };

  const handleMinimize = () => {
    const toasterId = data?.channel;
    dispatch(notificationDataActions.setVoiceAction({ ...voiceAction, minimize: true, showDisposition: false }));
    dispatch(notificationDataActions.setVoiceInteraction({ open: false, minimized: true, showDisposition: false }));
    dispatch(notificationDataActions.incrementActiveToaster());
    dispatch(notificationDataActions.setSnackBarData(null));
    toast(
      <ToasterContent
        channel={ChannelType.voice}
        id={toasterId}
        info={globalData?.voice?.data}
        header={
          data?.tos.find(Boolean)?.contactId
            ? ToasterHeader(data?.tos.find(Boolean)?.name, data?.tos.find(Boolean)?.contactId)
            : `${data?.tos.find(Boolean)?.phoneNumber}`
        }
        subheader={
          data.useType.toLowerCase() === 'clientimpact'
            ? getFacilitySubHeader(8)
            : `${data?.tos.find(Boolean)?.phoneNumber} | ${t('CID ')} ${data?.tos.find(Boolean)?.contactId}`
        }
      />,
      {
        toastId: toasterId,
        containerId: 'channel',
        position: 'bottom-right',
        closeButton: false,
        hideProgressBar: true,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        transition: Zoom,
      },
    );
  };

  const Outcome = props => {
    return (
      <Box
        boxShadow={3}
        sx={
          props.value === voiceDisposition?.disposition
            ? { border: `${theme.palette.framework.system.navyBlue} 2px solid` }
            : { border: `${theme.palette.framework.system.lightGray2} 1px solid` }
        }
        m={1}
        className={classes.box}
        onClick={event => {
          handleDisposition(props?.value, 'disposition');
        }}
      >
        <Grid>
          <Grid
            item
            justifyContent="center"
            sx={{ color: props.value === voiceDisposition?.disposition ? theme.palette.framework.system.navyBlue : theme.palette.framework.system.coolGray }}
            className={`${classes.gridIcon} ${
              props.value === voiceDisposition?.disposition ? classes.invertItemColor : ''
            }`}
          >
            {props.icon}
          </Grid>
          <Grid
            item
            justifyContent="center"
            style={{
              color: props.value === voiceDisposition?.disposition ? theme.palette.framework.system.navyBlue : theme.palette.framework.system.coolGray,
              fontSize: '0.75rem',
            }}
            className={classes.gridText}
          >
            <span>{props.name}</span>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const DeviceSelection = () => {
    return (
      <Modal open={true} className={classes.modal} closeAfterTransition hideBackdrop>
        <div className={classes.paper}>
          {globalData?.snackbarData?.showCloseAction && globalData?.snackbarData?.showResponse && (
            <Backdrop className={classes.backdrop} open />
          )}
          {loader && <CustomBackdrop open />}
          <Grid container spacing={3} style={{ padding: '6% 6% 0 6%' }}>
            <Grid item xs={11}>
              <DeviceHeader className={classes.deviceSelectionHeader}>{modalDeviceSelectionTitle}</DeviceHeader>
            </Grid>
            <Grid item xs={1}>
              <CloseIcon onClick={() => onClose(ChannelType.voice)} className={classes.modalIcon} />
            </Grid>
          </Grid>

          <Box className={classes.modalContent}>
            <Grid>
              <UserDeviceSelection />
            </Grid>
            <FormControl>
              <FormControlLabel
                disabled={
                  userDevicePreference.current?.line === undefined ||
                  userDevicePreference?.current?.device === undefined
                }
                control={
                  <Checkbox
                    checked={userDeviceLine.isDefaultDeviceAndLine}
                    onChange={event => {
                      setUserDeviceLine(prevState => ({ ...prevState, isDefaultDeviceAndLine: event.target.checked }));
                    }}
                  />
                }
                label={t('Set as Default')}
              />
            </FormControl>
          </Box>
          <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
            <Grid item>
              {globalData?.snackbarData?.channel === ChannelType.voice && !globalData?.snackbarData?.manual && (
                <CloseConfirmation
                  yesAction={handleModalClose}
                  noAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
                />
              )}
            </Grid>
          </Grid>
          <Button
            variant="contained"
            disabled={!userDevicePreference.current?.line || !userDevicePreference.current?.device}
            color="primary"
            className={classes.purposeButton}
            onClick={HandleInitiateCall}
          >
            {`OK`}
          </Button>
        </div>
      </Modal>
    );
  };

  const getFacilitySubHeader = length => {
    const title = data.associatedRecords?.filter(
      value => value.name === 'facilityName' || value.name === 'unitName' || value.name === 'placementId',
    )[0];
    return title?.value?.length > length ? (
      <Tooltip arrow classes={{ tooltip: classes.tooltipBackground }} title={title.value}>
        <SubHeader variant="subtitle1" className={classes.modalHeaderSubtitle}>
          {data?.tos.find(Boolean)?.phoneNumber} | {title.value.substring(0, length) + '...'}
        </SubHeader>
      </Tooltip>
    ) : (
      <SubHeader variant="subtitle1" className={classes.modalHeaderSubtitle}>
        {data?.tos.find(Boolean)?.phoneNumber} | {title.value}
      </SubHeader>
    );
  };

  const Disposition = () => {
    return (
      <Modal open={true} className={classes.modal} hideBackdrop>
        <>
          {loader && <CustomBackdrop open />}
          <Draggable handle="#draggableArea" bounds="parent">
            <div className={classes.paper}>
              <ModalAppBar id="draggableArea" position="static">
                <Toolbar disableGutters style={{ background: '#88888' }}>
                  <Avatar className={classes.avatar} style={{ background: data.manualLog ? '#008b95' : '#6638B7' }}>
                    {data?.tos?.find(Boolean).name ? nameInitial(data.tos.find(Boolean).name) : <PersonIcon />}
                  </Avatar>
                  <Grid>
                    <Grid item>
                      <Header style={{ color: data.manualLog ? '#008b95' : '#6638b7' }}>
                        {t('Call with ')}
                        {data?.tos?.find(Boolean)?.name ? data?.tos.find(Boolean)?.name : 'Unknown Number'}
                      </Header>
                    </Grid>
                    <Grid item style={{ fontWeight: 'bold' }}>
                      {data.useType?.toLowerCase() === 'clientimpact' ? (
                        getFacilitySubHeader(25)
                      ) : (
                        <SubHeader variant="subtitle1" className={classes.modalHeaderSubtitle}>
                          {data?.tos?.find(Boolean)?.contactId
                            ? `${data?.tos.find(Boolean)?.phoneNumber} | ${t('CID ')} ${
                                data?.tos.find(Boolean)?.contactId
                              }`
                            : data?.tos?.find(Boolean)?.phoneNumber}
                        </SubHeader>
                      )}
                    </Grid>
                  </Grid>
                  <div style={{ marginLeft: 'auto', marginRight: '2%' }}>
                    <IndeterminateCheckBoxOutlinedIcon onClick={handleMinimize} className={classes.modalIcon} />
                  </div>
                </Toolbar>
              </ModalAppBar>

              <div className={classes.modalContent}>
                {globalData?.snackbarData?.channel === ChannelType.voice &&
                  !globalData?.snackbarData?.manual &&
                  globalData?.snackbarData?.changeWarning && (
                    <ChangeBanner message={t('notification.finishLogWarning')} />
                  )}
                <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center"></Grid>
                <FieldHeader>{t('Outcome')}</FieldHeader>
                <Grid container style={{ marginBottom: '3%' }}>
                  {OutcomeIcon(data.useType === UseType.ClientContacts).map(item => (
                    <Grid key={item.name} item>
                      <Outcome icon={item.icon} name={item.name} value={item.value} />
                    </Grid>
                  ))}
                </Grid>
                <FieldHeader>{t('Add note')}</FieldHeader>
                <Notes
                  label={t('notification.voice.notes')}
                  rows={5}
                  value={notes}
                  inputProps={{
                    maxLength: COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT,
                  }}
                  InputProps={{
                    classes: { input: classes.notes },
                    startAdornment:
                      notes.length >= COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? (
                        <InputAdornment
                          position="start"
                          style={{ position: 'absolute', top: '0', right: '0', color: theme.palette.system.errorRed }}
                        >
                          <InfoOutlinedIcon className={classes.errorInfoIcon} />
                        </InputAdornment>
                      ) : (
                        <></>
                      ),
                  }}
                  onChange={event => setNotes(event.target.value)}
                  onBlur={() => handleDisposition(notes.trim(), 'notes')}
                  variant="filled"
                  multiline
                  error={notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}
                />
                <LayoutGrid
                  container
                  justifyContent="space-between"
                  style={{ width: '100%', marginLeft: '0px' }}
                  xs={12}
                >
                  <LayoutGridItem style={{ paddingLeft: '12px' }}>
                    <label className={classes.errorText}>{`${
                      notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT
                        ? t('notification.voice.maxCharLimit')
                        : ' '
                    }`}</label>
                  </LayoutGridItem>
                  <LayoutGridItem>
                    <label
                      className={
                        notes.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT
                          ? classes.errorText
                          : classes.characterCount
                      }
                    >{`${notes.length}/${COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}`}</label>
                  </LayoutGridItem>
                </LayoutGrid>

                <Grid container spacing={0} direction="row" alignItems="center" justifyContent="center">
                  <Grid item>
                    {globalData?.snackbarData?.channel === ChannelType.voice &&
                      !globalData?.snackbarData?.manual &&
                      !globalData?.snackbarData?.changeWarning && (
                        <CloseConfirmation
                          yesAction={handleModalClose}
                          noAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
                        />
                      )}
                  </Grid>
                </Grid>
                {voiceDisposition?.disposition === null ? (
                  <Button className={classes.root} variant="contained">
                    <CustomTooltip tooltipContent={t('notification.voice.saveButtontooltip')}>
                      <div className={classes.root}>{t('Save')}</div>
                    </CustomTooltip>
                  </Button>
                ) : (
                  <Button
                    onClick={saveManualCallLog}
                    className={classes.actionButton}
                    variant="contained"
                    color="primary"
                  >
                    {t('Save')}
                  </Button>
                )}
              </div>
            </div>
          </Draggable>
        </>
      </Modal>
    );
  };

  return (
    <React.Fragment>
      {loader && <CustomBackdrop open />}
      {voiceAction?.openDeviceSelection && DeviceSelection()}
      {voiceAction?.showDisposition && Disposition()}
    </React.Fragment>
  );
};
