import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import { Grid, IconButton, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GetMomentOfDate } from 'utils/dates/moment';
import { FormInputCheckbox } from './Controls/FormInputCheckbox';
import { FormInputDatePicker } from './Controls/FormInputDatePicker';
import { dates } from './PersonalInfoHelper';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { ErrorMessage } from '@hookform/error-message';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { userRoles } from 'oidc/userRoles';
import { Authorized } from 'oidc/userHelper';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import useAddRoleTypeStyle from './AddRoleTypeSectionsV2.styles';
import { FormInputDatePickerV2 } from './Controls/FormInputDatePickerV2';

export const AddRoleTypeSectionV2 = props => {
  const user = useSelector(selectUser);

  const { classes } = useAddRoleTypeStyle();
  const { control, errors, setValue, setError, clearErrors, getValues } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'roleType',
  });

  const addRoleDisabled = moveOut => {
    let pastMoveOutDate = false;
    if (moveOut) {
      const currentDate = GetMomentOfDate(new Date()).format('MM/DD/YYYY');
      const moveOutDate = GetMomentOfDate(moveOut).format('MM/DD/YYYY');
      pastMoveOutDate = dates.gt(currentDate, moveOutDate);
    }
    return (
      pastMoveOutDate
    );
  };

  const handleStartDate = async (index, event) => {
    if (event && event !== '' && !props.disableEdit) {
      const currentDate = GetMomentOfDate(new Date()).format('MM/DD/YYYY');
      const startDate = GetMomentOfDate(event).format('MM/DD/YYYY');
      const moveInDate = GetMomentOfDate(props.moveInDate ?? '').format('MM/DD/YYYY');

      const res = dates.gt(startDate, currentDate);
      const gt = dates.gt(startDate, moveInDate);
      const eq = dates.eq(startDate, moveInDate);

      setValue(`roleType[${index}].Start`, startDate);

      if (!res)
        setError(`roleType[${index}].Start`, {
          type: 'manual',
          message: `Start date should be greater than today's date`,
        });

      if (!(gt || eq))
        setError(`roleType[${index}].Start`, {
          type: 'manual',
          message: 'Start date should be greater than or equal to the address move in date',
        });

      if ((gt || eq) && res) clearErrors(`roleType[${index}].Start`);
    }
  };

  const handleEndDate = async (index, event) => {
    if (event && event !== '' && !props.disableEdit) {
      const startDate = GetMomentOfDate(getValues(`roleType[${index}].Start`)).format('MM/DD/YYYY');
      const endDate = GetMomentOfDate(event).format('MM/DD/YYYY');

      const res = dates.gt(endDate, startDate);

      setValue(`roleType[${index}].End`, endDate);

      if (!res)
        setError(`roleType[${index}].End`, {
          type: 'manual',
          message: `End date should be greater than the start date`,
        });

      if (props.moveOutDate) {
        const moveOutDate = GetMomentOfDate(props.moveOutDate ?? '').format('MM/DD/YYYY');
        const gt = dates.gt(endDate, moveOutDate);
        if (gt)
          setError(`roleType[${index}].End`, {
            type: 'manual',
            message: 'End date should be less than or equal to the address move out date',
          });
        if (!gt && res) clearErrors(`roleType[${index}].End`);
      } else {
        if (res) clearErrors(`roleType[${index}].End`);
      }
    }
  };

  const deleteRoleType = index => {
    remove(index);
  };

  const getStartValue = (index: number) => {
    return getValues(`roleType[${index}].Start`);
    // if (Array.isArray(data) && data.length > index) {
    //   return data[index].Start;
    // } else return null;
  };

  const getEndValue = (index: number) => {
    var data = getValues().roleType;
    if (Array.isArray(data) && data.length > index) {
      return data[index].End;
    } else return null;
  };

  return (
    <>
      {fields.map((item, index) => (
        <Grid key={item.id} container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle2" gutterBottom component="div" className={classes.sectionTitle}>
              {fields.length == 1 ? 'Role Types' : `Role Type ${index + 1}`}
            </Typography>
          </Grid>
          <Grid item style={{ padding: '10px' }} onClick={() => deleteRoleType(index)}>
            <IconButton color="primary" size="small">
              <DeleteIcon className={classes.icon} />
            </IconButton>
          </Grid>
          <Grid container className={classes.sectionWrapper}>
            <Grid item>
              <div className={classes.startDate}>
                {/* <FormInputDatePicker
                  name={`roleType[${index}].Start`}
                  label="Start Date*"
                  handleChange={e => handleStartDate(index, e)}
                  require={true}
                  key={item.id}
                  inputValue={getStartValue(index)}
                  // customError={errors['roleType'][index].Start}
                ></FormInputDatePicker> */}
                <FormInputDatePickerV2
                  name={`roleType[${index}].Start`}
                  key={item.id}
                  require={true}
                  handleChange={e => handleStartDate(index, e)}
                  label="Start Date*"
                  readOnly={props.isDisabled || !props.roleTypeAccess?.startDate}
                />
              </div>
              <div className={classes.endDate}>
                <FormInputDatePickerV2
                  name={`roleType[${index}].End`}
                  label="End Date*"
                  handleChange={e => handleEndDate(index, e)}
                  require={props.require}
                  key={item.id}
                  readOnly={props.isDisabled || !props.roleTypeAccess?.endDate}
                ></FormInputDatePickerV2>
              </div>
            </Grid>
            <Grid item>
              <Grid container className={classes.checkBoxGroup}>
                <Grid item xs={5}>
                  <FormInputCheckbox
                    name={`roleType[${index}].W2`}
                    isChecked={props.data?.addressRoles[5].selected}
                    handleChecked={props.handleCheck}
                    checkboxLabel={`W2`}
                    isDisabled={props.isDisabled || !props.roleTypeAccess?.w2}
                    labelStyle={classes.checkBox}
                    key={item.id}
                  ></FormInputCheckbox>
                  <FormInputCheckbox
                    name={`roleType[${index}].Paycheck`}
                    isChecked={props.data?.addressRoles[3].selected}
                    handleChecked={props.handleCheck}
                    checkboxLabel={`Paycheck`}
                    labelStyle={classes.checkBox}
                    isDisabled={props.isDisabled || !props.roleTypeAccess?.paycheck}
                    key={item.id}
                  ></FormInputCheckbox>
                  <FormInputCheckbox
                    name={`roleType[${index}].Subsidy`}
                    isChecked={props.data?.addressRoles[4].selected}
                    handleChecked={props.handleCheck}
                    checkboxLabel={`Subsidy`}
                    isDisabled={props.isDisabled || !props.roleTypeAccess?.subsidy}
                    labelStyle={classes.checkBox}
                    key={item.id}
                  ></FormInputCheckbox>
                </Grid>
                <Grid item xs={6}>
                  <FormInputCheckbox
                    name={`roleType[${index}].Mailers`}
                    isChecked={props.data?.addressRoles[2].selected}
                    handleChecked={props.handleCheck}
                    checkboxLabel={`Mailers`}
                    isDisabled={props.isDisabled || !props.roleTypeAccess?.mailers}
                    labelStyle={classes.checkBox}
                    key={item.id}
                  ></FormInputCheckbox>
                  <FormInputCheckbox
                    name={`roleType[${index}].Cobra`}
                    isChecked={props.data?.addressRoles[0].selected}
                    handleChecked={props.handleCheck}
                    checkboxLabel={`Cobra`}
                    isDisabled={props.isDisabled || !props.roleTypeAccess?.cobra}
                    labelStyle={classes.checkBox}
                    key={item.id}
                  ></FormInputCheckbox>
                  <FormInputCheckbox
                    name={`roleType[${index}].DependentCobra`}
                    isChecked={props.data?.addressRoles[1].selected}
                    handleChecked={props.handleCheck}
                    checkboxLabel={`Dependent Cobra`}
                    labelStyle={classes.checkBox}
                    isDisabled={props.isDisabled || !props.roleTypeAccess?.dependentCobra}
                    key={item.id}
                  ></FormInputCheckbox>
                </Grid>
                <ErrorMessage
                  errors={errors}
                  name={`roleType[${index}].CheckboxError`}
                  render={({ message }) => (
                    <span style={{ color: '#f44336', fontSize: '0.75rem', marginLeft: '0px', marginTop: '-8px' }}>
                      {message}
                    </span>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid container alignItems={'center'}>
        <Grid item style={{ padding: 0 }}>
          <IconButton
           color="primary"
            onClick={() => {
              append({});
            }}
            disabled={addRoleDisabled(props.moveOutValue)}
            className={classes.addRoleButton}
            size="small"
            disableFocusRipple
            disableTouchRipple
          >
            <AddBoxIcon className={classes.addRoleIcon} />
          </IconButton>
        </Grid>
        <Grid item style={{ padding: 0 }}>
          <Typography className={classes.addRolesText}>Add Roles</Typography>
        </Grid>
      </Grid>
    </>
  );
};
