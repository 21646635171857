import { IRequirementsUI } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { ITypeAheadOptions } from '@AMIEWEB/Order/Store/Order.redux';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICompactStatusLookup, IEditOrderSave, NewUnit } from 'app/models/Unit/CreateUnit';
import {
  INewOrderInitialState,
  IOrderType,
  IPage2,
  IPage3,
  ISubmissionPreferences,
  OrderCreationStepEnum,
  OrderTypeDetails,
} from '../types';
import { IOrderFreeText } from 'app/models/Orders/OrderDetails';
import { IOrderLicenseLookup } from '../constants';

export const initialState: INewOrderInitialState = {
  orderId: null,
  isEditSuccess: false,
  activeStep: OrderCreationStepEnum.UnitSelection,
  isNewOrderCreatedFromUnit: false,
  previewTabSelected: false,
  selectedUnitId: null,
  selectedUnitItem: null,
  selectedUnitSkillsSets: null,
  requiredQualifications: '',
  preferredQualifications: '',
  additionalRequiredQualifications: '',
  internalNotes: '',
  jobDescription: '',
  orderPriorities: [], //TODO can be moved to the order details redux once available
  typeAheadDropdownOptions: null,
  editLoader: {
    isLoader: false,
    isSaveFailed: false,
  },
  clientContacts: [],
  disableNextBtnSidePanel: false,
  compactStatusOptions: [],
  orderLicenseOptions: [],
  page1: {
    ssnRequired: false,
    dobRequired: false,
    maxFilesSent: false,
    hideFacilityDescription: false,
    suppressExternalPosting: false,
    reasonForMaxFilesSent: '',
    positionsAvailable: undefined,
    orderType: '',
    orderTypeId: 0,
    orderReason: '',
    region: '',
    skillSetGroup: {
      operation: null,
      groups: [
        {
          id: null,
          operation: null,
          skillsets: [
            {
              id: 1,
              isPrimary: false,
              skillSet: {
                discipline: null,
                disciplineId: 0,
                disciplineAbbr: null,
                isNewGrad: false,
                isRequired: true,
                monthsOfExperience: null,
                specialty: null,
                specialtyId: 0,
                specialtyAbbr: null,
                subSpecialty: null,
                subSpecialtyId: 0,
                isPrimary: false,
                subSpecialtyAbbr: null,
                yearsOfExperience: null,
                isSkillsCheckList: false,
                isClinicalFellowship: false,
              },
              travelExperience: {
                experienceTypeId: 0,
                experienceTypeText: '',
                isRequired: false,
                numberOfAssignments: null,
                yearsOfExperience: null,
                monthsOfExperience: null,
                withinTwoYears: false,
                isAMNTravel: false,
                experienceType: null,
              },
              collections: [
                {
                  id: 1,
                  type: null,
                  operation: null,
                  requirements: [],
                },
                {
                  id: 1,
                  type: null,
                  operation: null,
                  requirements: [] as IRequirementsUI[],
                },
              ],
            },
          ],
        },
      ],
    },
  },

  page2: {
    areDefaultValueSet: false,
    orderClinicianToPatientRatio: {
      notifyCandidate: false,
      numberOfClinicians: null,
      numberOfPatients: null,
      numberOfVisits: null,
      patientsSeen: null,
      productivityPercentage: null,
    },
    orderWorkHistory: {
      entireWorkHistory: false,
      workHistoryGapInDays: null,
      workHistoryGapInYears: null,
    },
    virtualRatingSubmissionRequirement: false,
    orderPriorities: [],
    bestBets: '--',
    bestBetsId: 0,
    reference: {
      isRequired: false,
      numberOfReferenceRequired: 0,
      referenceFromSupervisor: false,
      numberOfReferenceToRFO: 0,
      withinMonths: false,
      withinNumberOfMonths: null,
    },
    orderStrike: {
      okToTravel: false,
      strikeNoticeDate: null,
    },
  },
  page3: {
    areDefaultValueSet: false,
    shifts: [],
    additionalInformation: {
      allowBlocks: { isSelected: false, selectedValue: '' },
      float: { isSelected: false, selectedValue: '' },
      holidays: { isSelected: false, selectedValue: '' },
      noTimeOffAllowed: { isSelected: false, selectedValue: '' },
      onCall: { isSelected: false, selectedValue: '' },
      weekends: { isSelected: false, selectedValue: '' },
      scrubColors: '',
    },
    VMSOrderNumber: null,
    webLink: '',
    placementLength: 13,
    ASAP: false,
    flexibleDates: false,
    startDate: null,
    endDate: null,
    VMSBillRate: null,
    expectedHours: null,
    orderBillRate: null,
    guaranteedHours: null,
  },

  preferences: {
    submissionLimits: {
      activePlacementCounts: {
        rfo: null,
        sent: null,
        others: null,
      },
    },
    wfcToRfo: false,
    candidateTasks: false,
    rfoToSent: false,
    sendPacket: false,
    savePacket: false,
    submissionEmailSetup: '',
    submissionPacketSetup: '',
    maxFilesSent: false,
    packetTemplate: {
      amnBrand: false,
      anonymize: false,
      showCandidateHighlights: false,
      showBillRate: null,
      billRate: null,
      templateValue: '',
    },
    noteToFacility: '',
    orderId: 0,
    updatedBy: null,
  },
};

export const orderCreationNewSlice = createSlice({
  name: 'orderCreationNew',
  initialState,
  reducers: {
    setActiveStep(state, action: PayloadAction<OrderCreationStepEnum>) {
      state.activeStep = action.payload;
    },
    setPreviewTabSelected(state, action: PayloadAction<boolean>) {
      state.previewTabSelected = action.payload;
    },
    setSelectedUnitId(state, action: PayloadAction<OrderCreationStepEnum>) {
      if (state.selectedUnitId !== action.payload) {
        state.page2.areDefaultValueSet = false;
        state.page3.areDefaultValueSet = false;
      }
      state.selectedUnitId = action.payload;
    },
    setSelectedUnitItem(state, action: PayloadAction<NewUnit>) {
      state.selectedUnitItem = action.payload;
      state.page2.bestBets = action.payload?.bestBets;
      state.page2.bestBetsId = action.payload?.bestBetsId;
      state.jobDescription = action.payload?.externalJobDescription ?? '';
      state.internalNotes = action.payload?.internalNotes ?? '';
      state.additionalRequiredQualifications = action.payload?.additionalRequiredQualifications ?? '';
      state.preferredQualifications = action.payload?.preferredQualifications ?? '';
      state.page1 = {
        ...action.payload,
      };
      state.page1.ssnRequired = action.payload.ssn ?? false;
      state.page1.dobRequired = action.payload.dob ?? false;
    },
    setSkillSetsForSelectedUnit(state, action: PayloadAction<any>) {
      state.selectedUnitSkillsSets = action.payload;
      //need to transform this before assiging

      if (action.payload) {
        state.page1.skillSetGroup = {
          ...state.page1.skillSetGroup,
          ...state.selectedUnitSkillsSets,
        };
      }
    },
    setOrderType(state, action: PayloadAction<IOrderType>) {
      state.page1.orderType = action.payload.Description;
      state.page1.orderTypeId = action.payload.ID;
    },
    setNewOrderCreationData(state, action: PayloadAction<INewOrderInitialState>) {
      state = action.payload;
    },
    setPage1FormData(state, action: PayloadAction<OrderTypeDetails>) {
      state.page1 = action.payload;
    },
    setPage2DefaultValues(state, action: PayloadAction<boolean>) {
      state.page2.areDefaultValueSet = true;
    },
    setPage3DefaultValues(state, action: PayloadAction<boolean>) {
      state.page3.areDefaultValueSet = true;
    },
    setPage2FormData(state, action: PayloadAction<IPage2>) {
      state.page2 = { ...state.page2, ...action.payload };
    },
    setOrderPriorities(state, action: PayloadAction<{ name: string; value: string }[]>) {
      state.orderPriorities = action.payload;
    },
    setPage3FormData(state, action: PayloadAction<IPage3>) {
      state.page3 = { ...state.page3, ...action.payload };
    },
    setPreferencesFormData(state, action: PayloadAction<ISubmissionPreferences>) {
      state.preferences = { ...state.preferences, ...action.payload };
    },
    setTypeAheadDropdownOptions(state, action: PayloadAction<ITypeAheadOptions>) {
      state.typeAheadDropdownOptions = action.payload;
    },
    resetTravelExperience(state, action: PayloadAction<{ groupIndex: number; skillSetIndex: number }>) {
      state.page1.skillSetGroup.groups[action.payload.groupIndex].skillsets[
        action.payload.skillSetIndex
      ].travelExperience = null;
    },
    setOrderId(state, action: PayloadAction<string>) {
      state.orderId = action.payload;
    },
    setAllPageData(state, action: PayloadAction<{ page1: OrderTypeDetails; page2: IPage2; page3: IPage3 }>) {
      state.page1 = action.payload.page1;
      state.page2 = action.payload.page2;
      state.page3 = action.payload.page3;
    },
    setIsEditSuccess(state, action: PayloadAction<boolean>) {
      state.isEditSuccess = action.payload;
    },
    setQualificationText(state, action: PayloadAction<{ name: string; value: string }>) {
      state[action.payload.name] = action.payload.value;
    },
    setOrderQualificationFreeText(state, action: PayloadAction<IOrderFreeText>) {
      state.additionalRequiredQualifications = action.payload?.additionalRequiredQualifications ?? '';
      state.preferredQualifications = action.payload?.preferredQualifications ?? '';
      state.requiredQualifications = action.payload?.requiredQualifications ?? '';
      state.internalNotes = action.payload?.internalNotes ?? '';
      state.jobDescription = action.payload?.jobDescription ?? '';
    },
    setIsEditLoader(state, action: PayloadAction<IEditOrderSave>) {
      state.editLoader = action.payload;
    },
    setClientContacts(state, action: PayloadAction<any[]>) {
      state.clientContacts = action.payload;
    },
    setDisableNextBtnSidePanel(state, action: PayloadAction<boolean>) {
      state.disableNextBtnSidePanel = action.payload;
    },
    setNewOrderCreatedFromUnit(state, action: PayloadAction<boolean>) {
      state.isNewOrderCreatedFromUnit = action.payload;
    },
    setOrderLicenseCompactStatusOptions(state, action: PayloadAction<ICompactStatusLookup[]>) {
      state.compactStatusOptions = action.payload;
    },
    setOrderLicenseOptions(state, action: PayloadAction<IOrderLicenseLookup[]>) {
      state.orderLicenseOptions = action.payload;
    },

    reset() {
      return { ...initialState };
    },
    resetForm(state) {
      return {
        ...initialState,
        orderPriorities: state.orderPriorities,
        typeAheadDropdownOptions: state.typeAheadDropdownOptions,
      };
    },
  },
});

export const { reducer: orderCreationNewReducer, name: orderCreationNewSliceKey } = orderCreationNewSlice;
