import { Editor } from '@tinymce/tinymce-react';
import { Box, Button, Grid, TextField } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { trackEvent } from 'app-insights/appInsightsTracking';
import { EmailValidator } from 'app/ComponentLibrary/Molecules/EmailValidator';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styledComponent from 'styled-components';
import { ChannelType } from '../../Constants';
import {
  notificationSelection,
  selectEmailBCC,
  selectEmailData,
  selectEmailType,
  selectSender,
} from 'store/redux-store/notification/notification.selector';
import { Attachments } from './EmailAttachment';
import { UploadFileBackdrop } from '../../Common/UploadBackdrop';
import PreviewAttachmentsWithUrl from './PreviewAttachmentsWithUrls';
import { ContactProps, IEmployee } from 'app/models/Notification/Notification';
import { FromField } from './FromField';
import { selectCoveredEmails } from 'store/redux-store/user-coverage/selectors';
import { useDecision } from '@optimizely/react-sdk';
import { ff_notifications_coverage_email } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const useStyles = makeStyles()(() => ({
  paper: {
    borderBottom: '1px solid #000',
  },
  subjectResize: {
    height: '35px',
    backgroundColor: '#FBFBFB',
    padding: '4px 15px 5px',
    borderBottom: '1px solid #CCC',
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'auto',
    margin: '0.5% 0 1% 0',
    borderBottom: '1px solid #CCC',
    '& div.tox-tinymce': {
      border: 'none',
      borderRadius: '0px',
    },
    '& div.tox-editor-header': {
      boxShadow: 'none !important',
      borderBottom: '1px solid lightgrey !important',
    },
    '& div.tox-editor-header, div.tox-toolbar': {
      backgroundColor: '#fbfbfb !important',
    },
    '& div.tox-toolbar__group:last-child': {
      marginLeft: 'auto',
      borderLeft: 'none',
    },
  },
  emailField: {
    width: '100%',
    marginBottom: '0.2%',
    backgroundColor: '#FBFBFB',
    paddingBottom: '0px',
  },
}));

const API_KEY = globalThis?.app?.env?.REACT_APP_TINYMCE_API_KEY;

export const SubjectField = styledComponent(TextField)`
  background: #fbfbfb;
`;

interface IDragResult {
  source: {
    droppableId: string;
    index: number;
  };
  destination?: {
    droppableId: string;
    index: number;
  };
  draggableId: string;
}

export function Compose(props) {
  const {
    loader,
    handlers,
    getInputProps,
    showUploadBackdrop,
    expanded = false,
    changeTracker,
    data,
    senderMenuValue,
    setSenderMenuValue,
    subject,
    setDefaultEmailData,
  } = props;

  const { t } = useTranslation();
  const [coverageFlag] = useDecision(ff_notifications_coverage_email);
  const { classes } = useStyles();
  const [showMoreRecipient, setShowMoreRecipient] = useState<boolean>(false);
  const globalData = useSelector(notificationSelection);
  const bccData = useSelector(selectEmailBCC);
  const coverageData = useSelector(selectCoveredEmails);
  const [notificationData, setNotificationData] = useState(globalData.email.data!);
  const [value, setValue] = useState(props.html ?? '');
  const [fixedFlag, setFixedFlag] = useState<boolean>(true);
  const [warningText, setWarningText] = useState<boolean>(false);
  const [Recipients, setRecipients] = useState([
    { label: 'To:', name: 'tos', show: true },
    { label: 'CC:', name: 'ccs', show: false },
    { label: 'BCC:', name: 'bccs', show: false },
  ]);
  const [isDragging, setIsDragging] = useState(false);

  const emailData = useSelector(selectEmailData);
  const emailType = useSelector(selectEmailType);
  const emailSender = useSelector(selectSender);
  const emailTypeRef = useRef(null);
  const emailSenderRef = useRef(null);
  useEffect(() => {
    emailSenderRef.current = emailSender;
  }, [emailSender]);

  useEffect(() => {
    if (emailData?.data.ccs && emailData?.data.ccs.length > 0) {
      setShowMoreRecipient(true);
      setRecipients([
        { label: 'To: ', name: 'tos', show: true },
        { label: 'CC:', name: 'ccs', show: true },
        { label: 'BCC:', name: 'bccs', show: true },
      ]);
    }
  }, [emailData]);

  useEffect(() => {
    if (notificationData?.tos && notificationData?.tos?.length > 0) {
      setWarningText(false);
    }
  }, [notificationData]);

  useEffect(() => {
    setNotificationData(globalData.email.data!);
    //Add the conditions for fixing the email chips here..
    if (
      globalData?.email?.data?.selectedTemplate?.category === 'Job Match' ||
      globalData?.email?.data?.packetSubmission ||
      globalData?.email?.data?.confirmationLetter ||
      globalData.email.data?.attachmentCannotBeRemoved ||
      globalData.email.data?.alertId
    ) {
      setFixedFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalData.email.data]);

  useEffect(() => {
    setValue(props.html ?? '');
    editor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.html]);
  useEffect(() => {
    emailTypeRef.current = emailType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailType]);

  const bodyValue = React.useRef(globalData.email.data!.body ?? '');
  const subjectValue = React.useRef(globalData.email.data!.subject ?? '');
  const tosValue = React.useRef(globalData.email.data!.tos ?? []);
  const ccsValue = React.useRef(globalData.email.data!.ccs ?? []);
  const bccsValue = React.useRef(globalData.email.data!.bccs ?? []);
  subjectValue.current = props.subject ?? '';
  useEffect(() => {
    bccsValue.current = bccData;
  }, [bccData]);

  useEffect(() => {
    if (!changeTracker?.isEdited && !changeTracker?.showConfirmation && changeTracker?.changedTemplate?.body) {
      handleChange('body', changeTracker?.changedTemplate?.body);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTracker]);

  const handleMoreRecipient = data => {
    const newFields = showMoreRecipient
      ? [
          { label: 'To: ', name: 'tos', show: true },
          { label: 'CC:', name: 'ccs', show: false },
          { label: 'BCC:', name: 'bccs', show: false },
        ]
      : [
          { label: 'To:', name: 'tos', show: true },
          { label: 'CC:', name: 'ccs', show: true },
          { label: 'BCC:', name: 'bccs', show: true },
        ];

    setRecipients(newFields);
    setShowMoreRecipient(!showMoreRecipient);
  };
  const MoreRecipientAction = () => (
    <Button variant="text" disableRipple onClick={handleMoreRecipient}>
      {t('notification.emails.handleMoreRecepients')}
    </Button>
  );

  const editor = () => {
    return (
      <>
        {props.showOverlay && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0',
              zIndex: 1,
            }}
          ></div>
        )}
        <Editor
          value={value}
          onInit={() => {
            handlers.setLoader({ ...loader, backdropLoader: false });
          }}
          apiKey={API_KEY}
          init={{
            init_instance_callback: function (editor) {
              editor.on('KeyPress', function (e) {
                handlers.setTemplateChangeTracker(prevState => ({
                  ...prevState,
                  isEdited: true,
                }));
              });
              editor.on('keydown', function (e) {
                if (e.key === 'Backspace' || e.key === 'Delete') {
                  handlers.setTemplateChangeTracker(prevState => ({
                    ...prevState,
                    isEdited: true,
                  }));
                }
              });
              editor.on('paste', function (e) {
                handlers.setTemplateChangeTracker(prevState => ({
                  ...prevState,
                  isEdited: true,
                }));
              });
              editor.on('blur', function (e) {
                handleChange('body', e.target.getContent());
              });
            },
            setup: function (editor) {
              editor.ui.registry.addMenuButton('insert', {
                text: 'Insert',
                fetch: function (callback) {
                  const items: any[] = [];
                  globalData.email?.data?.substitutions?.map(token => {
                    const item = {
                      type: 'menuitem',
                      text: token?.name,
                      onAction: function () {
                        trackEvent({ type: 'event', name: 'Insert -' + token?.input });
                        editor.insertContent(
                          (globalData.email.data?.packetSubmission || globalData.email.data?.confirmationLetter) &&
                            token?.name !== 'Signature'
                            ? token?.value
                            : token?.input,
                        );
                        handlers.setTemplateChangeTracker(prevState => ({
                          ...prevState,
                          isEdited: true,
                        }));
                      },
                      enabled:
                        (globalData.email.data?.packetSubmission || globalData.email.data?.confirmationLetter) &&
                        token?.name !== 'Signature'
                          ? token?.value != null && token?.value != ''
                          : true,
                    };
                    items.push(item);
                  });

                  callback(items);
                },
              });
              editor.ui.registry.addGroupToolbarButton('grouptools', {
                icon: 'image-options',
                tooltip: 'additional options',
                items: 'removeformat | help | undo redo',
              });
            },
            plugins: [
              'advlist',
              'autolink',
              'lists',
              'image',
              'link',
              'charmap',
              'print',
              'preview',
              'anchor',
              'searchreplace',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'paste',
              'code',
              'help',
            ],
            toolbar: [
              'bold italic backcolor image | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | grouptools | insert',
            ],
            toolbar_mode: 'floating',
            branding: false,
            statusbar: false,
            elementpath: false,
            height: '100%',
            block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3',
            menubar: false,
            forced_root_block: '',
            force_br_newlines: true,
            force_p_newlines: false,
            paste_data_images: true,
            relative_urls: false,
            remove_script_host: false,
            content_style: 'body { background-color: rgb(251, 251, 251); border:none;}',
          }}
          onEditorChange={newValue => {
            setValue(newValue);
          }}
        />
      </>
    );
  };

  const onDragEnd = (result: IDragResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    const sourceId = source.droppableId;
    const destinationId = destination.droppableId;
    const index = destination.index;
    const [email, name] = draggableId.split('-');

    const sourceArray = notificationData[sourceId] || [];
    const destinationArray = notificationData[destinationId] || [];

    const emailRecipient = sourceArray.find(item => item.email === email);

    if (emailRecipient && sourceId && destinationId) {
      setNotificationData(prevState => {
        const updatedSourceItems = sourceArray.filter(item => item.email !== email);
        const updatedEmailRecipient = { ...emailRecipient, editFlag: true };
        const updatedDestinationItems = JSON.parse(JSON.stringify([...destinationArray, updatedEmailRecipient]));
        updatedDestinationItems.splice(index, 0, updatedEmailRecipient);
        handleChange(sourceId, updatedSourceItems);
        handleChange(destinationId, updatedDestinationItems);

        const updatedNotificationData = {
          ...prevState,
          [sourceId]: updatedSourceItems,
          [destinationId]: updatedDestinationItems,
        };

        setWarningText(updatedNotificationData?.tos?.length === 0);
        setIsDragging(false);

        return updatedNotificationData;
      });
    }
  };

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const getChannelValidator = (channel, recipient) => {
    if (recipient.show) {
      switch (channel) {
        case ChannelType.email:
          return (
            <EmailValidator
              notificationData={notificationData}
              warningText={warningText}
              value={notificationData?.[recipient.name]}
              fixed={fixedFlag ? recipient.name === 'tos' : fixedFlag}
              disabled={
                notificationData?.packetSubmission ||
                notificationData?.confirmationLetter ||
                notificationData?.alertId ||
                isDragging
                  ? false
                  : recipient.name === 'tos' &&
                    notificationData?.tos?.length > 0 &&
                    !notificationData.attachmentCannotBeRemoved
              }
              name={recipient.name}
              variant="standard"
              label={recipient.label}
              className={classes.emailField}
              inputStyle={{ backgroundColor: 'none', background: 'none', border: 'none' }}
              onChange={handleChange}
              helperText={
                notificationData &&
                notificationData?.selectedTemplate?.category === 'Job Match' &&
                recipient.name === 'tos' &&
                notificationData?.tos?.length === 0
                  ? 'Add a recipient'
                  : recipient.name === 'tos' &&
                    notificationData &&
                    notificationData?.tos?.length === 0 &&
                    t('notification.emails.addRecipient')
              }
              action={recipient.name === 'tos' && <MoreRecipientAction />}
              errorText={t('notification.emails.addValidRecipient')}
              required={recipient.name === 'tos'}
              populateDropdown={
                (notificationData?.selectedTemplate?.category === 'Job Match' && recipient.name === 'tos') ||
                !!notificationData.attachmentCannotBeRemoved
              }
            />
          );
      }
    }
  };

  const handleChange = (property: string, propertyValue: string) => {
    if (property === 'body') bodyValue.current = propertyValue;
    else if (property === 'subject') subjectValue.current = propertyValue;
    else if (property === 'tos') tosValue.current = propertyValue as unknown as ContactProps[];
    else if (property === 'ccs') ccsValue.current = propertyValue as unknown as ContactProps[];
    else if (property === 'bccs') bccsValue.current = propertyValue as unknown as IEmployee[];

    const existingEmailData = { ...emailData.data, emailType: emailTypeRef.current, sender: emailSenderRef.current };
    if (!existingEmailData) return;

    if (
      property === 'body' ||
      property === 'subject' ||
      property === 'tos' ||
      property === 'ccs' ||
      property === 'bccs'
    ) {
      existingEmailData.body = bodyValue.current;
      existingEmailData.subject = subjectValue.current;
      existingEmailData.tos = tosValue.current;
      existingEmailData.ccs = ccsValue.current;
      existingEmailData.bccs = bccsValue.current;
    } else existingEmailData[property] = propertyValue;

    handlers.handleEmailPropertyChange(existingEmailData);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={handleDragStart}>
      <div className="drop">
        {showUploadBackdrop && <UploadFileBackdrop open />}
        <Box className={classes.paper}>
          <Grid>
            <input {...getInputProps()} />
            <Grid container>
              {(!!data?.packetSubmission ||
                !!data?.confirmationLetter ||
                (coverageFlag.enabled && coverageData?.length > 0)) &&
                !data?.alertId && (
                  <FromField
                    packetSubmission={!!data?.packetSubmission && !data?.alertId}
                    clientConfirmation={!!data?.confirmationLetter}
                    handleChange={handleChange}
                    senderMenuValue={senderMenuValue}
                    setSenderMenuValue={setSenderMenuValue}
                    setDefaultEmailData={setDefaultEmailData}
                  />
                )}
              {Recipients.map((recipient, index) => getChannelValidator(notificationData.channel, recipient))}
              {notificationData.channel === ChannelType.email && (
                <SubjectField
                  id="subject"
                  className={classes.emailField}
                  value={subject ?? notificationData?.subject}
                  variant="standard"
                  placeholder={t('notification.emails.subjectLine')}
                  InputProps={{ classes: { input: classes.subjectResize } }}
                  onChange={e => handleChange('subject', e.target.value)}
                />
              )}
            </Grid>
            <div className={classes.scroll} style={{ position: 'relative', height: expanded ? '550px' : '370px' }}>
              {editor()}
            </div>
            {notificationData.channel === ChannelType.email &&
              (!!notificationData.attachmentCannotBeRemoved ? (
                <PreviewAttachmentsWithUrl attachmentUrls={notificationData.attachmentUrls!} />
              ) : (
                <Attachments
                  data={props.attachments}
                  dataUrlAttachments={props.urlAttachments}
                  handleDataUrlAttachments={props.handleUrlAttachments}
                  uploadFiles={props.handleAttachments}
                  getInputProps={getInputProps}
                />
              ))}
          </Grid>
        </Box>
      </div>
    </DragDropContext>
  );
}
