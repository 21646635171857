import { ExperienceType, Reference, NewUnit, ICompactStatusLookup, IEditOrderSave } from 'app/models/Unit/CreateUnit';

import { ISkillSetGroup } from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import { ITypeAheadOptions } from '@AMIEWEB/Order/Store/Order.redux';
import { ITypeAheadOption } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { IAutoCompleteOption } from './CreateOrder/Common/AutoCompleteRenderChips';
import { IOrderFreeText } from 'app/models/Orders/OrderDetails';
import { IOrderLicenseLookup } from './constants';

export enum OrderCreationStepEnum {
  UnitSelection = 0,
  OrderRequirements = 1,
  AdditionalRequirements,
  OrderDetails,
  //Will be included in future
  // RateCard,
  PreviewAndSubmit,
}
export const OrderCreationSteps = [
  OrderCreationStepEnum.UnitSelection,
  OrderCreationStepEnum.OrderRequirements,
  OrderCreationStepEnum.AdditionalRequirements,
  OrderCreationStepEnum.OrderDetails,
  // OrderCreationStepEnum.RateCard,
  OrderCreationStepEnum.PreviewAndSubmit,
];

export interface OrderTypeDetails {
  ssnRequired: boolean;
  dobRequired: boolean;
  maxFilesSent: boolean;
  hideFacilityDescription: boolean;
  suppressExternalPosting: boolean;
  reasonForMaxFilesSent?: string;
  positionsAvailable?: number;
  orderType?: string;
  orderTypeId?: number;
  regionId?: number;
  orderReasonId?: number;
  orderReason?: string;
  region?: string;
  name?: string;
  experience?: ExperienceType;
  skillSetGroup: ISkillSetGroup;
}
export interface IDetails {
  orderDetails: IOrderFreeText;
}

export interface IBreadCrumb {
  title: string;
  clickable: boolean;
}

export interface INewOrderInitialState {
  orderId: string | null; //null for create order and id for edit order
  activeStep: OrderCreationStepEnum | null;
  previewTabSelected: boolean | null;
  selectedUnitId: number | null;
  selectedUnitItem: NewUnit | null;
  selectedUnitSkillsSets: ISkillSetGroup | null;
  typeAheadDropdownOptions: ITypeAheadOptions | null;
  page1: OrderTypeDetails;
  page2: IPage2;
  page3: IPage3;
  preferences: ISubmissionPreferences;
  orderPriorities: { name: string; value: string }[];
  isEditSuccess: boolean;
  requiredQualifications: string;
  preferredQualifications: string;
  additionalRequiredQualifications: string;
  internalNotes: string;
  jobDescription: string;
  editLoader: IEditOrderSave;
  clientContacts?: any[];
  disableNextBtnSidePanel?: boolean;
  isNewOrderCreatedFromUnit: boolean;
  compactStatusOptions: ICompactStatusLookup[];
  orderLicenseOptions: IOrderLicenseLookup[];
}

// #region Page2
export interface IDefaultPage {
  areDefaultValueSet: boolean;
}
export interface IPage2 extends IDefaultPage {
  orderClinicianToPatientRatio: ClinicianToPatientRatio;
  orderWorkHistory: OrderWorkHistory;
  virtualRatingSubmissionRequirement: boolean;
  orderPriorities: IAutoCompleteOption[];
  bestBets: string;
  bestBetsId: number;
  reference: Reference;
  orderStrike: OrderStrike;
}
export interface IPage3 extends IDefaultPage {
  additionalInformation: AdditionalInformation;
  shifts: ITypeAheadOption[];
  placementLength: null | number;
  ASAP: null | boolean;
  flexibleDates: null | boolean;
  startDate: null | Date;
  endDate: null | Date;
  VMSOrderNumber: null | string;
  webLink: null | string;
  VMSBillRate: null | number | string;
  expectedHours: null | number;
  guaranteedHours: null | number;
  orderBillRate: null | number;
}

export interface OrderDetailsAdditionalInfo {
  isSelected: null | boolean;
  selectedValue: null | string;
}

export interface AdditionalInformation {
  scrubColors: null | string;
  holidays: null | OrderDetailsAdditionalInfo;
  onCall: null | OrderDetailsAdditionalInfo;
  allowBlocks: null | OrderDetailsAdditionalInfo;
  weekends: null | OrderDetailsAdditionalInfo;
  float: null | OrderDetailsAdditionalInfo;
  noTimeOffAllowed: null | OrderDetailsAdditionalInfo;
}

export interface ClinicianToPatientRatio {
  notifyCandidate: boolean;
  patientsSeen: number | string;
  numberOfVisits: number | string;
  numberOfClinicians: number;
  numberOfPatients: number | string;
  productivityPercentage?: number;
}

export interface OrderWorkHistory {
  workHistoryGapInDays?: number;
  workHistoryGapInYears?: number;
  entireWorkHistory: boolean;
}
export interface OrderStrike {
  okToTravel: boolean;
  strikeNoticeDate: null | Date;
}
// #endregion

export interface IHeaderRow {
  rowHead: string | React.ReactNode;
  rowContent: string | React.ReactNode;
  classes?: any;
  id: string;
  type?: string;
}

export interface IGroupedTypeAheadOption extends ITypeAheadOption {
  groupBy: string;
}

export const VirtualInterviewOrOrderPriorities = {
  name: 'orderPriorities',
  checkboxName: 'virtualRatingSubmissionRequirement',
};

export interface ICetificate {
  certificates: ICertificates | null;
  subCertificate: ISubCertificate[] | null;
  isRequired: boolean;
  documentAttachmentRequired: boolean;
  issuedById: any;
}

export interface ICertificates {
  label: string;
  object: {
    abbreviation: string;
    category: string;
    certificationName: string;
    credentialName: string;
    id: number;
    requirementTypeID: number;
    subCategory: string;
    unitId: number;
  };
}
export interface ISubCertificate {
  id: number;
  name: string;
  value: string;
  isRequired: boolean;
  documentAttachmentRequired: boolean;
}

export interface ISubmissionPreferences {
  submissionLimits?: {
    activePlacementCounts: {
      rfo: number;
      sent: number;
      others: number;
    } | null;
  };
  wfcToRfo?: boolean;
  candidateTasks?: boolean;
  rfoToSent?: boolean;
  sendPacket?: boolean;
  savePacket?: boolean;
  submissionEmailSetup?: string;
  submissionPacketSetup?: string;
  maxFilesSent?: boolean;
  packetTemplate?: IpacketTemplate;
  noteToFacility?: string;
  updatedBy?: number;
  orderId?: number;
}

export interface ISubmissionOrderPreferences {
  autoRFO?: boolean;
  candidateTasks: boolean;
  rfoToSent: boolean;
  sendPacket: boolean;
  savePacket?: boolean;
  submissionEmailSetup?: string;
  submissionPacketSetup?: string;
  updatedBy?: any;
  orderId?: any;
}

export interface IpacketTemplate {
  amnBrand: boolean;
  anonymize: boolean;
  showCandidateHighlights: boolean;
  showBillRate: boolean;
  billRate: number;
  templateValue: string;
}
export interface IUnitDialoge {
  dialogeOpen: boolean;
  dialogeItem: string;
}

export const initialPacketState: IorderPacketTemplate = {
  amnBrand: false,
  showCandidateHighlights: false,
  showBillRate: false,
  billRate: null,
  templateValue: null,
};

export interface IorderPacketTemplate {
  amnBrand: boolean;
  showCandidateHighlights: boolean;
  showBillRate: boolean;
  billRate: number;
  templateValue: string;
}


export enum GlobalCategory {
  placement,
  facility,
  candidate,
  order,
  unit,
}

export interface IOrderCreationParams {
  category: GlobalCategory;
  value: number;
}
