import {
  ICollection,
  IGroup,
  IRequirements,
  ISkillSet,
  ISkillSetGroup,
  ISkillSetInfo,
  ITravelExperience,
} from '@AMIEWEB/Facility/FacilityStore/Facility.model';
import {
  getSeperatedCertSubCertItems,
  getTransformedLicenses,
} from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/UnitDetailDataTransformer';
import { getIssuedBy } from '@AMIEWEB/Order/OrderCreationForm/Components/UnitDetail/UnitDetailDefaultValues';
import { StatusTileDescription, StatusTileProps, StatusTileText } from '@AMIEWEB/Unit/Common/StatusTile';
import {
  AndOrOperator,
  CollectionType,
  SkillSetCollections,
  TravelExperienceSectionIndex,
  TravelExperienceSections,
  dateAbbreviationText,
} from 'app/enums/Common';
import { IOrderDetails, IOrderFreeText } from 'app/models/Orders/OrderDetails';
import { Reference } from 'app/models/Unit/CreateUnit';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';
import _, { isNil, sortBy } from 'lodash';
import moment from 'moment';
import { ReactElement } from 'react';
import { Concatenate, isStrNull } from 'utils/string/string';
import { ICetificate, OrderWorkHistory } from '../types';
import i18next from 'i18next';
import { IAutoCompleteOption } from './Common/AutoCompleteRenderChips';
import { RequirementType } from 'app/enums/Facility';

export const missingField = '--';

export const calculateOrderTypeDefaultValue = (orderDetailOrderType, facilityDetails) => {
  let defaultTypeValue = null;
  const STRIKE_ID = '12';
  const LOCAL_ID = '2';
  const TRAVEL_ID = '1';

  defaultTypeValue = orderDetailOrderType?.find(order => {
    const divisionTypeId = facilityDetails?.facilityResponse?.amnDivisionTypeId;
    const orderId = order.ID;

    if (divisionTypeId === AMNDivisionType.hsg) {
      return orderId === STRIKE_ID;
    } else if (
      divisionTypeId === AMNDivisionType.allied ||
      divisionTypeId === AMNDivisionType.intl ||
      divisionTypeId === AMNDivisionType.amnNurse ||
      divisionTypeId === AMNDivisionType.inDemand
    ) {
      return orderId === TRAVEL_ID;
    } else if (divisionTypeId === AMNDivisionType.localStaffing) {
      return orderId === LOCAL_ID;
    } else {
      return orderId === 'Unknown';
    }
  });

  return defaultTypeValue;
};

export const calculateRegionDefaultValue = (facilityData, _regions) => {
  let defaultTypeValue = null;
  const UNASSIGNED_ID = '9';
  const regionIdData = String(facilityData?.location?.regionId);
  const divisionTypeId = facilityData?.amnDivisionTypeId;

  if (divisionTypeId === AMNDivisionType.hsg) { // HSG (AMN Labor Disruption) always sets it to 'Unassigned'
    defaultTypeValue = _regions.find(region => region.ID === UNASSIGNED_ID);
  } else if (regionIdData !== '39') { // '39' is the regionId for 'AMN Allied'
    defaultTypeValue = _regions.find(region => region.ID === regionIdData);
  }
  return defaultTypeValue;
};

export const getFacilityHeaderAddressTemplate = (location): string => {
  if (location?.address1) {
    const addressComponents = [
      { value: location.address1, appendComma: !isStrNull(location.address2) },
      { value: location?.address2, appendComma: true },
      { value: location?.city, appendComma: true },
      { value: location?.state, appendComma: true },
      { value: location?.zipCode, appendComma: false },
    ];
    return addressComponents
      ?.map(({ value, appendComma }) => {
        const strValue = isStrNull(value);
        return strValue ? `${strValue}${appendComma ? ',' : ''}` : null;
      })
      .filter(Boolean)
      .join(' ');
  }
  return missingField;
};

export const getIfElementOfArrayPresentInAnotherArray = (dataArray, dataArrayFieldName: string, valueArray) => {
  return dataArray.map(item => item[dataArrayFieldName]).some(value => valueArray.includes(value));
};

export const getAdditionalInfo = (years, months) => {
  const yearText = dateAbbreviationText.Year.toLowerCase();
  const monthText = dateAbbreviationText.Month.toLowerCase();

  if ((years === null || years === '') && (months === null || months === '')) {
    return `--${yearText}`;
  } else if ((years === null || years === '' || (years < 1 && months !== 0)) && months !== null && months !== '') {
    return `${months}${monthText}`;
  } else if (years !== null && years !== '' && (months === null || months === '' || (months < 1 && years !== 0))) {
    return `${years}${yearText}`;
  } else {
    return `${years}${yearText} ${months}${monthText}`;
  }
};

export const transformUnitExperienceForStatusTile = (skillSetsData: ISkillSetInfo) => {
  let resultData: StatusTileProps = null;

  if (!_.isEmpty(skillSetsData)) {
    return {
      title: {
        text: Concatenate(
          [
            skillSetsData?.disciplineAbbr || skillSetsData?.discipline,
            skillSetsData?.specialty,
            skillSetsData?.subSpecialty,
          ],
          ' - ',
        ),
        showTooltip: true,
        tooltipText: Concatenate(
          [skillSetsData?.discipline, skillSetsData?.specialty, skillSetsData?.subSpecialty],
          ' - ',
        ),
      },
      status: {
        text: skillSetsData?.isRequired ? StatusTileText.Required : StatusTileText.Preferred,
        showTooltip: true,
        tooltipText: skillSetsData?.isRequired ? StatusTileDescription.Required : StatusTileDescription.Preferred,
      },
      showAdditionalInfo: true,
      additionalInfo: getAdditionalInfo(skillSetsData?.yearsOfExperience, skillSetsData?.monthsOfExperience),
      showAdditionalInfoTooltip: true,
      showIcon: false,
      icon: null,
    };
  }
  return resultData;
};

export const transformUnitSkillsetCollections = (
  type: SkillSetCollections,
  data: IRequirements[] = [],
  icon: ReactElement = null,
) => {
  const tranformedData =
    type === SkillSetCollections.Licenses
      ? transformSkillsetLicenses(data)
      : type === SkillSetCollections.Certifications
      ? transformSkillsetCertifications(data)
      : [];

  const sortedSkillset = sortBy(tranformedData, [item => (item.isRequired ? 0 : 1)]);

  const transformedSkillsetCollections: Array<StatusTileProps> = sortedSkillset?.map((item: any) => {
    return {
      id: item.id,
      title: {
        text: item.text ?? item.requirementAbbreviation,
        showTooltip: true,
        tooltipText: item.tooltipText ?? item.requirementDescription,
      },
      status: {
        text: item.isRequired ? StatusTileText.Required : StatusTileText.Preferred,
        showTooltip: true,
        tooltipText: item.isRequired ? StatusTileDescription.Required : StatusTileDescription.Preferred,
      },
      issuedBy: item?.issuedBy,
      showAdditionalInfo: false,
      additionalInfo: null,
      icon: item.documentAttachmentRequired ? icon : null,
      iconTooltip: {
        showTooltip: true,
        message: 'facility.units.experience.docRequired',
      },
    };
  });

  return transformedSkillsetCollections;
};

const transformSkillsetLicenses = (data: IRequirements[]) => {
  const transformedLicenses = data?.map(item => {
    return {
      ...item,
      [`text`]: `${item.requirementAbbreviation} ${
        item.isMultiState
          ? `- ${i18next.t('order.newOrderCreation.tabs.compact')}`
          : item.requirementState?.stateAbbreviation
          ? `- ${item.requirementState.stateAbbreviation}`
          : ''
      }`,
      [`tooltipText`]: `${item.requirementDescription} ${
        item.isMultiState
          ? `- ${i18next.t('order.newOrderCreation.tabs.compact')}`
          : item.requirementState?.state
          ? `- ${item.requirementState.state}`
          : ''
      }`,
    };
  });

  return transformedLicenses || [];
};

const transformSkillsetCertifications = (data: IRequirements[]) => {
  if (!data?.length) return [];

  const transformedCertifications = data?.map(item => {
    const isCert = item.requirementAbbreviation === 'CERT' && item.subCertificate?.subCertificationId;
    return {
      ...item,
      text: isCert
        ? `${item.requirementAbbreviation} - ${item.subCertificate?.subCertificateAbbreviation || ''}`
        : item.requirementDescription,
      tooltipText: isCert
        ? `${item.requirementAbbreviation} - ${item.subCertificate?.subCertificateName || ''}`
        : item.requirementDescription,
    };
  });

  return transformedCertifications;
};

export const transformNewOrderCreationStore = (storeData: ISkillSetGroup): ISkillSetGroup => {
  let result: ISkillSetGroup = { ...storeData };
  result.groups = result?.groups?.map(group => {
    let groupData: IGroup = {
      id: group.id,
      operation: group.operation,
      skillsets: [],
    };
    groupData.skillsets = group.skillsets?.map(skillSetData => {
      let res: ISkillSet = {
        skillSet: null,
        collections: [],
        id: skillSetData.id,
        isPrimary: skillSetData.isPrimary,
        travelExperience: skillSetData.travelExperience,
      };
      res.skillSet = tranformSkillSet(skillSetData.skillSet);
      res.collections = skillSetData.collections?.map((collection, collectionIndex) => {
        if (collectionIndex === CollectionType.CertificationsIndex) {
          collection = transFormCertificationData(collection);
        } else if (collectionIndex === CollectionType.LicensesIndex) {
          collection = transFormLicenceData(collection);
        }
        return collection;
      });
      return res;
    });
    return groupData;
  });
  return result;
};

function tranformSkillSet(skillSet: any): ISkillSetInfo {
  const transformedData: ISkillSetInfo = {
    discipline: skillSet?.discipline?.label,
    disciplineId: skillSet?.discipline?.object?.ID,
    disciplineAbbr: skillSet?.discipline?.object?.Abbreviation,
    specialtyId: skillSet?.specialty?.object?.SpecialtyID,
    specialty: skillSet?.specialty?.label,
    specialtyAbbr: skillSet?.specialty?.object?.Abbreviation,
    subSpecialtyId: skillSet?.subSpecialty?.object?.SubSpecialtyID,
    subSpecialty: skillSet?.subSpecialty?.label,
    subSpecialtyAbbr: skillSet?.subSpecialty?.object?.Abbreviation,
    yearsOfExperience: skillSet?.yearsOfExperience,
    monthsOfExperience: skillSet?.monthsOfExperience,
    isRequired: skillSet?.isRequired,
    isPrimary: skillSet?.isPrimary,
    isNewGrad: skillSet?.isNewGrad,
    isSkillsCheckList: skillSet?.isSkillsCheckList,
    isClinicalFellowship: skillSet?.isClinicalFellowship,
  };

  return transformedData;
}

function transFormCertificationData(collection: any): ICollection {
  const tranformedData: ICollection = {
    id: 1,
    type: SkillSetCollections.Certifications,
    operation: collection.operation,
    requirements: transFormCertificationRequirement(collection?.requirements),
  };

  return tranformedData;
}

function transFormLicenceData(collection: ICollection): ICollection {
  const tranformedData: ICollection = {
    id: 0,
    type: SkillSetCollections.Licenses,
    operation: collection.operation,
    requirements: collection?.requirements?.filter(x => x?.isMultiState !== null),
  };
  return tranformedData;
}

function transFormCertificationRequirement(requirements: any): IRequirements[] {
  const result: IRequirements[] = [];
  if (!requirements || !requirements.length) {
    return [];
  }
  requirements?.forEach(req => {
    const require: IRequirements = initializeRequirementType();
    require.documentAttachmentRequired = req.documentAttachmentRequired;
    require.isRequired = req.isRequired;
    require.issued = req.issued;
    require.issuedBy = req.issuedBy?.name;
    require.issuedById = req.issuedBy?.value;
    require.requirementAbbreviation = req?.certificates?.object?.abbreviation;
    require.requirementDescription = req?.certificates?.object?.certificationName;
    require.requirementTypeId = req?.certificates?.object?.requirementTypeID;
    if (req.subCertificate?.length) {
      req.subCertificate.forEach(sub => {
        const subState = { ...require };
        subState.documentAttachmentRequired = sub.documentAttachmentRequired;
        subState.isRequired = sub.isRequired;
        subState.subCertificate = {
          subCertificationId: sub.id,
          subCertificateAbbreviation: sub.value,
          subCertificateName: sub.name,
        };
        result.push(subState);
      });
    } else {
      require.subCertificate = {
        subCertificateAbbreviation: null,
        subCertificateName: null,
        subCertificationId: null,
      };
      result.push(require);
    }
  });
  return result;
}

function initializeRequirementType(): IRequirements {
  return {
    documentAttachmentRequired: false,
    id: 0,
    isMultiState: false,
    isRequired: false,
    issuedBy: null,
    issuedById: null,
    requirementAbbreviation: null,
    requirementDescription: null,
    requirementState: null,
    requirementTypeId: null,
    subCertificate: null,
    issued: null,
  };
}
export const getCollections = (collectionData: ICollection[], issuedBy) => {
  const licenses = collectionData?.find(collection => collection?.type === SkillSetCollections.Licenses);
  const certifications = collectionData?.find(collection => collection?.type === SkillSetCollections.Certifications);
  const formattedLicense = {
    id: licenses?.id,
    operation: licenses?.operation,
    requirements: licenses?.requirements,
    type: licenses?.type,
  };

  const formattedCertification = {
    id: certifications?.id,
    operation: certifications?.operation,
    requirements: certifications?.requirements?.map((requirement, index) => {
      return {
        id: index,
        certificates: {
          label: requirement?.requirementDescription
            ? `${requirement?.requirementDescription}`
            : `${requirement?.requirementAbbreviation}`,
          object: {
            abbreviation: requirement?.requirementAbbreviation,
            category: SkillSetCollections.Certifications,
            certificationName: requirement?.requirementDescription
              ? `${requirement?.requirementDescription}`
              : `${requirement?.requirementAbbreviation}`,
            credentialName: requirement?.requirementDescription,
            id: requirement?.id,
            requirementTypeID: requirement?.requirementTypeId,
          },
        },
        issuedBy: getIssuedBy(requirement?.requirementTypeId, requirement?.issuedById, issuedBy),
        subCertificate:
          requirement?.requirementTypeId === RequirementType.cert && requirement?.subCertificate?.subCertificationId
            ? [
                {
                  subCertificate: {
                    name: requirement?.subCertificate?.subCertificateName,
                    value: requirement?.subCertificate?.subCertificateAbbreviation,
                    id: requirement?.subCertificate?.subCertificationId,
                  },
                },
              ]
            : null,
        documentAttachmentRequired: requirement?.documentAttachmentRequired,
        isRequired: requirement?.isRequired,
      };
    }),
    type: certifications?.type,
  };
  return [licenses ? formattedLicense : {}, certifications ? formattedCertification : {}];
};

export const getDefaultSkillSetForEdit = (data, issuedBy) => {
  const formattedValue = {
    operation: data?.operation,
    groups: data?.groups?.map(group => {
      return {
        id: group.id,
        operation: group.operation,
        skillsets: group.skillsets?.map(skill => {
          return {
            collections: getCollections(skill?.collections, issuedBy),
            id: skill?.id,
            isPrimary: skill?.isPrimary,
            skillSet: {
              discipline: skill?.skillSet?.discipline
                ? {
                    label: skill?.skillSet?.discipline,
                    object: {
                      Abbreviation: skill?.skillSet?.disciplineAbbr,
                      Description: skill?.skillSet?.discipline,
                      ID: skill?.skillSet?.disciplineId,
                    },
                  }
                : null,
              specialty: skill?.skillSet?.specialty
                ? {
                    label: skill?.skillSet?.specialty,
                    object: {
                      DisciplineID: skill?.skillSet?.disciplineId,
                      Description: skill?.skillSet?.specialtyAbbr,
                      SpecialtyID: skill?.skillSet?.specialtyId,
                    },
                  }
                : null,
              subSpecialty: skill?.skillSet?.subSpecialty
                ? {
                    label: skill?.skillSet?.subSpecialty,
                    object: {
                      Description: skill?.skillSet?.subSpecialty,
                      SpecialtyID: skill?.skillSet?.specialtyId,
                      SubSpecialtyID: skill?.skillSet?.subSpecialtyId,
                    },
                  }
                : null,
              yearsOfExperience: skill?.skillSet?.yearsOfExperience,
              monthsOfExperience: skill?.skillSet?.monthsOfExperience,
              isRequired: skill?.skillSet?.isRequired,
              isPrimary: skill?.isPrimary,
              isNewGrad: skill?.skillSet?.isNewGrad,
              isSkillsCheckList: skill?.skillSet?.isSkillsCheckList ?? false,
              isClinicalFellowship: skill?.skillSet?.isClinicalFellowship ?? false,
            },

            travelExperience: {
              experienceType: skill.travelExperience?.experienceTypeText
                ? {
                    groupBy: !isNil(skill.travelExperience?.numberOfAssignments)
                      ? TravelExperienceSections.NoOfAssignments
                      : TravelExperienceSections.YearsOfExperience,
                    label: skill.travelExperience.experienceTypeText,
                    object: {
                      title: skill.travelExperience.experienceTypeText,
                      optionId: skill.travelExperience.experienceTypeId,
                      id: `${!isNil(skill.travelExperience?.numberOfAssignments) ? 1 : 2}-${
                        skill.travelExperience.experienceTypeId
                      }`,
                    },
                  }
                : null,
              isRequired: skill.travelExperience?.isRequired,
              numberOfAssignments: skill.travelExperience?.numberOfAssignments,
              yearsOfExperience: skill.travelExperience?.yearsOfExperience,
              monthsOfExperience: skill.travelExperience?.monthsOfExperience,
              withinTwoYears: skill.travelExperience?.withinTwoYears,
              isAMNTravel: skill.travelExperience?.isAMNTravel,
            },
          };
        }),
      };
    }),
  };
  return formattedValue;
};

export const addCertificateHelper = (data: ICetificate[], append) => {
  data?.map((each, index) => {
    append({
      id: index,
      documentAttachmentRequired: each.documentAttachmentRequired,
      isRequired: each.isRequired,
      certificates: each.certificates,
      subCertificate: each.subCertificate,
      issuedById: each.issuedById,
    });
  });
};

export function formatDate(date) {
  if (!date && !moment.isDate(date)) {
    return missingField;
  }
  const d = new Date(date);
  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const day = String(d.getDate()).padStart(2, '0');
  return `${month}/${day}/${year}`;
}

export const transformUnitExperienceForTravelTile = (travelExpData: ITravelExperience) => {
  let resultData: StatusTileProps = null;

  if (
    !isNil(travelExpData?.experienceTypeId) &&
    !isNil(travelExpData?.experienceTypeText) &&
    (!isNil(travelExpData?.numberOfAssignments) ||
      !isNil(travelExpData?.yearsOfExperience) ||
      !isNil(travelExpData?.monthsOfExperience))
  ) {
    return {
      title: {
        text: travelExpData.experienceTypeText,
        showTooltip: true,
        tooltipText: travelExpData.experienceTypeText,
      },
      status: {
        text: travelExpData.isRequired ? StatusTileText.Required : StatusTileText.Preferred,
        showTooltip: true,
        tooltipText: travelExpData.isRequired ? StatusTileDescription.Required : StatusTileDescription.Preferred,
      },
      showAdditionalInfo: true,
      additionalInfo:
        travelExpData?.experienceTypeGroupId === 1
          ? !isNil(travelExpData?.numberOfAssignments)
            ? `${travelExpData?.numberOfAssignments ?? missingField} ${
                travelExpData?.numberOfAssignments && travelExpData?.numberOfAssignments === 1
                  ? 'assignment'
                  : 'assignments'
              }`
            : ``
          : !isNil(travelExpData?.yearsOfExperience) || !isNil(travelExpData?.monthsOfExperience)
          ? getAdditionalInfo(travelExpData?.yearsOfExperience, travelExpData?.monthsOfExperience)
          : ``,
      showAdditionalInfoTooltip: true,
      showIcon: false,
      icon: null,
    };
  }
  return resultData;
};
export const transformSkillSetGroupData = data => {
  const formattedValue = {
    operation: data?.operation ? data.operation : null,
    groups: data?.groups?.map((group, index) => {
      return {
        id: group?.id ? group?.id : index,
        operation: group?.operation ? group.operation : null,
        skillsets: group?.skillsets?.map((skill, index) => {
          return {
            collections:
              skill?.collections
                ?.map((collection, index) => {
                  return collection?.requirements?.length
                    ? collection.requirements[0]?.hasOwnProperty('certificates')
                      ? {
                          id: index,
                          operation: collection?.operation ?? null,
                          requirements: collection?.requirements?.filter(req => req?.certificates !== null),
                          type: SkillSetCollections.Certifications,
                        }
                      : {
                          id: 0,
                          type: SkillSetCollections.Licenses,
                          operation: collection?.operation ? collection.operation : null,
                          requirements: getTransformedLicenses(collection?.requirements),
                        }
                    : null;
                })
                .filter(Boolean) || [],
            id: skill?.id ? skill?.id : index,
            isPrimary: group?.skillsets?.length === 1 ? true : skill?.isPrimary,
            skillSet: skill?.skillSet,
            travelExperience: skill?.travelExperience,
          };
        }),
      };
    }),
  };
  formattedValue?.groups?.forEach(group => {
    group?.skillsets?.forEach(skillSet => {
      if (skillSet?.collections?.length === 1) {
        if (skillSet.collections[0].type === SkillSetCollections.Certifications) {
          skillSet.collections = [
            {
              type: SkillSetCollections.Licenses,
              requirements: [],
              operation: null,
            },
            skillSet.collections[0],
          ];
        }
      }
    });
  });
  return formattedValue;
};

export const transformSkillSetGroupsDataWithoutLicense = data => {
  const formattedValue = {
    operation: data?.operation ? data.operation : null,
    groups: data?.groups?.map((group, index) => {
      return {
        id: group?.id ? group?.id : index,
        operation: group?.operation ? group.operation : null,
        skillsets: group?.skillsets?.map((skill, index) => {
          return {
            collections:
              skill?.collections
                ?.map((collection, index) => {
                  return collection?.requirements?.length
                    ? collection.requirements[0]?.hasOwnProperty('certificates')
                      ? collection.requirements[0]?.certificates
                        ? {
                            id: index,
                            operation: collection?.operation ?? null,
                            requirements: getSeperatedCertSubCertItems(collection),
                            type: SkillSetCollections.Certifications,
                          }
                        : null
                      : {
                          id: 0,
                          type: SkillSetCollections.Licenses,
                          operation: collection?.operation ? collection.operation : null,
                          requirements: collection?.requirements,
                        }
                    : null;
                })
                .filter(Boolean) || [],
            id: skill?.id ? skill?.id : index,
            isPrimary: skill?.isPrimary,
            skillSet: {
              disciplineId: skill?.skillSet?.discipline?.object?.ID,
              discipline: skill?.skillSet?.discipline?.object?.Description,
              disciplineAbbr: skill?.skillSet?.discipline?.object?.Abbreviation,

              specialtyId: skill?.skillSet?.specialty?.object?.SpecialtyID,
              specialty: skill?.skillSet?.specialty?.label,
              specialtyAbbr: skill?.skillSet?.specialty?.object?.Description,

              subSpecialtyId: skill?.skillSet?.subSpecialty?.object?.SubSpecialtyID,
              subSpecialty: skill?.skillSet?.subSpecialty?.object?.Description,
              subSpecialtyAbbr: skill?.skillSet?.subSpecialty?.object?.Description,

              yearsOfExperience: skill?.skillSet?.yearsOfExperience === '' ? null : skill?.skillSet?.yearsOfExperience,
              monthsOfExperience:
                skill?.skillSet?.monthsOfExperience === '' ? null : skill?.skillSet?.monthsOfExperience,
              isRequired: skill?.skillSet?.isRequired,
              isNewGrad: skill?.skillSet?.isNewGrad,

              isSkillsCheckList: skill?.skillSet?.isSkillsCheckList ?? false,
              isClinicalFellowship: skill?.skillSet?.isClinicalFellowship ?? false,
            },
            travelExperience: {
              experienceTypeId: skill?.travelExperience?.experienceType?.object?.optionId,
              experienceTypeText: skill?.travelExperience?.experienceType?.object?.title,
              numberOfAssignments: skill?.travelExperience?.numberOfAssignments,
              yearsOfExperience: skill?.travelExperience?.yearsOfExperience,
              monthsOfExperience: skill?.travelExperience?.monthsOfExperience,
              isRequired: skill?.travelExperience?.isRequired,
              withinTwoYears: skill?.travelExperience?.withinTwoYears,
              isAMNTravel: skill?.travelExperience?.isAMNTravel,
              experienceType: skill?.travelExperience?.experienceType,
              experienceTypeGroupId:
                skill?.travelExperience?.experienceType?.groupBy === TravelExperienceSections.NoOfAssignments
                  ? 1
                  : skill?.travelExperience?.experienceType?.groupBy === TravelExperienceSections.YearsOfExperience
                  ? 2
                  : null,
            },
          };
        }),
      };
    }),
  };
  return formattedValue;
};

const removeLeadingOperator = (str, operator) => {
  if (str?.startsWith(operator)) {
    str = str.slice(operator.length);
  }
  return str;
};

const removeTralingOperator = (str, operator) => {
  if (str?.endsWith(operator)) {
    str = str.slice(0, -operator.length);
  }
  return str;
};

const formatData = obj => {
  const result = [];
  for (const key in obj) {
    if (obj[key] && obj[key].isSelected && obj[key].selectedValue) {
      const value = obj[key].isSelected ? (obj[key].selectedValue ? obj[key].selectedValue : '--') : '--';
      result.push(`${capitalizeKey(key)}: ${value}`);
    }
  }
  if (obj?.scrubColors) {
    result.push(`Scrub Colors: ${obj?.scrubColors}`);
  }
  return result?.join('\n');
};

function capitalizeKey(key) {
  return key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
}

const getAdditionalInformation = addInfo => {
  return formatData(addInfo);
};

const getWorkHistoryInYears = workHistory => {
  const workDurationString =
    workHistory && workHistory?.entireWorkHistory
      ? 'entire work history'
      : workHistory && workHistory?.workHistoryGapInYears !== null && workHistory?.workHistoryGapInYears !== undefined
      ? `last ${!isNaN(workHistory?.workHistoryGapInYears) ? workHistory?.workHistoryGapInYears : 0} ${
          workHistory?.workHistoryGapInYears > 1 ? 'years' : 'year'
        }`
      : '';
  const workHistoryString =
    workHistory && workHistory?.workHistoryGapInDays !== null && workHistory?.workHistoryGapInDays !== undefined
      ? `Should not be more than ${workHistory?.workHistoryGapInDays} ${
          workHistory?.workHistoryGapInDays > 1 ? 'days' : 'day'
        } of gap in ${workDurationString}`
      : '';
  return workHistoryString?.length > 0 && workHistory && workHistory?.workHistoryGapInDays >= 0
    ? `Work History: ${workHistoryString}`
    : null;
};
// Helper function to add markers
function addMarker(value: string, marker: string="MARKER"): string {
  return `__${marker}__${value}__${marker}__`;
}

export const getQualificationStringExperience = (
  orderFreeTextData,
  data,
  selectedUnitSkillsets,
  additionalInformation,
) => {
  let requiredQualification = '';
  let preferredQualification = '';
  let licenses = '';
  let preferredLicense = '';
  let preferredCertificates = '';
  let certificates = '';
  let concatenateOperator;
  const andMarker = addMarker('AND');
  const orMarker = addMarker('OR');

  //Retains unit data in preferred container
  selectedUnitSkillsets?.groups?.forEach(group => {
    group.skillsets?.forEach((skill, skillIndex, skillArray) => {
      if (group.operation === AndOrOperator.And) {
        concatenateOperator = `\n${andMarker}`;
      } else {
        concatenateOperator = `\n${orMarker}`;
      }

      let isPreferred =
        (!skill?.skillSet?.isRequired && !skill?.isPrimary) ||
        (!skill?.travelExperience?.isRequired && skill?.travelExperience?.experienceType !== null) ||
        skill?.collections?.some(collection => {
          if (collection?.type === SkillSetCollections.Licenses) {
            return collection?.requirements?.some(req => !req?.isRequired);
          }
          if (collection?.type === SkillSetCollections.Certifications) {
            let requiredSubCertificate = false;
            let requiredCertificate = collection?.requirements?.some(req => !req?.isRequired);

            requiredSubCertificate = collection.requirements?.subCertificate?.some(sub => !sub?.isRequired);

            return requiredCertificate || requiredSubCertificate;
          }
          return false;
        });

      //Preferred skillsets
      if (isPreferred) {
        preferredQualification = Concatenate([preferredQualification, getSkillset(skill)], '\n');
        if (!(skill?.skillSet?.isRequired || skill?.isPrimary)) {
          preferredQualification = Concatenate([preferredQualification, getExperience(skill)], '\n');
        }
      }

      //Preferred travel experience
      if (!skill?.travelExperience?.isRequired) {
        preferredQualification = Concatenate([preferredQualification, getTravelExperienceText(skill)], '\n');
      }

      //Preferred license and certification
      skill?.collections?.forEach(collection => {
        if (collection?.type === SkillSetCollections.Licenses) {
          collection?.requirements?.forEach(req => {
            if (!req?.isRequired) {
              preferredLicense = getLicensesText(preferredLicense, req, collection);
            }
          });
          if (collection?.operation && preferredLicense) {
            preferredLicense =
              'Licenses' +
              (collection.operation === '|' ? '(At least one of the following): ' : ': ') +
              preferredLicense;
          }
          preferredQualification = Concatenate([preferredQualification, preferredLicense], '\n');
        }
        if (collection?.type === SkillSetCollections.Certifications) {
          collection?.requirements?.forEach(req => {
            if (req?.subCertificate?.length === 0 || req?.subCertificate?.length === undefined) {
              if (!req?.isRequired) {
                preferredCertificates = getCertificateTexts(preferredCertificates, req, collection);
              }
            } else {
              req?.subCertificate?.forEach(subCert => {
                if (!req?.isRequired) {
                  preferredCertificates = getSubCertificateTexts(preferredCertificates, subCert, req, collection);
                }
              });
            }
          });

          if (preferredCertificates) {
            preferredCertificates =
              'Certifications' +
              (collection?.operation === '|' ? '(At least one of the following): ' : ': ') +
              preferredCertificates;
          }
          preferredQualification = Concatenate([preferredQualification, preferredCertificates], '\n');
        }
        preferredLicense = '';
        preferredCertificates = '';
      });

      if (skillIndex < skillArray.length - 1) {
        requiredQualification = Concatenate([requiredQualification, concatenateOperator]);
        preferredQualification = Concatenate([preferredQualification, concatenateOperator]);
      }
    });
  });

  //Order preferred and required strings
  data?.groups?.forEach(group => {
    group.skillsets?.forEach((skill, skillIndex, skillArray) => {
      if (group.operation === AndOrOperator.And) {
        concatenateOperator =`\n${andMarker}`;
      } else {
        concatenateOperator = `\n${orMarker}`;
      }

      let isRequired =
        skill?.skillSet?.isRequired ||
        skill?.isPrimary ||
        skill?.travelExperience?.isRequired ||
        skill?.collections?.some(collection => {
          if (collection?.type === SkillSetCollections.Licenses) {
            return collection?.requirements?.some(req => req?.isRequired);
          }
          if (collection?.type === SkillSetCollections.Certifications) {
            let requiredCertificate = collection?.requirements?.some(req => req?.isRequired);
            let requiredSubCertificate = collection.requirements?.subCertificate?.some(sub => sub?.isRequired);
            return requiredCertificate || requiredSubCertificate;
          }
          return false;
        });

      //Required skillsets
      if (isRequired) {
        requiredQualification = Concatenate([requiredQualification, getSkillset(skill)], '\n');
        if (skill?.skillSet?.isRequired || skill?.isPrimary) {
          requiredQualification = Concatenate([requiredQualification, getExperience(skill)], '\n');
        }
      }

      //Required travel experience
      if (skill?.travelExperience?.isRequired) {
        requiredQualification = Concatenate([requiredQualification, getTravelExperienceText(skill)], '\n');
      }

      //Required license and certifications
      skill?.collections?.forEach(collection => {
        if (collection?.type === SkillSetCollections.Licenses) {
          collection?.requirements?.forEach(req => {
            if (req?.isRequired) {
              licenses = getLicensesText(licenses, req, collection);
            }
          });
          if (collection?.operation && licenses) {
            licenses =
              'Licenses' + (collection.operation === '|' ? '(At least one of the following): ' : ': ') + licenses;
          }
          requiredQualification = Concatenate([requiredQualification, licenses], '\n');
        }
        if (collection?.type === SkillSetCollections.Certifications) {
          collection?.requirements?.forEach(req => {
            if (req?.subCertificate?.length === 0 || req?.subCertificate?.length === undefined) {
              if (req?.isRequired) {
                certificates = getCertificateTexts(certificates, req, collection);
              }
            } else {
              req?.subCertificate?.forEach(subCert => {
                certificates = getSubCertificateTexts(certificates, subCert, req, collection);
              });
            }
          });

          if (certificates) {
            certificates =
              'Certifications' +
              (collection?.operation === '|' ? '(At least one of the following): ' : ': ') +
              certificates;
          }
          requiredQualification = Concatenate([requiredQualification, certificates], '\n');
        }
        licenses = '';
        certificates = '';
      });

      if (skillIndex < skillArray.length - 1) {
        requiredQualification = Concatenate([requiredQualification, concatenateOperator], '');
        preferredQualification = Concatenate([preferredQualification, concatenateOperator], '');
      }
    });
  });
  //Additional Information

  requiredQualification = Concatenate([requiredQualification, getAdditionalInformation(additionalInformation)], '\n');

  // Split the required and preferred qualifications into arrays
  const requiredQualificationArray = requiredQualification?.split('\n');
  const preferredQualificationArray = preferredQualification?.split('\n');

  // Remove the 'AND,' or 'OR,' from each element and trim any leading/trailing whitespace

  const pattern = new RegExp(`${andMarker},|${orMarker},`, 'g');
  const requiredQualificationModifiedArray = requiredQualificationArray?.map(element =>
    element?.replace(pattern, '').trim(),
  );
  const preferredQualificationModifiedArray = preferredQualificationArray?.map(element =>
    element?.replace(pattern, '').trim(),
  );

  // Filter out any empty strings and join the elements back into a string
  const requiredQualificationString = requiredQualificationModifiedArray?.filter(str => str !== '').join('\n');
  const preferredQualificationString = preferredQualificationModifiedArray?.filter(str => str !== '').join('\n');

  if (concatenateOperator === `\n${andMarker}`) {
    const requiredQualifications = removeLeadingOperator(requiredQualificationString, `${andMarker}\n`);
    requiredQualification = removeTralingOperator(requiredQualifications, `\n${andMarker}`);
    requiredQualification = removeTralingOperator(requiredQualification, `${andMarker}`);

    const preferredQualifications = removeLeadingOperator(preferredQualificationString, `${andMarker}\n`);
    preferredQualification = removeTralingOperator(preferredQualifications, `\n${andMarker}`);
    preferredQualification = removeTralingOperator(preferredQualification, `${andMarker}`);
  }

  if (concatenateOperator === `\n${orMarker}`) {
    const requiredQualifications = removeLeadingOperator(requiredQualificationString, `${orMarker}\n`);
    requiredQualification = removeTralingOperator(requiredQualifications, `\n${orMarker}`);
    requiredQualification = removeTralingOperator(requiredQualification, `${orMarker}`);
    requiredQualification = removeLeadingOperator(requiredQualification,`${orMarker}`);
    requiredQualification = removeLeadingOperator(requiredQualification, `${andMarker}`);
    const preferredQualifications = removeLeadingOperator(preferredQualificationString, `${orMarker}\n`);
    preferredQualification = removeTralingOperator(preferredQualifications, `\n${orMarker}`);
    preferredQualification = removeTralingOperator(preferredQualification, `${orMarker}`);
    preferredQualification = removeLeadingOperator(preferredQualification, `${orMarker}`); 
    preferredQualification = removeLeadingOperator(preferredQualification, `${andMarker}`);
  }

  if (preferredQualification?.length > 0 && orderFreeTextData?.preferredQualifications?.length > 0) {
    Concatenate([preferredQualification, orderFreeTextData?.preferredQualifications], '\n');
  } else if (orderFreeTextData?.preferredQualifications?.length > 0) {
    preferredQualification = orderFreeTextData?.preferredQualifications;
  } else {
    preferredQualification = '';
  }
  requiredQualification =requiredQualification.replace(new RegExp(andMarker, "g"), "AND").replace(new RegExp(orMarker, "g"), "OR")
  preferredQualification =preferredQualification.replace(new RegExp(andMarker, "g"), "AND").replace(new RegExp(orMarker, "g"), "OR")

  return [requiredQualification, preferredQualification];
};

export function getAdditionalQualificationString(
  ssn?: boolean,
  dob?: boolean,
  workHistory?: OrderWorkHistory,
  references?: Reference,
) {
  const ssnString = ssn ? 'SSN Required' : null;
  const dobString = dob ? 'DOB Required' : null;
  const referenceString =
    references?.numberOfReferenceToRFO > 0
      ? references &&
        references?.numberOfReferenceToRFO &&
        !references?.withinMonths &&
        references?.withinNumberOfMonths
        ? `References: ${references?.numberOfReferenceToRFO} ${
            references?.numberOfReferenceToRFO > 1 ? 'References' : 'Reference'
          } in the last ${references?.withinNumberOfMonths} ${
            parseInt(references?.withinNumberOfMonths.toString()) === 1 ? 'month' : 'months'
          }`
        : `References: ${references?.numberOfReferenceToRFO} ${
            references?.numberOfReferenceToRFO > 1
              ? 'References in entire work history'
              : 'Reference in entire work history'
          } `
      : ``;
  return Concatenate([ssnString, dobString, getWorkHistoryInYears(workHistory), referenceString], '\n').trimEnd();
}

const getTravelExperienceLabel = (assignment, skill) => {
  switch (assignment) {
    case 'Discipline': {
      return skill?.skillSet?.discipline?.label;
    }
    case 'Specialty': {
      return `${skill?.skillSet?.discipline?.label}, ${skill?.skillSet?.specialty?.label}`;
    }
    default: {
      return `General`;
    }
  }
};

const getTravelExperienceText = skill => {
  let travelExperience = '';
  const experienceTypeText = getTravelExperienceLabel(skill.travelExperience?.experienceType?.label, skill);
  if (skill.travelExperience && experienceTypeText) {
    let withinTwoYears = skill.travelExperience?.withinTwoYears ? 'Within 2 Years' : null;
    let amnTravel = skill.travelExperience?.isAMNTravel ? 'AMN Travel' : null;
    travelExperience =
      !isNaN(skill.travelExperience?.numberOfAssignments) && skill.travelExperience?.numberOfAssignments !== null
        ? skill.travelExperience.numberOfAssignments > 0
          ? `Travel Experience: ${skill.travelExperience.numberOfAssignments ?? 0} ${
              skill.travelExperience.numberOfAssignments > 1 ? 'assignments' : 'assignment'
            } in ${experienceTypeText}`
          : ``
        : skill.travelExperience?.yearsOfExperience || skill.travelExperience?.monthsOfExperience
        ? `Travel Experience: ${dateAndMonthConcat(
            skill?.travelExperience?.yearsOfExperience,
            skill?.travelExperience?.monthsOfExperience,
          )} ${
            skill?.travelExperience?.experienceType?.label === 'General' &&
            skill?.travelExperience?.experienceType?.groupBy === 'YEARS OF EXPERIENCE'
              ? ''
              : ' in ' + experienceTypeText
          }`
        : ``;
    travelExperience =
      travelExperience.length > 0 && (withinTwoYears || amnTravel)
        ? Concatenate([travelExperience, withinTwoYears, amnTravel], ', ')
        : travelExperience;
  }
  return travelExperience;
};

export const dateAndMonthConcat = (years, months) => {
  const yearText = years > 1 ? 'years' : 'year';
  const monthText = months > 1 ? 'months' : 'month';

  if ((!years && !months) || (years < 1 && months < 1)) {
    return ``;
  } else if ((!years || years < 1) && months) {
    return `${months} ${monthText}`;
  } else if (years && (!months || months < 1)) {
    return `${years} ${yearText}`;
  } else {
    return `${years} ${yearText} ${months} ${monthText}`;
  }
};

const getSkillset = skill => {
  let skillset = '';
  skillset = Concatenate([skill.skillSet?.discipline?.label, skill?.skillSet?.specialty?.label, skill.skillSet?.subSpecialty?.label], ', ');
  return skillset;
};

const getExperience = skill => {
  let experienceText = dateAndMonthConcat(skill?.skillSet?.yearsOfExperience, skill?.skillSet?.monthsOfExperience);

  const experienceDescription = `${
    experienceText || skill?.skillSet?.isNewGrad || skill?.skillSet?.isClinicalFellowship ? 'Experience: ' : ''
  }${experienceText} ${skill?.skillSet?.isNewGrad ? `${experienceText ? '(' : ''} New Grad Accepted` : ''}${
    skill?.skillSet?.isClinicalFellowship
      ? `${skill?.skillSet?.isNewGrad ? ',' : experienceText ? '(' : ''}CF SLP - Clinical Fellowship Accepted ${
          experienceText ? ')' : ''
        }`
      : skill?.skillSet?.isNewGrad && experienceText
      ? ')'
      : ''
  }`;

  return experienceDescription;
};

// To create strings out of licenses.
function getLicensesText(licenses: string, req: any, collection: any) {
  if (req) {
    const licenseText = `${req.requirementAbbreviation}${
      !req.isMultiState ? '-' + req.requirementState.stateAbbreviation : '(Compact Accepted)'
    }${req.documentAttachmentRequired ? '(Copy needed)' : ''}`;

    licenses = Concatenate([licenses, licenseText], collection?.operation === '|' ? ', ' : '; ');
  }
  return licenses;
}

// To create strings out of certificates.
function getCertificateTexts(certificates: string, req, collection: any) {
  const certificationTexts = `${req.certificates?.object?.abbreviation}${
    req?.issuedBy ? '-' + req.issuedBy?.label : ''
  }${req?.documentAttachmentRequired ? '(Copy needed)' : ''}`;

  certificates = Concatenate([certificates, certificationTexts], collection?.operation === '|' ? ', ' : '; ');

  return certificates;
}

// To create strings out of sub-certificates.
function getSubCertificateTexts(subCertificates, subCert, req: any, collection) {
  const subCertificationTexts = `${req.certificates?.object.abbreviation}${
    subCert.subCertificate?.name ? '-' + subCert.subCertificate?.name : ''
  }${req?.documentAttachmentRequired ? '(Copy needed)' : ''}`;

  const concatenatedSubCertificates = Concatenate(
    [subCertificates, subCertificationTexts],
    collection?.operation === '|' ? ', ' : '; ',
  );

  return concatenatedSubCertificates;
}

export const getSkillSetReorderPrimarySkill = (data, issuedBy) => {
  const formattedValue = {
    operation: data?.operation,
    groups: data?.groups?.map(group => {
      const skillsets = group.skillsets?.map(skill => {
        return {
          collections: getCollections(skill?.collections, issuedBy),
          id: skill?.id,
          isPrimary: skill?.isPrimary,
          skillSet: {
            discipline: skill?.skillSet?.discipline
              ? {
                  label: skill?.skillSet?.discipline,
                  object: {
                    Abbreviation: skill?.skillSet?.disciplineAbbr,
                    Description: skill?.skillSet?.discipline,
                    ID: skill?.skillSet?.disciplineId,
                  },
                }
              : null,
            specialty: skill?.skillSet?.specialty
              ? {
                  label: skill?.skillSet?.specialty,
                  object: {
                    DisciplineID: skill?.skillSet?.disciplineId,
                    Description: skill?.skillSet?.specialtyAbbr,
                    SpecialtyID: skill?.skillSet?.specialtyId,
                  },
                }
              : null,
            subSpecialty: skill?.skillSet?.subSpecialty
              ? {
                  label: skill?.skillSet?.subSpecialty,
                  object: {
                    Description: skill?.skillSet?.subSpecialty,
                    SpecialtyID: skill?.skillSet?.specialtyId,
                    SubSpecialtyID: skill?.skillSet?.subSpecialtyId,
                  },
                }
              : null,
            yearsOfExperience: skill?.skillSet?.yearsOfExperience,
            monthsOfExperience: skill?.skillSet?.monthsOfExperience,
            isRequired: skill?.skillSet?.isRequired,
            isPrimary: skill?.isPrimary,
            isNewGrad: skill?.skillSet?.isNewGrad,
            isSkillsCheckList: skill?.skillSet?.isSkillsCheckList ?? false,
            isClinicalFellowship: skill?.skillSet?.isClinicalFellowship ?? false,
          },

          travelExperience: {
            experienceType: skill.travelExperience?.experienceTypeText
              ? {
                  groupBy:
                    skill?.travelExperience?.experienceTypeGroupId === 1
                      ? TravelExperienceSections.NoOfAssignments
                      : TravelExperienceSections.YearsOfExperience,
                  label: skill.travelExperience.experienceTypeText,
                  object: {
                    title: skill.travelExperience.experienceTypeText,
                    optionId: skill.travelExperience.experienceTypeId,
                    id: `${skill?.travelExperience?.experienceTypeGroupId === 1 ? 1 : 2}-${
                      skill.travelExperience.experienceTypeId
                    }`,
                  },
                }
              : null,
            isRequired: skill.travelExperience?.isRequired,
            numberOfAssignments: skill.travelExperience?.numberOfAssignments,
            yearsOfExperience: skill.travelExperience?.yearsOfExperience,
            monthsOfExperience: skill.travelExperience?.monthsOfExperience,
            withinTwoYears: skill.travelExperience?.withinTwoYears,
            isAMNTravel: skill.travelExperience?.isAMNTravel,
          },
        };
      });

      // Reorder skillsets array so that the skillset with isPrimary true is at index 0
      if (skillsets) {
        const primarySkillIndex = skillsets?.findIndex(skill => skill.isPrimary === true);
        if (primarySkillIndex !== -1) {
          const primarySkill = skillsets.splice(primarySkillIndex, 1)[0];
          skillsets.unshift(primarySkill);
        }
      }

      return {
        id: group.id,
        operation: group.operation,
        skillsets: skillsets,
      };
    }),
  };
  return formattedValue;
};

export function updateQualificationStrings(
  editPayload: IOrderDetails,
  skillSetGroup: ISkillSetGroup,
  additionalQualification: string,
  selectedSkillSetDataUnit,
) {
  const orderFreeTexts: IOrderFreeText = {
    orderId: editPayload?.orderId,
    additionalRequiredQualifications: editPayload?.additionalRequiredQualifications,
    internalNotes: editPayload?.internalNotes,
    jobDescription: editPayload?.jobDescription,
    preferredQualifications: editPayload?.preferredQualifications,
    requiredQualifications: editPayload?.requiredQualifications,
  };
  let [requiredQualification, preferredQualification] = getQualificationStringExperience(
    orderFreeTexts,
    skillSetGroup,
    selectedSkillSetDataUnit,
    editPayload?.additionalInformation,
  );
  requiredQualification = Concatenate(
    [
      requiredQualification,
      getAdditionalQualificationString(
        editPayload.ssnRequired,
        editPayload.dobRequired,
        editPayload.orderWorkHistory,
        editPayload.reference,
      ),
      additionalQualification?.length > 0 ? `\n${additionalQualification}` : '',
    ],
    '\n',
  );
  editPayload.requiredQualifications = requiredQualification;
}

export function checkIsValidDecimal(value: string) {
  return /^(\$)?(\d+(\.\d{0,2})?)?$/.test(value) && (value === '' || parseFloat(value) <= 999999.99);
}

export function getPositiveIntegerValue(value: string) {
  const number = parseInt(value, 10);
  if (isNaN(number) || number < 0 || number === null) {
    return null;
  } else {
    return number.toString();
  }
}
export function getPositiveIntegerValueExceptZero(value: string) {
  const number = parseInt(value, 10);
  if (isNaN(number) || number <= 0 || number === null) {
    return null;
  } else {
    return number.toString();
  }
}

export function modifyValues(value: string) {
  // Transforming Values based on condition
  if (/^(\d+)\s*-\s*(\d+)$/.test(value)) {
    return value;
  } else if (/^\d+$/.test(value)) {
    return value;
  } else if (/^-$/.test(value)) {
    return '';
  } else if (/^-.*|.*-$/.test(value)) {
    return value.match(/\d+/)[0];
  } else {
    return '';
  }
}

export const validateInputValue = value => {
  //Ensuring that input field will only accept a combination of number and hypen
  return /^(\d+(-\d+)?|-|\d+-|-\d+)$/.test(value) ? value : '';
};

export function getValueForNumberField(value: string) {
  const number = parseInt(value, 10);
  if (isNaN(number) || number < 0) {
    return null;
  } else {
    return number.toString();
  }
}

export function getValueForWithinLastYear(number: number) {
  if (isNaN(number) || number <= 0) {
    return null;
  } else {
    return number.toString();
  }
}
//Get length of the skillsets
export const getSkillSetsLength = currentFormData => {
  const skillSetsLength = currentFormData?.skillSetGroup?.groups?.flatMap(group => group?.skillsets || []).length;

  return skillSetsLength > 1;
};
//Get get operation 
export const getOperation = currentFormData => {
  const skillSetOpeartion = currentFormData?.skillSetGroup?.groups[0]?.operation;
  return `${skillSetOpeartion}` === AndOrOperator.And ;
};

//Get skillsets with Primary equal to true
export const getPrimarySkillSets = data => {
  const formattedValue = {
    operation: data?.operation ? data.operation : null,
    groups: data?.groups?.map((group, index) => {
      return {
        id: group?.id ? group.id : index,
        operation: group?.operation ? group.operation : null,
        skillsets: group?.skillsets
          ?.filter(skill => skill?.isPrimary) // Filter out skillsets where isPrimary is false
          .map((skill, index) => {
            return {
              collections:
                skill?.collections
                  ?.map((collection, index) => {
                    return collection?.requirements?.length
                      ? collection.requirements[0]?.hasOwnProperty('certificates')
                        ? {
                            id: index,
                            operation: collection?.operation ?? null,
                            requirements: collection?.requirements?.filter(req => req?.certificates !== null),
                            type: SkillSetCollections.Certifications,
                          }
                        : {
                            id: 0,
                            type: SkillSetCollections.Licenses,
                            operation: collection?.operation ? collection.operation : null,
                            requirements: getTransformedLicenses(collection?.requirements),
                          }
                      : null;
                  })
                  .filter(Boolean) || [],
              id: skill?.id ? skill.id : index,
              isPrimary: true, // Since we filtered out skillsets where isPrimary is false, set this to true
              skillSet: skill?.skillSet,
              travelExperience: skill?.travelExperience,
            };
          }),
      };
    }),
  };

  formattedValue?.groups?.forEach(group => {
    group?.skillsets?.forEach(skillSet => {
      if (skillSet?.collections?.length === 1) {
        if (skillSet.collections[0].type === SkillSetCollections.Certifications) {
          skillSet.collections = [
            {
              type: SkillSetCollections.Licenses,
              requirements: [],
              operation: null,
            },
            skillSet.collections[0],
          ];
        }
      }
    });
  });

  return formattedValue;
};

export const getNameWithIds = (data: IAutoCompleteOption[]) => {
  return data?.map(opt => {
    return { ...opt, name: `${opt.value}. ${opt.name}` };
  });
};

export const isRequiredTravelExperience = ({
  experienceTypeValue,
  numberOfAssignments,
  yearsOfExperience,
  monthsOfExperience,
}) => Boolean(experienceTypeValue) && (numberOfAssignments > 0 || yearsOfExperience > 0 || monthsOfExperience > 0);

export const validateWithinLastMonthValue = value => {
  const isValid = (value && parseInt(value) > 0) || value === null;
  const isError = !isValid;
  return { isValid, isError };
};

export const formatReferenceText = data => {
  const { numberOfReferenceToRFO, withinNumberOfMonths, numberOfReferenceRequired } = data;
  const month = parseInt(withinNumberOfMonths) > 1 ? 'months' : 'month';
  const referenceToSubmit = numberOfReferenceToRFO
    ? `${numberOfReferenceToRFO} - ${
        withinNumberOfMonths > 0 ? `Within ${withinNumberOfMonths} ${month}` : 'Within entire work history'
      }`
    : missingField;
  const referencesRequired = numberOfReferenceRequired || missingField;
  return { referenceToSubmit, referencesRequired };
};

export const formattedSkillSet = data => {
  const transformedGroups = data?.map((group: any, groupIndex: number) => {
    const skillsets = group?.skillsets?.map((skillset: ISkillSet, skillsetIndex: number) => {
      const collections = skillset?.collections?.map((collection: ICollection) => {
        if (collection.type === SkillSetCollections.Licenses) {
          return {
            id: collection?.id,
            type: collection?.type,
            operation: collection?.operation,
            requirements: collection?.requirements?.map(req => ({
              requirementTypeId: req?.requirementTypeId,
              requirementAbbreviation: req?.requirementAbbreviation,
              requirementDescription: req?.requirementDescription,
              isMultiState: req?.isMultiState,
              requirementState: {
                state: req?.requirementState?.state,
                stateAbbreviation: req?.requirementState?.stateAbbreviation,
              },
              documentAttachmentRequired: req?.documentAttachmentRequired,
              isRequired: req?.isRequired,
              subCertificate: {}, // assuming subCertificate is an empty object
              id: req?.id,
            })),
          };
        } else if (collection.type === SkillSetCollections.Certifications) {
          return {
            id: collection?.id,
            operation: collection?.operation,
            type: collection?.type,
            requirements: collection?.requirements?.map(req => {
              let subCertificates = [];
              if (req.subCertificate?.subCertificationId) {
                subCertificates?.push({
                  subCertificate: {
                    name: req?.subCertificate?.subCertificateName,
                    id: req?.subCertificate?.subCertificationId,
                    value: req?.subCertificate?.subCertificateAbbreviation,
                  },
                });
              }

              return {
                certificates: {
                  label: `${req?.requirementAbbreviation} - ${req?.requirementDescription}`,
                  object: {
                    category: collection?.type,
                    subCategory: req?.requirementDescription?.split(' - ')[1] || '',
                    requirementTypeID: req?.requirementTypeId,
                    abbreviation: req?.requirementAbbreviation,
                    credentialName: req?.requirementDescription,
                    unitId: 1,
                    id: req?.requirementTypeId,
                    certificationName: `${req?.requirementAbbreviation} - ${req?.requirementDescription}`,
                  },
                },
                subCertificate: subCertificates,
                issuedBy: req?.issuedById
                  ? {
                      label: req?.issuedBy,
                      object: {
                        id: req?.issuedById,
                        abbr: req?.requirementAbbreviation,
                        fullDescription: req?.issuedBy,
                        certificationRequirementTypeId: req?.requirementTypeId,
                        certificationRequirementDescription: req?.requirementAbbreviation,
                        certificationRequirementFullDescription: req?.requirementDescription,
                      },
                    }
                  : null,
                isRequired: req?.isRequired,
                documentAttachmentRequired: req?.documentAttachmentRequired,
              };
            }),
          };
        }
      });
      const skillSetData = {
        discipline: {
          object: {
            ID: skillset?.skillSet?.disciplineId,
            Abbreviation: skillset?.skillSet?.disciplineAbbr,
            Description: skillset?.skillSet?.discipline,
          },
          label: skillset?.skillSet?.discipline,
        },
        specialty: {
          object: {
            SpecialtyID: skillset?.skillSet?.specialtyId,
            DisciplineID: skillset?.skillSet?.disciplineId,
            Description: skillset?.skillSet?.specialty,
          },
          label: skillset?.skillSet?.specialty,
        },
        subSpecialty: {
          object: {
            SpecialtyID: skillset?.skillSet?.specialtyId,
            SubSpecialtyID: skillset?.skillSet?.subSpecialtyId,
            Description: skillset?.skillSet?.subSpecialty,
          },
          label: skillset?.skillSet?.subSpecialty,
        },
        yearsOfExperience: skillset?.skillSet?.yearsOfExperience,
        monthsOfExperience: skillset?.skillSet?.monthsOfExperience,
        isRequired: skillset?.skillSet?.isRequired,
        isNewGrad: skillset?.skillSet?.isNewGrad,
        isClinicalFellowship: skillset?.skillSet?.isClinicalFellowship,
      };
      const groupBy =
        skillset?.travelExperience?.experienceTypeGroupId === TravelExperienceSectionIndex.NoOfAssignments
          ? TravelExperienceSections.NoOfAssignments
          : TravelExperienceSections.YearsOfExperience;
      const travelExperienceDetails = skillset?.travelExperience?.experienceTypeId
        ? {
            experienceTypeId: skillset?.travelExperience?.experienceTypeId.toString(), // Convert to string
            experienceTypeText: skillset?.travelExperience?.experienceTypeText,
            experienceType: {
              groupBy: groupBy,
              label: skillset?.travelExperience?.experienceTypeText,
              object: {
                optionId: skillset?.travelExperience?.experienceTypeId.toString(), // Convert to string
                title: skillset?.travelExperience?.experienceTypeText,
                id: `${skillset?.travelExperience?.experienceTypeGroupId}-${skillset?.travelExperience?.experienceTypeId}`, // Format id as "groupId-optionId"
              },
            },
            isRequired: skillset?.travelExperience?.isRequired,
            withinTwoYears: skillset?.travelExperience?.withinTwoYears,
            isAMNTravel: skillset?.travelExperience?.isAMNTravel,
            // Include fields based on groupBy value
            ...(groupBy === TravelExperienceSections.NoOfAssignments
              ? { numberOfAssignments: skillset?.travelExperience?.numberOfAssignments }
              : {
                  yearsOfExperience: skillset?.travelExperience?.yearsOfExperience,
                  monthsOfExperience: skillset?.travelExperience?.monthsOfExperience,
                }),
          }
        : null;
      const transformedSkillset = {
        collections,
        id: skillsetIndex,
        isPrimary: skillset?.isPrimary || false,
        skillSet: skillSetData || {},
        travelExperience: travelExperienceDetails || {},
      };

      return transformedSkillset;
    });

    return {
      id: groupIndex,
      operation: group?.operation || '&',
      skillsets,
    };
  });

  return { groups: transformedGroups, operation: null };
};
export const CONSTANT = {
  SPECIALITY: {
    OR: {
      ID: 7,
    },
  },
};
